import React, { useState, useEffect, useRef } from "react";
import TimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js";
import RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions";
//import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
import wavesurfer from "wavesurfer.js";
import Navbar from "../../pages/workspace/navbarWorkspace/Navbar";
import EditControls, { iconsData } from "../../operations/operations";
import PlaylistArea from "./playlistArea/PlaylistArea";
import { useSelector, useDispatch } from "react-redux";
import TranscriptButton from "../../pages/workspace/navbarWorkspace/TranscriptButton";
import { loadFlags } from "../../../services/FlagServices";
import {
  ajustAnnotandFadeAfterZoom,
  clearBackendApplyScheduler,
  clearOperationCountHandlerScheduler,
  convertTimeToPixel,
  convertTimeToPixelWithPixPerSec,
  editFeatureTitle,
  handleZoomLevel,
  findClosestAnnotationIndex,
  initiateBackendApplyScheduler,
  initiateOperationCountHandlerScheduler,
  loadSlickSliders,
  loadTranscription,
  renderFade,
  reRenderWaveform,
  resetAnnotation,
  showStatText,
  updateDuration,
  hideZoomControls,
  verticalScrollToView,
  updateCurrentTime,
} from "../../../utils/utils";
import "../../../css/dark/playlistarea.css";
import store from "../../../redux/Store";
import { multiSelectWordActions } from "../../../redux/slice/MultiSelectWordList";
import wavesurferobj, {
  wavesurferObjActions,
} from "../../../redux/slice/wavesurferobj";
import { wavesurferObjSFXActions } from "../../../redux/slice/wavesurferObjSFX";
import { updateWorkspaceWithOrigin } from "../../../utils/koolio-workspace";
import { useNavigate, useParams } from "react-router-dom";
import {
  convertAudioBufferToBlob,
  retrieveAudioFileByJobname,
} from "../../../utils/indexedDButils";
import { projectDataSliceActions } from "../../../redux/slice/ProjectDataSlice";
import { projectTitleSliceActions } from "../../../redux/slice/ProjectTitleSlice";
import { frontendApplySliceActions } from "../../../redux/slice/FrontendApplySlice";
import {
  checkIsTheProjectShared,
  setModeDependingOnFeatureLockStatus,
  updateOnlineOfflineStatus,
} from "../../../services/ShareAndCollabeServices";
import { wavesurferObjGlobalActions } from "../../../redux/slice/wavesurferObjGlobal";
import {
  applyOperations,
  ensureBuffersReady,
} from "../../operations/frontendApplyOperations";
import { restoreDataSliceActions } from "../../../redux/slice/restoreSlice";
import { handleHorizontalScroll } from "../../../utils/utils";
import { utilsSliceActions } from "../../../redux/slice/utilsSlice";
import { propsActions } from "../../../redux/slice/PropsSliceForApplyOperation";
import {
  checkForUnSyncedOperationInBackend,
  handleResultStatDataInDb,
  hideplaylistloader,
  showplaylistloader,
} from "../kooliospace/kooliospaceUtils";
import useBootstrapTooltips from "../../../hooks/useBootstrapTooltips";
import { viewEditSliceActions } from "../../../redux/slice/ViewEditModeSlice";
import { clickedRegionActions } from "../../../redux/slice/ClickedRegionSlice";
import { annotationListActions } from "../../../redux/slice/AnnotationListSlice";
import { playlistLoaderSliceActions } from "../../../redux/slice/PlaylistLoaderSlice";
import { screenHeight } from "../../../utils/Res";
import WorkspaceTour from "../../tours/WorkspaceTour";
import EditTour from "../../tours/EditFeaturesTour";
import { setSignInUserAction } from "../../../redux/slice/SignInUserSlice";
export const dragDropWord = (annotation = null) => {
  store.dispatch(
    multiSelectWordActions.updateDragSFX({
      toDragSFX: annotation,
    }),
  );
};
export const wordMultiSelect = (annotation = null) => {
  let multiSelectedWords = store.getState().multiSelectwordObject.wordsObject;
  let val = store.getState().multiSelectedWords.isMultiselect;
  if (val) {
    multiSelectedWords = [...multiSelectedWords, annotation];

    store.dispatch(
      multiSelectWordActions.updateMultiSelectWordList({
        wordsObject: multiSelectedWords,
      }),
    );
  } else {
    multiSelectedWords.forEach((annot) => {
      const elem = document.getElementById(`${annot.id}_div`);
      if (elem) {
        elem.classList.remove("clicked-annot");
        elem.style.transform = "scale(1.0)";
      }
    });
    multiSelectedWords = [annotation];
    store.dispatch(
      multiSelectWordActions.updateMultiSelectWordList({
        wordsObject: multiSelectedWords,
      }),
    );
  }
};

const Workspace = (props) => {
  let firsttopen = true;
  let params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const headerImg = document.querySelector(".koolio-header-img");
  if (headerImg)
    headerImg.addEventListener("click", () => navigate("/kooliospace"));

  const jobname = params.jobname;
  const wavesurferRef = useRef(null);
  const wavesurferRefSFX = useRef(null);
  const wavesurferRefGlobal = useRef(null);

  const [projectResultStat, setProjectResultStat] = useState({});
  const [isChecked, setIsChecked] = useState(false);

  // crate an instance of the wavesurfer
  const [wavesurferObj, setWavesurferObj] = useState();
  const [wavesurferObjGlobal, setWavesurferObjGlobal] = useState();
  const [wavesurferObjSFX, setwavesurferObjSFX] = useState();
  const [playing, setPlaying] = useState(true); // to keep track whether audio is currently playing or not
  const [volume, setVolume] = useState(1); // to control volume level of the audio. 0-mute, 1-max
  const [activeSpeaker, setactiveSpeaker] = useState("speaker-0"); // duration is used to set the default region of selection for trimming the audio
  const rejectBtn = useSelector((state) => state.restoreData.rejectBtnClick);
  const resultstatcheck = useSelector((state) => state.projectData.resultStat);
  const CurrentProjCollabAccessRevoked = useSelector(
    (state) => state?.signInUser?.revoked,
  );
  useEffect(() => {
    // console.log(resultstatcheck);
    if (rejectBtn) {
      setProjectResultStat(resultstatcheck);
      dispatch(restoreDataSliceActions.updateRejectBtnClick(false));
    }
  }, [rejectBtn]);
  useEffect(() => {
    if (CurrentProjCollabAccessRevoked) {
      sessionStorage.setItem("prevstate", "workspace");
      navigate("/kooliospace");
      store.dispatch(
        setSignInUserAction.setCurrentProjCollabAccessRevoked({
          revokeKey: false,
        }),
      );
    }
  });
  // console.log(rejectBtn);
  // console.log(projectResultStat);
  // let play = store.getState().viewEditMode.playPauseMode;
  let play = useSelector((state) => state.viewEditMode.playPauseMode);
  const [curtime, setcurtime] = useState(0);
  const [annotationList, setAnnotationList] = useState([]);
  const [sfxAnnotationList, setSfxAnnotationList] = useState([]);
  const [click, setClick] = useState(false);
  const iRef = useRef(0);
  useEffect(() => {
    if (
      resultstatcheck &&
      resultstatcheck.speakers_segments &&
      resultstatcheck.sfx_segments
    ) {
      let annotationList = [];
      let sfxAnnotationList = [];
      // console.log(resultstatcheck);
      resultstatcheck.speakers_segments.forEach((element) => {
        element.annotations.forEach((annot) => {
          // console.log(annot);
          let annotInfo = {
            id: annot.id,
            start: parseFloat(annot.start_time),
            end: parseFloat(annot.end_time),
            content: annot.content,
            speaker_label: annot.speaker_label,
            confidence: annot.confidence,
            is_silenced: annot.is_silenced,
          };
          annotationList.push(annotInfo);
        });
      });

      // console.log(annotationList);
      const sortedAnnotationList = annotationList.sort(
        (a, b) => a.start - b.start,
      );
      setAnnotationList(sortedAnnotationList);
      dispatch(
        annotationListActions.updateAnnotationList(sortedAnnotationList),
      );

      resultstatcheck.sfx_segments.forEach((element) => {
        element.annotations?.forEach((annot) => {
          // console.log(annot);
          let annotInfo = {
            id: annot.id,
            start: parseFloat(annot.start_time),
            end: parseFloat(annot.end_time),
          };
          sfxAnnotationList.push(annotInfo);
        });
      });
      // console.log(sfxAnnotationList);
      setSfxAnnotationList(sfxAnnotationList);
      dispatch(
        annotationListActions.updateSfxAnnotationList(sfxAnnotationList),
      );

      const waveform = document.getElementById("waveform");
      waveform.addEventListener("click", function () {
        resetAnnotation();
      });
      const sfxarea = document.getElementById("sfxarea");
      sfxarea.addEventListener("click", function () {
        resetAnnotation();
      });
    }
  }, [resultstatcheck]);
  console.log(annotationList);
  //change speaker while playing
  function changeSpeaker(previd, currid) {
    // Ensure the current speaker ID is valid and exists
    // console.log(currid);
    const currSpeaker = document.getElementById(`speakers-${currid}-slider`);
    const prevSpeaker = document.getElementById(`speakers-${previd}-slider`);
    const currSpeakerTrans = document.getElementById(
      `speakers-${currid}-slider-transcript`,
    );
    const prevSpeakerTrans = document.getElementById(
      `speakers-${previd}-slider-transcript`,
    );
    const container = document.getElementById("speakers-sliders");
    if (!currSpeaker || !container) {
      return; // Exit if the current speaker or container is not found
    }

    // Highlight the current speaker
    currSpeaker.classList.add("slick-current");

    // Remove highlight from the previous speaker
    if (prevSpeaker) {
      prevSpeaker.classList.remove("slick-current");
    }

    // Get container width and current speaker's position
    const containerWidth = container.offsetWidth;
    const iconWidth = currSpeaker.offsetWidth;
    const speakerOffsetLeft = currSpeaker.offsetLeft;

    // Scroll to center the current speaker
    const scrollPosition =
      speakerOffsetLeft - containerWidth / 2 + iconWidth / 2;

    container.scrollTo({
      left: scrollPosition,
      behavior: "smooth",
    });

    if (currSpeakerTrans) {
      // Highlight the current speaker
      currSpeakerTrans.classList.add("slick-current");

      // Remove highlight from the previous speaker
      if (prevSpeakerTrans) {
        prevSpeakerTrans.classList.remove("slick-current");
      }
    }
  }

  useEffect(() => {
    if (wavesurferObj) {
      let icon = document
        .getElementById("btn-play-icon")
        .src.split("/image")[1];
      let time = store.getState().utilsData.currentTime;
      if (icon == "/workspace/active-icons/play.png") {
        let timeToPX = convertTimeToPixel(time);
        let container = document.getElementById("playlist-wrapper");
        let containerWidth = container.offsetWidth;
        let waveformWidth = wavesurferObj.drawer.width; // Get total width of waveform
        let currentScrollPosition = container.scrollLeft;

        // Calculate the visible range
        let visibleStart = currentScrollPosition;
        let visibleEnd = currentScrollPosition + containerWidth;

        // Check if the current position is outside the visible range
        if (timeToPX < visibleStart || timeToPX > visibleEnd) {
          // console.log("Scrolling to new position");
          handleHorizontalScroll(timeToPX, "scroll");
        }
        setcurtime(timeToPX);
        //
      } else {
        setcurtime(0);
      }

      const findClick = store.getState().selectedRegion.clickedregion;
      if (findClick === "wav" || findClick === "global") {
        const index = findClosestAnnotationIndex(time, annotationList);
        if (index >= 0) {
          iRef.current = index;
          setClick(true);
        }
        iRef.current = index;
      }
      //hightlight while playing
      if (time === 0) {
        iRef.current = 0;
      }
      let waveTime = wavesurferObj.getDuration();
      if (parseFloat(time.toFixed(1)) === parseFloat(waveTime.toFixed(1))) {
        store.dispatch(
          viewEditSliceActions.updatePlayPauseMode({ value: true }),
        );
      }

      const onAudioProcess = (currentTime) => {
        resetAnnotation(); // Reset previous annotations before highlighting the new one

        // Highlight SFX annotations
        sfxAnnotationList.forEach((currAnnot) => {
          const currAnnotElem = document.getElementById(`${currAnnot.id}_div`);
          const transcriptionSectionAnnot = document.getElementById(
            `${currAnnot.id}_span`,
          );

          if (currentTime >= currAnnot.start && currentTime <= currAnnot.end) {
            currAnnotElem?.classList.add("clicked-annot");
            transcriptionSectionAnnot?.classList.add("clicked-annot");
            dispatch(
              annotationListActions.updateHighlightSFXAnnotationList(currAnnot),
            );
          } else {
            currAnnotElem?.classList.remove("clicked-annot");
          }
        });

        if (annotationList.length > 0 && iRef.current < annotationList.length) {
          const currAnnot = annotationList[iRef.current];
          const prevAnnot = annotationList[iRef.current - 1];

          if (currAnnot && currentTime >= currAnnot.start) {
            // Highlight the current annotation
            const currAnnotElem = document.getElementById(
              `${currAnnot.id}_div`,
            );
            const transcriptionSectionAnnot = document.getElementById(
              `${currAnnot.id}_span`,
            );
            const transcriptionModeAnnot = document.getElementById(
              `${currAnnot.id}_tspan`,
            );
            const transcriptionModalElem =
              document.getElementById(`fullTranscript`);
            const transcriptionModeElem = document.getElementById(
              `fullTranscript-workspace`,
            );

            currAnnotElem?.classList.add("clicked-annot");
            transcriptionSectionAnnot?.classList.add("clicked-annot");
            transcriptionModeAnnot?.classList.add("clicked-annot");

            dispatch(
              annotationListActions.updateHighlightAnnotationList(currAnnot),
            );

            // Handle speaker change
            if (prevAnnot?.speaker_label !== currAnnot?.speaker_label) {
              let prevSpk = prevAnnot?.speaker_label;
              let currSpk = currAnnot?.speaker_label;
              if (prevSpk && currSpk) {
                let previd = parseInt(prevSpk.split("_")[1]);
                let currid = parseInt(currSpk.split("_")[1]);
                changeSpeaker(previd, currid);
              }
            }

            // Scroll the transcription modal and  transcription mode to keep the current annotation visible
            verticalScrollToView(
              transcriptionSectionAnnot,
              transcriptionModalElem,
            );
            verticalScrollToView(transcriptionModeAnnot, transcriptionModeElem);

            // Remove highlight from previous annotation when needed
            if (currentTime >= currAnnot.end) {
              iRef.current += 1;
              if (prevAnnot) {
                const prevAnnotElem = document.getElementById(
                  `${prevAnnot.id}_div`,
                );
                const prevTranscriptionSectionAnnot = document.getElementById(
                  `${prevAnnot.id}_span`,
                );
                const prevTranscriptionModeAnnot = document.getElementById(
                  `${prevAnnot.id}_tspan`,
                );

                prevAnnotElem?.classList.remove("clicked-annot");
                prevTranscriptionSectionAnnot?.classList.remove(
                  "clicked-annot",
                );
                prevTranscriptionModeAnnot?.classList.remove("clicked-annot");
              }
            }
          }

          // Clean up when the audio ends
          if (currentTime >= annotationList[annotationList.length - 1].end) {
            const lastAnnot = annotationList[annotationList.length - 1];
            if (lastAnnot) {
              const lastAnnotElem = document.getElementById(
                `${lastAnnot.id}_div`,
              );
              const lastTranscriptionSectionAnnot = document.getElementById(
                `${lastAnnot.id}_span`,
              );
              const lastTranscriptionModeAnnot = document.getElementById(
                `${lastAnnot.id}_tspan`,
              );

              lastAnnotElem?.classList.remove("clicked-annot");
              lastTranscriptionSectionAnnot?.classList.remove("clicked-annot");
              lastTranscriptionModeAnnot?.classList.remove("clicked-annot");
            }
          }
        }
      };

      // Attach the audioprocess event listener
      wavesurferObj.on("audioprocess", onAudioProcess);

      // Clean up the event listener when the component unmounts or wavesurferObj changes
      return () => {
        wavesurferObj.un("audioprocess", onAudioProcess);
      };
    }
  }, [useSelector((state) => state.utilsData.currentTime)]);

  // handle reload and closing browser
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Perform actions before the component unloads
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    let resultStat = store.getState().projectData.resultStat;
    // console.log(resultStat);
    if (resultStat.refid) {
      document.title = `${resultStat.project_title} | koolio.ai`;
      if (resultStat?.page_loadable === false) {
        showplaylistloader();
        showStatText("sorry could not open the file, please try again", false);
      } else {
        //

        document.getElementById("wrapper").style.visibility = "visible";
        document.getElementById("controls").style.visibility = "visible";
        // setShowPlaylistArea(true);
        setProjectResultStat(resultStat);
        loadFlags(resultStat.jobname);
      }
    } else {
      let checkLoader = store.getState().playlistLoader.showLoader;
      if (!checkLoader) {
        showplaylistloader();
      }
      async function handleAudioFileByJobname() {
        let res;
        await retrieveAudioFileByJobname(jobname).then((projectData) => {
          //console.log("projectData ", projectData);
          if (projectData === null) {
            handleResultStatDataInDb(jobname).then(() => {
              return navigate(0);
            });
          } else {
            resultStat = projectData.data;
            document.title = `${resultStat.project_title} | koolio.ai`;

            store.dispatch(
              projectTitleSliceActions.updateProjectTitle({
                env: "workspace",
                projectTitle: resultStat.project_title,
              }),
            );
            store.dispatch(
              projectDataSliceActions.projectReducerData({ resultStat }),
            );
            if (resultStat?.page_loadable === false) {
              showplaylistloader();
              showStatText(
                "sorry could not open the file, please try again",
                false,
              );
            } else {
              document.getElementById("wrapper").style.visibility = "visible";
              document.getElementById("controls").style.visibility = "visible";
              setProjectResultStat(resultStat);
              loadFlags(resultStat.jobname);
            }
          }
        });
      }
      handleAudioFileByJobname(jobname);
    }
    dispatch(restoreDataSliceActions.clearRestoreData());
    clearBackendApplyScheduler();
    clearOperationCountHandlerScheduler();
    initiateBackendApplyScheduler();
    initiateOperationCountHandlerScheduler(jobname);

    shareAndCollabfunctions(jobname);
  }, [rejectBtn]);
  useEffect(() => {
    if (wavesurferObj && wavesurferObjSFX) {
      let clickPosition = 0;

      // event listener to track the initial click position whenever we are creating the region
      [wavesurferObj, wavesurferObjSFX].forEach((waveform) => {
        waveform.drawer.wrapper.addEventListener("mousedown", (e) => {
          const rect = waveform.drawer.wrapper.getBoundingClientRect();
          const scrollLeft = waveform.drawer.wrapper.scrollLeft;
          const offsetLeft = waveform.drawer.wrapper.offsetLeft;

          // Calculate precise pixel position
          clickPosition = Math.round(
            e.pageX - rect.left + scrollLeft - offsetLeft,
          );
          prevEndPosition.current = clickPosition;
        });
      });
      // event listener to track the initial click position whenever we are creating the region
      const handleRegionCreated = (createdInSFX) => (region) => {
        if (createdInSFX) {
          hideZoomControls();
          wavesurferObj.seekTo(region.start / wavesurferObj.getDuration());
          wavesurferObjSFX.seekTo(region.start / wavesurferObj.getDuration());
          wavesurferObjGlobal.seekTo(
            region.start / wavesurferObj.getDuration(),
          );
          wavesurferObj.regions.clear();
          wavesurferObjGlobal.regions.clear();
          wavesurferObjGlobal.addRegion({
            start: region.start,
            end: region.end,
            color: region.color,
            isDuplicated: true,
          });
          store.dispatch(
            clickedRegionActions.uploadSelectedRegion({
              payload: { type: "SFX" },
            }),
          );
        } else {
          hideZoomControls();
          store.dispatch(
            clickedRegionActions.uploadSelectedRegion({
              payload: { type: "wav" },
            }),
          );
          wavesurferObjSFX.regions.clear();
        }
        updateCurrentTime(region.start, wavesurferObj);
      };

      wavesurferObj.on("region-created", handleRegionCreated(false));
      wavesurferObjSFX.on("region-created", handleRegionCreated(true));
      wavesurferObjSFX.on("region-updated", (region) => {
        handleDragSelectionFunction(region);
        wavesurferObj.seekTo(region.start / wavesurferObj.getDuration());
        wavesurferObjSFX.seekTo(region.start / wavesurferObj.getDuration());
        wavesurferObjGlobal.seekTo(region.start / wavesurferObj.getDuration());
        wavesurferObjGlobal.regions.clear();
        wavesurferObjGlobal.addRegion({
          start: region.start,
          end: region.end,
          color: region.color,
          isDuplicated: true,
        });
        store.dispatch(
          clickedRegionActions.uploadSelectedRegion({
            payload: { type: "SFX" },
          }),
        );
      });

      return () => {
        wavesurferObj.un("region-created", handleRegionCreated(false));
        wavesurferObjSFX.un("region-created", handleRegionCreated(true));
      };
    }
  }, [wavesurferObj, wavesurferObjSFX, rejectBtn]);
  // Add this ref outside the function to store previous position
  const prevEndPosition = useRef(null);
  function handleDragSelectionFunction(region) {
    // we will track of region direction(right or left) then scroll the container accordingly
    let container = document.getElementById("playlist-wrapper");
    let containerWidth = container.offsetWidth;
    let currentScrollPosition = container.scrollLeft;
    let visibleStart = currentScrollPosition; // viewWidth current startTime in pixel wrt audio
    let visibleEnd = currentScrollPosition + containerWidth; // viewWidth current entTime in pixel wrt audio
    let regionStartPosition = region.start * wavesurferObj.params.minPxPerSec;
    let regionEndPosition = region.end * wavesurferObj.params.minPxPerSec;
    // Determine drag direction
    let dragDirection = "none";
    if (prevEndPosition.current !== null) {
      dragDirection =
        prevEndPosition.current -
          wavesurferObj.getCurrentTime() * wavesurferObj.params.minPxPerSec >
        5
          ? "left"
          : "right";
      updateCurrentTime("", props.wavesurferObj);
      if (dragDirection === "right" && visibleEnd - regionEndPosition < 50) {
        handleHorizontalScroll(currentScrollPosition + 20, "scroll");
      } else if (
        dragDirection === "right" &&
        regionEndPosition - visibleStart < 50
      ) {
        handleHorizontalScroll(currentScrollPosition - 20, "scroll");
      } else if (
        dragDirection === "left" &&
        regionStartPosition - visibleStart < 50
      ) {
        handleHorizontalScroll(currentScrollPosition - 20, "scroll");
      } else if (
        dragDirection === "left" &&
        visibleEnd - regionStartPosition < 50
      ) {
        handleHorizontalScroll(currentScrollPosition + 20, "scroll");
      }
    }
  }
  // create the waveform inside the correct component
  useEffect(() => {
    if (wavesurferRef.current && !wavesurferObj) {
      let AudioContext = window.AudioContext || window.webkitAudioContext;
      let context = new AudioContext();
      let sampleRate = context.sampleRate;
      let containerWidth = document.querySelector("#waveform").offsetWidth;
      let desiredDuration = 5;
      let minPxPerSec = containerWidth / desiredDuration;
      let wavesurferObj = wavesurfer.create({
        container: "#waveform",
        scrollParent: true,
        height: 90,
        zindex: 0,
        autoCenter: true,
        cursorColor: "white",
        loopSelection: true,
        waveColor: "rgb(138, 43, 226)",
        progressColor: "rgb(138, 43, 226)",
        responsive: true,
        normalize: false,
        sampleRate: sampleRate,
        minPxPerSec,
        plugins: [RegionsPlugin.create({})],
      });
      setWavesurferObj(wavesurferObj);
      dispatch(wavesurferObjActions.updateWavesurferObj({ wavesurferObj }));
    }
    if (wavesurferRefSFX.current) {
      if (wavesurferRefSFX.current && !wavesurferObjSFX) {
        let AudioContext = window.AudioContext || window.webkitAudioContext;
        let context = new AudioContext();
        let sampleRate = context.sampleRate;
        let containerWidth = document.querySelector("#waveform").offsetWidth;
        let desiredDuration = 5;
        let minPxPerSec = containerWidth / desiredDuration;
        let wavesurferObjSFX = wavesurfer.create({
          container: wavesurferRefSFX.current,
          scrollParent: true,
          height: 90,
          autoCenter: true,
          cursorColor: "white",
          loopSelection: true,
          waveColor: "rgb(138, 43, 226)",
          progressColor: "rgb(138, 43, 226)",
          responsive: true,
          sampleRate: sampleRate,
          minPxPerSec,
          plugins: [RegionsPlugin.create({})],
        });
        wavesurferObjSFX.seekTo(0);
        setwavesurferObjSFX(wavesurferObjSFX);
        dispatch(
          wavesurferObjSFXActions.updateWavesurferObjSFX({ wavesurferObjSFX }),
        );
      }
    }
    if (wavesurferRefGlobal.current) {
      if (wavesurferRefGlobal.current && !wavesurferObjGlobal) {
        let wavesurferObjGlobal = wavesurfer.create({
          container: wavesurferRefGlobal.current,
          height: 30,
          cursorColor: "red",
          waveColor: "rgb(138, 43, 226)",
          progressColor: "rgb(138, 43, 226)",
          responsive: true,
          plugins: [RegionsPlugin.create({})],
        });
        setWavesurferObjGlobal(wavesurferObjGlobal);
        dispatch(
          wavesurferObjGlobalActions.updateWavesurferObjGlobal({
            wavesurferObjGlobal,
          }),
        );
      }
    }
  }, [rejectBtn]);
  useEffect(() => {
    if (wavesurferObj && wavesurferObjGlobal) {
      wavesurferObj.on("region-created", (region) => {
        wavesurferObj.seekTo(region.start / wavesurferObj.getDuration());
        wavesurferObjSFX.seekTo(region.start / wavesurferObj.getDuration());
        wavesurferObjGlobal.seekTo(region.start / wavesurferObj.getDuration());
        wavesurferObjGlobal.regions.clear();
        wavesurferObjGlobal.addRegion({
          start: region.start,
          end: region.end,
          color: region.color,
          isDuplicated: true,
        });
      });
      wavesurferObj.on("region-updated", (region) => {
        handleDragSelectionFunction(region);
        wavesurferObj.seekTo(region.start / wavesurferObj.getDuration());
        wavesurferObjSFX.seekTo(region.start / wavesurferObj.getDuration());
        wavesurferObjGlobal.seekTo(region.start / wavesurferObj.getDuration());
      });
      let isDuplicatingRegion = false;

      const handleRegionCreatedOnWS = (region) => {
        if (!isDuplicatingRegion && !region.isDuplicated) {
          isDuplicatingRegion = true;
          wavesurferObjGlobal.regions.clear();
          wavesurferObjGlobal.addRegion({
            start: region.start,
            end: region.end,
            color: region.color,
            isDuplicated: true,
          });
          isDuplicatingRegion = false;
        }
        updateCurrentTime("", props.wavesurferObj);
      };

      const handleRegionCreatedOnGlobal = (region) => {
        if (!isDuplicatingRegion && !region.isDuplicated) {
          isDuplicatingRegion = true;
          wavesurferObj.regions.clear();
          wavesurferObj.addRegion({
            start: region.start,
            end: region.end,
            color: region.color,
            isDuplicated: true,
          });

          isDuplicatingRegion = false;
        }
        updateCurrentTime("", props.wavesurferObj);
      };
      wavesurferObj.on("region-updated", handleRegionCreatedOnWS);
      wavesurferObjGlobal.on("region-updated", handleRegionCreatedOnGlobal);

      return () => {
        wavesurferObj.un("region-updated", handleRegionCreatedOnWS);
        wavesurferObjGlobal.un("region-updated", handleRegionCreatedOnGlobal);
      };
    }
  }, [wavesurferObj, wavesurferObjGlobal, rejectBtn]);

  async function shareAndCollabfunctions(jobname) {
    try {
      const checkWhetherProjectIsShared =
        await checkIsTheProjectShared(jobname);
      if (checkWhetherProjectIsShared.shareholdersCount > 1) {
        await updateOnlineOfflineStatus(true);
        await setModeDependingOnFeatureLockStatus(editFeatureTitle);
      }
    } catch (err) {
      console.error(err);
    }
  }
  const isWithinRange = (time, timeRanges) => {
    return timeRanges.some((range) => time >= range.start && time <= range.end);
  };
  // once the projectResultStat is ready, load the file to produce the waveform
  useEffect(() => {
    if (projectResultStat && wavesurferObj) {
      const audioBlob = new Blob([projectResultStat.speakers_src]);
      const blobURL = URL.createObjectURL(audioBlob);
      wavesurferObj.load(blobURL);
      wavesurferObj.on("audioprocess", (currentTime) => {
        if (store.getState().solo.soloSpk) {
          let spklist = store.getState().solo.soloSpkList;
          if (isWithinRange(currentTime, spklist)) {
            wavesurferObj.setVolume(1); // Unmute during allowed ranges
          } else {
            wavesurferObj.setVolume(0); // Mute outside the allowed ranges
          }
        }
      });
      wavesurferObjGlobal.load(blobURL);
      wavesurferObjGlobal.setVolume(0);

      wavesurferObj.on("ready", async function () {
        document.querySelectorAll("wave").forEach((wave) => {
          wave.style.opacity = 1;
        });

        const speakerAnim = document.getElementById("speakerPulsate");
        const sfxAnim = document.getElementById("sfxPulsate");
        const globalViewAnim = document.getElementById("globalViewPulsate");
        if (speakerAnim && sfxAnim && globalViewAnim) {
          speakerAnim.remove();
          sfxAnim.remove();
          globalViewAnim.remove();
        }

        updateDuration();

        // Get the duration of the original audio buffer
        let duration = wavesurferObj.backend.buffer.duration;
        // Create a silent audio buffer with the same duration
        let sampleRate = wavesurferObj.backend.buffer.sampleRate;
        let numberOfChannels = wavesurferObj.backend.buffer.numberOfChannels;

        let restoreProjectData = store.getState().restoreData;
        let resultStat = restoreProjectData.isRestore
          ? restoreProjectData.restoreResultStat
          : store.getState().projectData.resultStat;
        resultStat = { ...resultStat };

        // Load the silent audio buffer into wavesurferObjSFX
        if (!(resultStat.sfx_src instanceof ArrayBuffer) && firsttopen) {
          let length = Math.round(duration * sampleRate);
          let silentBuffer = wavesurferObj.backend.ac.createBuffer(
            numberOfChannels,
            length,
            sampleRate,
          );
          resultStat.sfx_src = silentBuffer;
          wavesurferObjSFX.loadDecodedBuffer(silentBuffer);
          store.dispatch(
            projectDataSliceActions.projectReducerData({ resultStat }),
          );
        } else if (resultStat.sfx_src instanceof ArrayBuffer && firsttopen) {
          const audioBlob = new Blob([resultStat.sfx_src]);
          const blobURL = URL.createObjectURL(audioBlob);
          wavesurferObjSFX.load(blobURL);
        }
        // wavesurferObj.play();
        wavesurferObj.enableDragSelection({
          color: "hsla(265, 100%, 86%, 0.4)", // Set your desired color and opacity
        });
        let samplesPerPixel = parseInt((256 * 1440) / window.innerWidth);
        wavesurferObj.backend.params.samplesPerPixel = samplesPerPixel;
        //wavesurferObj.backend.params.minPxPerSec = samplesPerPixel
        //wavesurfer.zoom(samplesPerPixel)
        wavesurferObjSFX.enableDragSelection({
          color: "hsla(265, 100%, 86%, 0.4)", // Set your desired color and opacity
        });
        wavesurferObjGlobal.enableDragSelection({
          color: "hsla(265, 100%, 86%, 0.4)", // Set your desired color and opacity
        });
        //setDuration(Math.floor(wavesurferObj.getDuration())); // set the duration in local state

        let props = {
          wavesurferObj,
          wavesurferObjSFX,
          wavesurferObjGlobal,
        };

        store.dispatch(propsActions.updateProps({ props: props }));

        const isfrontendApplyActive =
          store.getState().frontendApplyData.isfrontendApplyActive;
        if (firsttopen) {
          renderFade(resultStat);
          await loadTranscription(resultStat);
          firsttopen = false;
          store.dispatch(
            playlistLoaderSliceActions.updateLoaderValue({ value: false }),
          );
          hideplaylistloader();
        }
        if (!isfrontendApplyActive && !restoreProjectData.isRestore) {
          updateWorkspaceWithOrigin(
            jobname,
            resultStat.refid,
            true,
            false,
            props,
          ).then(async () => {
            resultStat = store.getState().projectData.resultStat;

            // making sure that waveform is loaded using react requestAnimationFrame
            requestAnimationFrame(updateWaveWidth);
          });
        }
      });

      // annotation highlighted and handling spk and sfx click annotation
      wavesurferObj.on("region-created", (region) => {
        const multiselect = store.getState().multiSelectedWords.isMultiselect;
        const annotationList = store.getState().annotationList.annotationList;
        const sfxAnnotationList =
          store.getState().annotationList.sfxAnnotationList;
        const startTime = region.start;
        const endTime = region.end;
        if (sfxAnnotationList.length > 0) {
          sfxAnnotationList.forEach((sfxAnnot) => {
            if (startTime <= sfxAnnot.start && sfxAnnot.end <= endTime) {
              let sfxannotdata = document.getElementById(sfxAnnot.id + "_div");
              if (sfxannotdata) {
                sfxannotdata.classList.remove("clicked-annot");
                sfxannotdata.classList.add("active-annot");
              }
            }
          });
        }
        if (!multiselect) {
          resetAnnotation();
        }
        // Apply styles for annotations within the selected region
        // console.log(annotationList);
        annotationList.forEach((annot) => {
          if (startTime <= annot.start && annot.end <= endTime) {
            let annotdata = document.getElementById(annot.id + "_div");
            let transcriptionSectionAnnot = document.getElementById(
              annot.id + "_span",
            );
            let transcriptionModeAnnot = document.getElementById(
              annot.id + "_tspan",
            );
            if (annotdata) {
              // annotdata.id = `${annot.id}_div`;
              // annotdata.innerText = annot.content;
              annotdata.classList.add("clicked-annot");
              if (transcriptionSectionAnnot) {
                transcriptionSectionAnnot.classList.add("clicked-annot");
              }
              if (transcriptionModeAnnot) {
                transcriptionModeAnnot.classList.add("clicked-annot");
              }

              dispatch(
                annotationListActions.updateHighlightAnnotationList(annot),
              );
            }
          }
        });
      });
      // code for annotations is getting highlighted when selected through drag
      wavesurferObj.on("region-updated", (region) => {
        const annotationList = store.getState().annotationList.annotationList;
        const startTime = region.start;
        const endTime = region.end;
        resetAnnotation();

        // Apply styles for annotations within the selected region
        annotationList.forEach((annot) => {
          if (
            startTime <= annot.end - (annot.end - annot.start) * 0.8 &&
            endTime >= annot.start + (annot.end - annot.start) * 0.8
          ) {
            let annotdata = document.getElementById(annot.id + "_div");
            let transcriptionSectionAnnot = document.getElementById(
              annot.id + "_span",
            );

            if (annotdata) {
              // annotdata.id = `${annot.id}_div`;
              // annotdata.innerText = annot.content;
              annotdata.classList.add("clicked-annot");
              if (transcriptionSectionAnnot) {
                transcriptionSectionAnnot.classList.add("clicked-annot");
              }

              dispatch(
                annotationListActions.updateHighlightAnnotationList(annot),
              );
            }
          }
        });
      });
      // need to change the approach it is throwing error
      const resultStat = store.getState().projectData.resultStat;
      // once audio starts playing, set the state variable to false
      wavesurferObj.on("finish", () => {
        setPlaying(false);
      });

      //play pause icon change
      wavesurferObj.on("play", () => {
        document.getElementById("btn-play-icon").src = iconsData.play.active;
      });
      wavesurferObj.on("pause", () => {
        document.getElementById("btn-play-icon").src = iconsData.play.inactive;
        // store.dispatch(
        //   viewEditSliceActions.updatePlayPauseMode({ value: true }),
        // );
      });

      // if multiple regions are created, then remove all the previous regions so that only 1 is present at any given time
      wavesurferObj.on("region-updated", (region) => {
        const regions = region.wavesurfer.regions.list;
        const keys = Object.keys(regions);
        if (keys.length > 1) {
          regions[keys[0]].remove();
        }
      });
      wavesurferObjSFX.on("region-updated", (region) => {
        const regions = region.wavesurfer.regions.list;
        const keys = Object.keys(regions);
        if (keys.length > 1) {
          regions[keys[0]].remove();
        }
      });
      loadSlickSliders(projectResultStat);
    }
  }, [projectResultStat, rejectBtn]);
  // projectResultStat,

  useEffect(() => {
    if (wavesurferObjGlobal) {
      wavesurferObjGlobal.on("ready", function () {
        document.querySelectorAll("wave").forEach(function (wave) {
          if (
            wave.style.position === "relative" &&
            wave.parentElement.id === "globalview"
          ) {
            let width = 0;
            //#651- setting global waveform wave element tag to be equal to global view so that we can drag the the entire region
            width = document.getElementById("globalview").offsetWidth;
            wave.style.width = width + "px";
            // wave.children[0].style.width = 0 + "px"; // after operation the cursor should move to starting point
            wave.style.overflow = "visible";
          }
        });
      });
    }
  }, [wavesurferObjGlobal, rejectBtn]);

  useEffect(() => {
    //639 code starts
    // Add event listener when the component mounts
    window.addEventListener("resize", handleWindowResize);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
    //639 code ends here
  }, []);

  function handleWindowResize() {
    showplaylistloader();
    const props = store.getState().propsSlice.props;
    const { wavesurferObj, wavesurferObjSFX, wavesurferObjGlobal } = {
      ...props,
    };
    if (!wavesurferObj || !wavesurferObjSFX || !wavesurferObjGlobal) return;
    wavesurferObj.drawBuffer();
    wavesurferObjSFX.drawBuffer();
    wavesurferObjGlobal.drawBuffer();
    let pixPerSecond = wavesurferObj.params.minPxPerSec;
    let newWidth = Math.round(wavesurferObj.getDuration() * pixPerSecond);
    if (newWidth < document.getElementById("playlist").offsetWidth) {
      newWidth = document.getElementById("playlist").offsetWidth;
      pixPerSecond = Math.round(newWidth / wavesurferObj.getDuration());
      let props = { wavesurferObj, wavesurferObjSFX };
      handleZoomLevel(props, newWidth, pixPerSecond);
    }
    ajustAnnotandFadeAfterZoom();
    hideplaylistloader();
  }

  async function updateWaveWidth(start) {
    //checking whether our audio is present or not
    await ensureBuffersReady(wavesurferObj, wavesurferObjSFX);
    // set width of all wave element
    document.querySelectorAll("wave").forEach(function (wave) {
      if (
        wave.style.position === "relative" &&
        wave.parentElement.id != "globalview"
      ) {
        let width = 0;
        let duration = wavesurferObj.getDuration();
        const minPxPerSec = wavesurferObj.params.minPxPerSec;
        width = duration * minPxPerSec;
        if (width < document.getElementById("playlist").offsetWidth) {
          width = document.getElementById("playlist").offsetWidth;
        }
        wave.style.width = width + "px";
        wave.style.overflow = "visible";
      }
    });
  }

  return (
    <>
      <WorkspaceTour isLoading={props.isLoading} />
      <EditTour />
      <Navbar
        setIsCheckedTrue={() => setIsChecked(true)}
        setIsCheckedFalse={() => setIsChecked(false)}
      />
      <article className="post col-8-mod">
        <div className="post-content" id="post-content">
          <div id="playlist-loader">
            <div id="playlist-loader-bg" />
            <div
              className="spinner-container"
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.4vw",
              }}
            >
              <img
                src="/image/koolio-icon-workspace.svg"
                style={{
                  width: "5.856515373352855vw",
                }}
                className="ajax-loader"
                alt="Loading Spinner"
              />
              <p
                className="text-white"
                style={{
                  fontSize: "1.171303074670571vw",
                }}
              >
                Please wait...
              </p>
            </div>
          </div>
          <div id="workspace-closing-loader">
            <div
              className="spinner-container"
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.4vw",
              }}
            >
              <img
                src="/image/koolio-icon-workspace.svg"
                style={{
                  width: "5.856515373352855vw",
                  marginBlockEnd: "1em",
                }}
                className="ajax-loader"
                alt="Loading Spinner"
              />
              <p
                className="text-white"
                style={{
                  fontSize: "1.171303074670571vw",
                }}
              >
                Saving your workspace.
              </p>
              <p
                className="text-white"
                style={{
                  fontSize: "1.171303074670571vw",
                }}
              >
                Please wait...
              </p>
            </div>
          </div>
          <div
            id="playlist"
            style={{
              borderLeft: "double #E2522B",
              borderRight: "double #E2522B",
            }}
          ></div>
        </div>
      </article>
      <>
        <div className="waveform-container">
          <PlaylistArea
            // style={{ visibility: "hidden" }}
            wavesurferRefGlobal={wavesurferRefGlobal}
            wavesurferRef={wavesurferRef}
            wavesurferRefSFX={wavesurferRefSFX}
            wavesurferObj={wavesurferObj}
            wavesurferObjSFX={wavesurferObjSFX}
            wavesurferObjGlobal={wavesurferObjGlobal}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
          />
          <EditControls
            wavesurferObj={wavesurferObj}
            wavesurferObjSFX={wavesurferObjSFX}
            wavesurferObjGlobal={wavesurferObjGlobal}
          />
        </div>
        <div className="footer">
          <span id="stat-text"></span>
        </div>
      </>
      <TranscriptButton />
    </>
  );
};

export default Workspace;
