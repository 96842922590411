import React from "react";
import "../../../../css/dark/navbar.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function HelpButton({ handleHelpButtonClick }) {
  return (
    <div
      style={{
        height: "max-content",
        width: "max-content",
        position: "fixed",
        top: "3.9%",
        left: "22%",
        cursor: "pointer",
      }}
      onClick={handleHelpButtonClick}
    >
      {/* <OverlayTrigger
        placement="right"
        overlay={<Tooltip id="help-tooltip">Help</Tooltip>}
      > */}
      <span className="circle-badge">
        <span title="Help">?</span>
      </span>
      {/* </OverlayTrigger> */}
    </div>
  );
}
