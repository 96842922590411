import store from "../redux/Store";
import $ from "jquery";
import {
  applyOperationsInBackend,
  getHistory,
  getResultStat,
  restoreProjectHistory,
  sendOperationToBackend,
} from "../services/globalServices";
//import { Koolioai } from "./cognitoAuth";
//import store from "../redux/Store";
import jsPDF from "jspdf";
import _ from "lodash";
import { projectDataSliceActions } from "../redux/slice/ProjectDataSlice";
import { genAiActions } from "../redux/slice/GenAiWebsocketDataSlice";
import { Koolioai, _config, pollRefreshToken } from "./cognitoAuth";
import axios from "axios";
import { operationListSliceActions } from "../redux/slice/OperationListSlice";
import { authRes } from "./Res";
import {
  apiProviderBuzzsprout,
  apiProviderCastos,
  apiProviderHeadliner,
  apiProviderPodbean,
  apiProviderSpreaker,
  apiProviderTransistor,
  publishAudio,
} from "./publish-api/ApiIntegrationAuth";
import { SelectedApiProviderSliceActions } from "../redux/slice/SelectedApiProviderSlice";
import {
  getAINotesFromDB,
  prepareShareholderStatusView,
  showMessage,
  updateOnlineShareholderView,
  updateWorkspaceWithOrigin,
} from "./koolio-workspace";
import {
  addAudioFile,
  addOperationsToDB,
  convertAudioBufferToBlob,
  getArrayBufferFromSrcURL,
  getAudioBufferFromSrcURL,
  updateIndexedDBoperation,
  updateTranscriptionIndexDB,
} from "./indexedDButils";
import { notificationDetailsActions } from "../redux/slice/NotificationsDetailsSlice";
import { getNotifications } from "../services/NotificationServices";
import {
  cueFormatters,
  hideplaylistloader,
  mediaQueriess,
  saveKooliospace,
  setNewNotificationCount,
  showplaylistloader,
  updateKSFromLocal,
  viewNotification,
} from "../components/pages/kooliospace/kooliospaceUtils";
import { ACManager } from "./Access-control-manager";
import { retrieveAudioFileByJobname } from "./indexedDButils";
import { getKooliospace } from "../services/KooliospaceServices";
import {
  dragDropWord,
  wordMultiSelect,
} from "../components/pages/workspace/Workspace";
import { shareAndCollabActions } from "../redux/slice/ShareAndCollableSlice";
import { viewEditSliceActions } from "../redux/slice/ViewEditModeSlice";
import { applyOperations } from "../components/operations/frontendApplyOperations";
import { restoreDataSliceActions } from "../redux/slice/restoreSlice";
import { utilsSliceActions } from "../redux/slice/utilsSlice";
import {
  getOperationCount,
  setModeDependingOnFeatureLockStatus,
} from "../services/ShareAndCollabeServices";
import { OperationsCountHandlerSliceActions } from "../redux/slice/OperationsCountHandler";
import { sfxModalSliceActions } from "../redux/slice/SfxModalSlice.js";
import { updateOperationSpace } from "./ProjectTitleUtils";
import { data, iconsData } from "../components/operations/operations";
import { deleteFlag, editFlag } from "./Flagshandler.js";
import {
  loadFlags,
  globalFlagTriggerFunction,
  restoreFlags,
} from "../services/FlagServices.js";
import { AdjustAnnotationActions } from "../redux/slice/RestoreAnnotationofCutSlice.js";
import SubscriptionModal from "../components/modals/subscriptionModal";
import { clickedRegionActions } from "../redux/slice/ClickedRegionSlice.js";
import { multiSelectWordActions } from "../redux/slice/MultiSelectWordList.js";
import { flagListActions } from "../redux/slice/Flagslice.js";
import { projectTitleSliceActions } from "../redux/slice/ProjectTitleSlice.js";
import { exportOptionSliceActions } from "../redux/slice/ExportOptionsSlice.js";
import { uploadFileToWorkspaceActions } from "../redux/slice/FileSelectedForWorkspaceSlice.js";
import { annotationListActions } from "../redux/slice/AnnotationListSlice.js";
import { mediaQueriesss } from "../components/pages/loginPage/loginUtils.js";
import { soloListActions } from "../redux/slice/soloenable.js";
import { initiateTranscription } from "../services/uploadService.js";
import { wavesurferObjActions } from "../redux/slice/wavesurferobj.js";
import { copiedAnnotActions } from "../redux/slice/copiedAnnotation.js";
import { nestedFileSliceActions } from "../redux/slice/NestedImportSlice.js";
import { ToggleSliceActions } from "../redux/slice/ToggleButtonForEnablingOperationsSlice.js";
import { propsActions } from "../redux/slice/PropsSliceForApplyOperation.js";
import { wavesurferObjSFXActions } from "../redux/slice/wavesurferObjSFX.js";
import { speakerBtnClickedSliceActions } from "../redux/slice/RightCLickOnSpeakerSlice.js";
import {
  jobHeadOFAudioToPublish,
  prepareHeadlinerButton,
  updateValueForHeadliner,
} from "./publish-api/ApiHeadliner.js";
import tippy from "tippy.js";
export let websocketOperations = [];
export let pinginterval,
  wssreconnect,
  wsstm,
  statTimer,
  lastsynced,
  alertTimer,
  listofSharedUser = [],
  operationQ = {};
export let isWebsocketApplyOperationActive = false;
export const ACM = new ACManager();
let transcriptionCompleted = false;

export const editFeatureTitle = "Edit";
// export let isNativeExport = false
// export let selectedApiProvider = 'buzzsprout'
let freePlanId = "f3ce00b8-d019-3dfe-8446-c64a843894e2";
let adminPlanId = "f4671d4b-16ab-36a5-9c25-137358063843";

export function create_UUID() {
  let dt = new Date().getTime();
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    },
  );
  return uuid;
}

export function getDuration(src) {
  return new Promise(function (resolve) {
    let audio = new Audio();
    audio.addEventListener("loadedmetadata", function () {
      resolve(audio.duration);
    });
    audio.src = src;
  });
}

export function secondsToMinutesAndSeconds(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
}

export function getInternetSpeed(obj) {
  const url =
    "https://s3.us-east-2.amazonaws.com/static.koolio.ai/favicon.ico?" +
    new Date().getTime();
  // The size in bytes
  const downloadSize = 106420;
  const startTime = new Date().getTime();
  const userImageLink = url;
  const downloadImgSrc = new Image();
  let loaded = false;
  setTimeout(() => {
    if (!loaded) {
      downloadImgSrc.onload = null;
      if (!navigator.onLine) obj.speed = 0;
    }
  }, 9000);
  downloadImgSrc.onload = function () {
    const endTime = new Date().getTime();
    const timeDuration = (endTime - startTime) / 1000;
    const loadedBits = downloadSize * 8;
    const bps = (loadedBits / timeDuration).toFixed(2);
    loaded = true;
    obj.speed = bps;
    //ShowProgressMessage('Average connection speed is ' + obj.speed + ' bps')
  };
  downloadImgSrc.src = userImageLink;
}

export function forceHideMessage() {
  //document.getElementById("pop-up-message").style.display = 'none'
}

export function showStatText(...messages) {
  clearTimeout(statTimer);
  const autoturnoff = messages[messages.length - 1] !== false;
  if (autoturnoff) {
    if (document.getElementById("stat-text"))
      document.getElementById("stat-text").innerText = messages.join(" ");
    statTimer = setTimeout(function () {
      if (document.getElementById("stat-text"))
        document.getElementById("stat-text").innerText = "";
    }, 3000);
    return;
  }
  messages.pop();
  if (document.getElementById("stat-text"))
    document.getElementById("stat-text").innerText = messages.join(" ");
}

export function moreOptionsAlert(...messages) {
  clearTimeout(alertTimer);
  const autoturnoff = messages[messages.length - 1] !== false;
  if (autoturnoff) {
    if (document.getElementById("alert-more-options"))
      document.getElementById("alert-more-options").innerText =
        messages.join(" ");
    alertTimer = setTimeout(function () {
      if (document.getElementById("alert-more-options"))
        document.getElementById("alert-more-options").innerText = "";
    }, 2000);
    return;
  }
  messages.pop();
  if (document.getElementById("alert-more-options"))
    document.getElementById("alert-more-options").innerText =
      messages.join(" ");
}

export function pasteOperation(str) {
  document.getElementById("btn-insert-alt-icon").src =
    iconsData.insert.inactive;
  document.getElementById("btn-spk-icon").src = iconsData.speakers.inactive;
  document.getElementById("waveforms-selector").src = iconsData.fades.inactive;

  document.getElementById("btn-insert-icon").src = document
    .getElementById("btn-insert-icon")
    .src.endsWith(iconsData.paste.active)
    ? iconsData.paste.inactive
    : iconsData.paste.active;

  if (
    document.getElementById("sub-menus-fade").style.visibility === "visible"
  ) {
    document.getElementById("sub-menus-fade").style.visibility = "hidden";
    document.getElementById("sub-menus-section").style.visibility = "hidden";
    document.getElementById("control-mode").classList.remove("none");
    document.getElementById("control-mode").style.display = "flex";
  }
  if (
    document.getElementById("change-speakers").style.visibility === "visible"
  ) {
    document.getElementById("change-speakers").style.visibility = "hidden";
    document.getElementById("sub-menus-section").style.visibility = "hidden";
    document.getElementById("control-mode").classList.remove("none");
    document.getElementById("control-mode").style.display = "flex";
  }
  if (document.getElementById("sub-menus").style.visibility === "visible") {
    document.getElementById("sub-menus").style.visibility = "hidden";
    document.getElementById("control-mode").classList.remove("none");
    document.getElementById("control-mode").style.display = "flex";
  } else {
    document.getElementById("sub-menus").style.visibility = "visible";
    document.getElementById("control-mode").classList.remove("d-flex");
    document.getElementById("control-mode").style.display = "none";
  }
  if (
    document.getElementById("sub-menus-insert").style.visibility === "visible"
  ) {
    document.getElementById("sub-menus-insert").style.visibility = "hidden";
  }
}

export function insertOperation() {
  document.getElementById("btn-insert-icon").src = iconsData.paste.inactive;
  document.getElementById("btn-spk-icon").src = iconsData.speakers.inactive;
  document.getElementById("waveforms-selector").src = iconsData.fades.inactive;

  document.getElementById("btn-insert-alt-icon").src = document
    .getElementById("btn-insert-alt-icon")
    .src.endsWith(iconsData.insert.active)
    ? iconsData.insert.inactive
    : iconsData.insert.active;

  if (
    document.getElementById("sub-menus-fade").style.visibility === "visible"
  ) {
    document.getElementById("sub-menus-fade").style.visibility = "hidden";
    document.getElementById("sub-menus-section").style.visibility = "hidden";
    document.getElementById("control-mode").classList.remove("none");
    document.getElementById("control-mode").style.display = "flex";
  }
  if (
    document.getElementById("change-speakers").style.visibility === "visible"
  ) {
    document.getElementById("change-speakers").style.visibility = "hidden";
    document.getElementById("sub-menus-section").style.visibility = "hidden";
    document.getElementById("control-mode").classList.remove("none");
    document.getElementById("control-mode").style.display = "flex";
  }

  if (
    document.getElementById("sub-menus-insert").style.visibility === "visible"
  ) {
    document.getElementById("sub-menus-insert").style.visibility = "hidden";
    document.getElementById("control-mode").classList.remove("none");
    document.getElementById("control-mode").style.display = "flex";
  } else {
    document.getElementById("sub-menus-insert").style.visibility = "visible";
    document.getElementById("control-mode").classList.remove("d-flex");
    document.getElementById("control-mode").style.display = "none";
  }
  if (document.getElementById("sub-menus").style.visibility === "visible") {
    document.getElementById("sub-menus").style.visibility = "hidden";
  }
}
export function fadeOperation() {
  document.getElementById("btn-insert-icon").src = iconsData.paste.inactive;
  document.getElementById("btn-spk-icon").src = iconsData.speakers.inactive;
  document.getElementById("btn-insert-alt-icon").src =
    iconsData.insert.inactive;

  document.getElementById("waveforms-selector").src = document
    .getElementById("waveforms-selector")
    .src.endsWith(iconsData.fades.active)
    ? iconsData.fades.inactive
    : iconsData.fades.active;

  if (document.getElementById("sub-menus").style.visibility === "visible") {
    document.getElementById("sub-menus").style.visibility = "hidden";
    document.getElementById("control-mode").classList.remove("none");
    document.getElementById("control-mode").style.display = "flex";
  }
  if (
    document.getElementById("change-speakers").style.visibility === "visible"
  ) {
    document.getElementById("change-speakers").style.visibility = "hidden";
    document.getElementById("sub-menus-section").style.visibility = "hidden";
    document.getElementById("control-mode").classList.remove("none");
    document.getElementById("control-mode").style.display = "flex";
  }
  if (
    document.getElementById("sub-menus-insert").style.visibility === "visible"
  ) {
    document.getElementById("sub-menus-insert").style.visibility = "hidden";
    document.getElementById("control-mode").classList.remove("none");
    document.getElementById("control-mode").style.display = "flex";
  }

  if (
    document.getElementById("sub-menus-fade").style.visibility === "visible" ||
    document.getElementById("sub-menus-section").style.visibility == "visible"
  ) {
    document.getElementById("sub-menus-fade").style.visibility = "hidden";
    document.getElementById("sub-menus-section").style.visibility = "hidden";
    document.getElementById("control-mode").classList.remove("none");
    document.getElementById("control-mode").style.display = "flex";
  } else {
    document.getElementById("sub-menus-fade").style.visibility = "visible";
    document.getElementById("control-mode").classList.remove("d-flex");
    document.getElementById("control-mode").style.display = "none";
  }
  if (document.getElementById("sub-menus").style.visibility === "visible") {
    document.getElementById("sub-menus").style.visibility = "hidden";
  }
}

export function initiateIncompleteTaskAfterConnectivityIsBack() {
  //if(isvantaactive && document.getElementById('progress-content').innerHTML === 'Preparing Your Workspace' && !isProjectAlreadyInLoadingPhase) getStatus()
  //else if(playlist.jobname && !isvantaactive && selectedSfxDomElement) selectedSfxDomElement.click()
}

export const pingPongConnectivityObj = {
  startTime: 0, // Holds the request sending time.
  endTime: 0, // Holds the response received time.
  fileSize: 0, // File size of the response download.
  speedStack: [], // Holds the latest 5 speed data.
  speed: 729528.71, // Calculated speed.
  threshold: 529528.71, // Value to determine whether calculated speed is stable or low.

  // Method to get online or offline status.
  isOnLine: function () {
    if (!navigator.onLine || this.speed < this.threshold) {
      this.speed = 0;
      return false;
    } else {
      return true;
    }
  },
  // Method used to check internet in interval to notify the user
  actionOnNetworkStatus: function () {
    if (!navigator.onLine) {
      this.speed = 0;
      showStatText("You are offline", "changes may not be synced", false);
    } else if (this.speed < this.threshold) {
      forceHideMessage();
      showStatText("Your internet speed is slow.", false);
    } else {
      forceHideMessage();
      showStatText("", false);
    }
  },
};

export function ping() {
  pingPongConnectivityObj.startTime = new Date().getTime();
  getInternetSpeed(pingPongConnectivityObj);
  try {
    Koolioai.wss.send(
      JSON.stringify({
        action: "ping",
        data: "__ping__",
      }),
    );
  } catch {
    console.error("socket send error");
  }
  wsstm = setTimeout(function () {
    Koolioai.isLive = false;
    if (!navigator.onLine) {
      showStatText("You are offline", "changes may not be synced", false);
    } else {
      showStatText("You are in idle state", "changes may not be synced", false);
    }
  }, 1000);
}

export function pong() {
  pingPongConnectivityObj.endTime = new Date().getTime();
  pingPongConnectivityObj.actionOnNetworkStatus();
  if (store.getState().projectData.resultStat.jobname)
    updateOnlineShareholderView();

  if (!Koolioai.isLive) {
    showStatText("You are online", "sync is enabled");
    initiateIncompleteTaskAfterConnectivityIsBack();
  }
  Koolioai.isLive = true;
  clearTimeout(wsstm);
}
// new function to check transcription completed manually

async function isTranscriptionCompleted(jobname) {
  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()])
    .then((tokens) =>
      axios.get(_config.api + "/transcription-completed", {
        params: {
          jobname: jobname,
          username: Koolioai.username,
        },
        headers: {
          Authorization: `${tokens[0]}`,
          AccessToken: `${tokens[1]}`,
        },
      }),
    )
    .then((response) => {
      return response.data.transcription_completed;
    });
}
async function getTranscriptionStatusFromSocket(uploadedProject) {
  const state = store.getState();
  let resultStat = state.projectData.resultStat;
  let uploadProgressStatus = state.uploadProgressStatus.uploadProgress;
  const jobname = resultStat.jobname;
  if (uploadedProject && !uploadedProject.checkTranscriptionThroughAPIcount) {
    uploadedProject.checkTranscriptionThroughAPIcount++;
    uploadProgressStatus.uploadedProjectList.map((project) => {
      if (project.id === jobname) {
        project.checkTranscriptionThroughAPIcount =
          uploadedProject.checkTranscriptionThroughAPIcount;
      }
    });
    let istranscribed = await isTranscriptionCompleted(uploadedProject.id);
    if (istranscribed) {
      uploadedProject.progress = "100";
    } else {
      uploadedProject.checkTranscriptionThroughAPIcount = 0;
      uploadProgressStatus.uploadedProjectList.map((project) => {
        if (project.id === jobname) {
          project.checkTranscriptionThroughAPIcount = 0;
        }
      });
    }
  }
}
export async function transcriptionProgressLoaderNewArch(
  jobname,
  displayOn,
  interval,
) {
  const state = store.getState();
  let uploadProgressStatus = state.uploadProgressStatus.uploadProgress;
  if (!jobname || !displayOn) {
    //closeHalfModal()
    return;
  }
  const progressBar =
    displayOn === "workspace"
      ? document.getElementById("progress-percentage-set")
      : document.getElementById(jobname + "_innerProgress");

  //const progressBarText = (displayOn === 'workspace') ? document.querySelector('.progress p > span') : document.getElementById(jobname + "_innerProgressText")     //commented due to #2405

  const progressBarText =
    displayOn === "workspace"
      ? document.getElementById("transcribe-percentage-text")
      : document.getElementById(jobname + "_innerProgressText"); //implementation #2405

  if (displayOn === "workspace" && !progressBar) {
    //have to implement this
    //closeHalfModal()
    return;
  }

  if (!progressBar || !progressBarText) return;

  if (displayOn === "workspace")
    document.getElementById("half-modals").css("visibility", "visible");

  if (displayOn === "kooliospace") {
    const infoText = document.querySelector(
      "[id='" + jobname + "_progressBarOuter'].progress p",
    ).lastChild;
    if (infoText.textContent !== "% transcribed")
      infoText.textContent = "% transcribed";
  }

  //starting of showing transcription progress
  getKooliospace().then((kooliospace) => {
    kooliospace.forEach((kooliojob) => {
      if (kooliojob.jobname === jobname) {
        const uploadedProject = uploadProgressStatus.uploadedProjectList.find(
          (project) => project.id === jobname,
        );
        const totalTranscriptionTime =
          (kooliojob.duration * 1000) / 3 + 60 * 1000;
        let timeRemaining = totalTranscriptionTime;
        let timeRemainingPercent =
          100 - Math.ceil((timeRemaining * 100) / totalTranscriptionTime);

        //showProgressETA(progressETA, timeRemaining)  //to show estimated time in modal for recordings

        progressBar.style.width = 1 + "%";
        progressBarText.textContent = 1;
        if (uploadedProject) {
          uploadedProject.progress = 1;
        }
        interval = setInterval(() => {
          getKooliospace().then((kooliospace) => {
            if (transcriptionCompleted) {
              // already got transcription completion socket response
              return clearInterval(interval);
            }
            const progressBar =
              displayOn === "workspace"
                ? document.getElementById("progress-percentage-set")
                : document.getElementById(jobname + "_innerProgress");
            const progressBarText =
              displayOn === "workspace"
                ? document.getElementById("transcribe-percentage-text")
                : document.getElementById(jobname + "_innerProgressText");

            timeRemaining = timeRemaining - 5000;
            timeRemainingPercent =
              100 - Math.ceil((timeRemaining * 100) / totalTranscriptionTime);
            //showProgressETA(progressETA, timeRemaining) //to show estimated time in modal for recordings

            if (timeRemainingPercent >= 99) {
              //if time exceeded our expectation
              progressBar.style.width = "99%";
              progressBarText.textContent = "99";
              if (uploadedProject) {
                uploadedProject.progress = "99";
              }
              setTimeout(() => {
                //check if transcription stuck in 99%
                const progress = document.getElementById(
                  uploadedProject.id + "_progress",
                );
                if (progress) getTranscriptionStatusFromSocket(uploadedProject);
              }, 5000);
            } else {
              //progress increment
              progressBar.style.width = timeRemainingPercent + "%";
              progressBarText.textContent = timeRemainingPercent;
              if (uploadedProject) {
                uploadedProject.progress = timeRemainingPercent;
              }
            }
          });
        }, 5000);
      }
    });
  });
}
export async function onwssmessage(message) {
  lastsynced = new Date();
  let body = JSON.parse(message.data);
  console.log("body.type = ", body.type, "body.data = ", body.data);
  try {
    switch (body.type) {
      case "result_stat": {
        let resultStat = body.data;
        let fileSrcURl = await Koolioai.getSignedObject(
          resultStat.speakers_src.key,
        );
        let fileSrcURlSFX = await Koolioai.getSignedObject(
          resultStat.sfx_src.key,
        );
        let arrayBuffer = await getArrayBufferFromSrcURL(fileSrcURl);
        let arrayBufferSFX = await getArrayBufferFromSrcURL(fileSrcURlSFX);

        resultStat.speakers_src = arrayBuffer;
        resultStat.sfx_src = arrayBufferSFX;
        ///

        showStatText("loading audio sources", false);
        await addAudioFile(
          resultStat,
          resultStat.jobname,
          resultStat.created_at,
        );

        const audioBlob = new Blob([resultStat.speakers_src]);
        const blobURL = URL.createObjectURL(audioBlob);

        store.dispatch(
          projectDataSliceActions.projectReducerData({ resultStat }),
        );

        store.dispatch(
          projectTitleSliceActions.updateProjectTitle({
            env: "Kooliospace",
            projectTitle: resultStat.project_title,
          }),
        );
        let trimmedFileName = String(resultStat.project_title).slice(0, -4);
        store.dispatch(
          exportOptionSliceActions.updateFileName({
            fileName: trimmedFileName,
          }),
        );
        store.dispatch(
          uploadFileToWorkspaceActions.uploadFileToWorkspace({
            payload: blobURL,
          }),
        );
        window.location.replace(`/workspace/${resultStat.jobname}`);
        break;
      }
      case "kooliospace": {
        saveKooliospace(body.data);
        // if (!ksUpdatedLocally.length) {
        //     displayUploadWidget()
        // } else {
        //     ksUpdatedLocally.pop()
        // }
        break;
      }
      case "shareduserdata": {
        const userDta = body.data;
        getShareUserData(userDta);
        break;
      }
      ////riffusion
      case "ws_create_music": {
        const state = store.getState();
        const projectData = await retrieveAudioFileByJobname(body.data.jobname);
        let resultStat = projectData.data;
        const jobname = resultStat.jobname;

        if (JSON.parse(message.data).data.jobname === jobname) {
          if (JSON.parse(message.data).data.is_what === "music") {
            let totalNumberOfGenAi = state.genAiWebsocketData.numberOfgenAi;
            let genAicounter = state.genAiWebsocketData.numberOfgenAiProduced;
            const dispatchMsg = JSON.parse(message.data).data;
            if (
              state.genAiWebsocketData.numberOfgenAiProduced <=
              totalNumberOfGenAi
            ) {
              store.dispatch(
                genAiActions.storeWebsocketData({
                  model: "genAi-music",
                  value: dispatchMsg,
                }),
              );

              store.dispatch(genAiActions.increaseGenAiCounter());
            }
            // generatedMusicCount++
            // const searchbar = document.getElementById('sfxSearchBar')
            // const start = searchbar.getAttribute('start')
            // const end = searchbar.getAttribute('end')
            // const wordid = null

            // const invisibleSearchbar = document.getElementById('musicInvisibleSearchBar')

            // invisibleSearchbar.value = JSON.parse(message.data).data.fileName || document.getElementById('genMusicSearchBar').value
            // invisibleSearchbar.setAttribute('onClick', 'searchMusic(event, this.value, ' + start + ', ' + end + ', false, "' + wordid + '", true)')
            // invisibleSearchbar.setAttribute('start', start)
            // invisibleSearchbar.setAttribute('end', end)
            // invisibleSearchbar.setAttribute('wordid', null)
            // if(document.getElementById("createMusicLoader") && generatedMusicCount === desiredMusicCount){
            //     if(desiredMusicCount==1){
            //         if(document.getElementById('genAi-music')){
            //             document.getElementById('genAi-music').remove()
            //         }
            //     }
            //    // preventing progress bar calculation to continue when our process is completed
            //     webSocketMessageForGenAIMusicReceived = true
            //     document.getElementById("createMusicLoader").innerHTML = `<div class="progress position-relative mx-auto rounded-pill"  style="width: 25%; height:4vh; z-index: 0 ; background-color:#6c757d">
            //     <div class="progress-bar h-full" role="progressbar" style="width: 100%; background-color:rgb(226, 82, 43);" aria-valuenow="100 aria-valuemin="0" aria-valuemax="100"></div>
            //     <div style="font-weight:500;color:#c4c4c4;font-size: 1vw" class="position-absolute h-100 w-100 d-flex justify-content-center align-items-center">Completed 100%</div>
            //     </div>`
            // }

            // initializeSfxMusicShow("music")
          } else if (JSON.parse(message.data).data.is_what === "sfx") {
            let totalNumberOfGenAi = state.genAiWebsocketData.numberOfgenAi;
            let genAicounter = state.genAiWebsocketData.numberOfgenAiProduced;
            const dispatchMsg = JSON.parse(message.data).data;
            if (
              state.genAiWebsocketData.numberOfgenAiProduced <=
              totalNumberOfGenAi
            ) {
              store.dispatch(
                genAiActions.storeWebsocketData({
                  model: "genAi-sfx",
                  value: dispatchMsg,
                }),
              );

              store.dispatch(genAiActions.increaseGenAiCounter());
            }
            // while(state.genAiWebsocketData.numberOfgenAiProduced<totalNumberOfGenAi){

            //     // genAicounter++
            // }

            // generatedSFXCount++
            // const searchbar = document.getElementById('sfxSearchBar')
            // const start = searchbar.getAttribute('start')
            // const end = searchbar.getAttribute('end')
            // const wordid = null

            // const invisibleSearchbar = document.getElementById('sfxInvisibleSearchBar')
            // invisibleSearchbar.value = JSON.parse(message.data).data.fileName || document.getElementById('genSFXSearchBar').value

            // // invisibleSearchbar.setAttribute('onClick', 'searchSfx(event, this.value, ' + start + ', ' + end + ', false, "' + wordid + '", true)')
            // invisibleSearchbar.setAttribute('start', start)
            // invisibleSearchbar.setAttribute('end', end)
            // invisibleSearchbar.setAttribute('wordid', null)
            // // && generatedSFXCount === desiredSFXCount

            // if(document.getElementById("createSFXLoader") ){
            //     // if(desiredSFXCount==1){
            //         if(document.getElementById('genAi-sfx')){
            //             document.getElementById('genAi-sfx').remove()
            //         }
            //     // }
            //    // preventing progress bar calculation to continue when our process is completed
            //     // webSocketMessageForGenAISfxReceived =true
            //     document.getElementById("createSFXLoader").innerHTML = `<div class="progress position-relative mx-auto rounded-pill"  style="width: 25%; height:4vh; z-index: 0 ; background-color:#6c757d">
            //     <div class="progress-bar h-full" role="progressbar" style="width: 100%; background-color:rgb(226, 82, 43);" aria-valuenow="100 aria-valuemin="0" aria-valuemax="100"></div>
            //     <div style="font-weight:500;color:#c4c4c4;font-size: 1vw" class="position-absolute h-100 w-100 d-flex justify-content-center align-items-center">Completed 100%</div>
            //     </div>`
            // }

            // initializeSfxMusicShow("sfx")
          } else if (JSON.parse(message.data).data.is_what === "image") {
            let totalNumberOfGenAi = state.genAiWebsocketData.numberOfgenAi;
            let genAicounter = state.genAiWebsocketData.numberOfgenAiProduced;
            // const word = (JSON.parse(message.data).data.fileName || '').split('_')[0]
            const word = JSON.parse(message.data).data;

            if (
              state.genAiWebsocketData.numberOfgenAiProduced <=
              totalNumberOfGenAi
            ) {
              store.dispatch(
                genAiActions.storeWebsocketData({
                  model: "genAi-image",
                  value: word,
                }),
              );

              store.dispatch(genAiActions.increaseGenAiCounter());
              // getAllPrevGenImage(JSON.parse(message.data).data.id,true)
              if (
                state.genAiWebsocketData.numberOfgenAiProduced ==
                totalNumberOfGenAi
              ) {
                document.getElementById("createImageLoader").innerHTML =
                  `<div class="progress position-relative mx-auto rounded-pill"  style="width: 25%; height:4vh; z-index: 0 ; background-color:#6c757d">
                    <div class="progress-bar h-full" role="progressbar" style="width: 100%; background-color:rgb(226, 82, 43);" aria-valuenow="100 aria-valuemin="0" aria-valuemax="100"></div>
                    <div style="font-weight:500;color:#c4c4c4;font-size: 1vw" class="position-absolute h-100 w-100 d-flex justify-content-center align-items-center">Completed 100%</div>
                    </div>`;
              }
            }

            // getAllPrevGenImage(JSON.parse(message.data).data.id,true)
            // if(document.getElementById("createImageLoader" && generatedImageCount == 6) ){
            //
            // }
            // initializeSfxMusicShow("Image")
          }
        }
        break;
      }
      ////riffusion

      case "ws_analytics_chatgpt": {
        let analyticsData = body.data;
        const state = store.getState();
        let resultStat = state.projectData.resultStat;
        const jobname = resultStat.jobname;
        document.getElementById("show-notes-spinner").style.display = "none";
        document.getElementById("show-notes-project-button").disabled = false;
        document
          .getElementById("show-notes-project-button")
          .classList.remove("btn-active");
        try {
          let aiNotesvariable = JSON.parse(analyticsData.response);
          if (analyticsData.jobname === jobname) {
            store.dispatch(
              genAiActions.storeWebsocketData({
                model: "show-notes",
                value: aiNotesvariable.generated_tags,
              }),
            );
            // showGeneratedNotes(aiNotesvariable.generated_tags)
            const currentCount = await getCurrentFeatureWiseCount(
              "allowed_number_of_show_notes",
            );
            await updateTrialFeatureUsageCount(
              "allowed_number_of_show_notes",
              currentCount + 1,
            );
            getAINotesFromDB();
          }
        } catch (e) {
          store.dispatch(
            genAiActions.storeWebsocketData({
              model: "show-notes-error",
              value: { is_what: "show-notes-error" },
            }),
          );
          //showMessage("Unable to process your request at this moment!", `Please, try again after some time.`, 5000, 'error', 'gen-options')
          // $('#half-modals').html(genNotesErrormodal)
          // $('#half-modals').css('visibility', 'visible')
          // koolioModals()
        }
        break;
      }
      case "publishstatus": {
        let currentCount = await getCurrentFeatureWiseCount(
          "allowed_number_of_publishing",
        );
        let provider = body.data.provider;
        try {
          if (provider === apiProviderCastos) {
            const publishMsg = JSON.parse(JSON.parse(body.data.msg));
            const publishStatus = publishMsg.status;
            if (publishStatus === 200) {
              if (ACM.checkAllowedNumberOfPublishingCount() !== -1)
                updateTrialFeatureUsageCount(
                  "allowed_number_of_publishing",
                  currentCount + 1,
                );
              showMessage(
                "Published",
                "Successfully published the project to " +
                  provider.toUpperCase(),
                5000,
                "success",
                "more-options",
              );
            } else if (
              publishStatus !== 200 &&
              publishMsg.message !== undefined
            ) {
              showMessage(
                "Failed",
                publishMsg.message,
                5000,
                "error",
                "more-options",
              );
            } else {
              showMessage(
                "Failed",
                "Publish failed, please try again later",
                5000,
                "error",
                "more-options",
              );
            }
          } else if (provider === apiProviderSpreaker) {
            const publishMsg = JSON.parse(JSON.parse(body.data.msg));
            if (publishMsg.response.episode !== undefined) {
              if (ACM.checkAllowedNumberOfPublishingCount() !== -1)
                updateTrialFeatureUsageCount(
                  "allowed_number_of_publishing",
                  currentCount + 1,
                );
              showMessage(
                "Published",
                "Successfully published the project to " +
                  provider.toUpperCase(),
                5000,
                "success",
                "more-options",
              );
            } else if (
              publishMsg.response.error !== undefined &&
              publishMsg.response.error.messages !== undefined
            ) {
              showMessage(
                "Failed",
                publishMsg.response.error.messages[0],
                5000,
                "success",
                "more-options",
              );
            } else {
              showMessage(
                "Failed",
                "Publish failed, please try again later",
                5000,
                "error",
                "more-options",
              );
            }
          } else if (provider === apiProviderPodbean) {
            const publishMsg = JSON.parse(body.data.msg);
            if (publishMsg.error === undefined) {
              if (ACM.checkAllowedNumberOfPublishingCount() !== -1)
                updateTrialFeatureUsageCount(
                  "allowed_number_of_publishing",
                  currentCount + 1,
                );
              showMessage(
                "Published",
                "Successfully published the project to " +
                  provider.toUpperCase(),
                5000,
                "success",
                "more-options",
              );
            } else if (
              publishMsg.error !== undefined &&
              publishMsg.error_description !== undefined
            ) {
              showMessage(
                "Failed",
                publishMsg.error_description,
                5000,
                "success",
                "more-options",
              );
            } else {
              showMessage(
                "Failed",
                "Publish failed, please try again later",
                5000,
                "error",
                "more-options",
              );
            }
          } else if (provider === apiProviderBuzzsprout) {
            const publishMsg = JSON.parse(JSON.parse(body.data.msg));
            if (publishMsg.published_at !== undefined) {
              if (ACM.checkAllowedNumberOfPublishingCount() !== -1)
                updateTrialFeatureUsageCount(
                  "allowed_number_of_publishing",
                  currentCount + 1,
                );
              showMessage(
                "Published",
                "Successfully published the project to " +
                  provider.toUpperCase(),
                5000,
                "success",
                "more-options",
              );
            } else {
              showMessage(
                "Failed",
                "Publish failed, please try again later",
                5000,
                "error",
                "more-options",
              );
            }
          } else if (provider === apiProviderTransistor) {
            const publishMsg = JSON.parse(JSON.parse(body.data.msg));
            if (
              publishMsg.data !== undefined &&
              publishMsg.data.attributes !== undefined &&
              publishMsg.data.attributes.status !== undefined
            ) {
              if (ACM.checkAllowedNumberOfPublishingCount() !== -1)
                updateTrialFeatureUsageCount(
                  "allowed_number_of_publishing",
                  currentCount + 1,
                );
              showMessage(
                "Published",
                "Successfully published the project to " +
                  provider.toUpperCase(),
                5000,
                "success",
                "more-options",
              );
            } else {
              showMessage(
                "Failed",
                "Publish failed, please try again later",
                5000,
                "error",
                "more-options",
              );
            }
          } else if (provider === apiProviderHeadliner) {
            const response = JSON.parse(body.data.msg);
            let audioUrlMsg = "";
            let fileName = "";
            try {
              if (response.export_url !== "") {
                if (
                  $("#headliner-msg").hasClass("d-block") &&
                  jobHeadOFAudioToPublish === ""
                ) {
                  audioUrlMsg = response.export_url;
                  let parts = audioUrlMsg.split("/");
                  if (parts.length > 0) {
                    fileName = parts[parts.length - 1];
                  }
                  prepareHeadlinerButton(audioUrlMsg);
                  if (ACM.checkAllowedNumberOfPublishingCount() !== -1)
                    updateTrialFeatureUsageCount(
                      "allowed_number_of_publishing",
                      currentCount + 1,
                    );
                } else {
                  updateValueForHeadliner(response);
                  showMessage(
                    "Success",
                    "File is ready to publish in Headliner. Try to publish now.",
                    5000,
                    "success",
                    "more-options",
                  );
                }
              } else {
                showMessage(
                  "Failed",
                  "Publish failed, please try again later",
                  5000,
                  "error",
                  "more-options",
                );
              }
            } catch (e) {
              showMessage(
                "Failed",
                "Publish failed, please try again later",
                5000,
                "error",
                "more-options",
              );
            }
          }
        } catch (e) {
          if (
            body.data.msg.match("User doesn't have access") ||
            body.data.msg.match("Bad credentials")
          ) {
            showMessage(
              "Failed",
              "Please check your api key or session key and try again later",
              5000,
              "error",
              "more-options",
            );
          } else {
            showMessage(
              "Failed",
              "Publish failed, please try again later",
              5000,
              "error",
              "more-options",
            );
          }
        }
        break;
      }
      case "jobstatus": {
        let transcriptionProgressInterval;
        const state = store.getState();
        let uploadProgressStatus = state.uploadProgressStatus.uploadProgress;
        const projectData = await retrieveAudioFileByJobname(body.data.jobname);
        let resultStat = projectData?.data;
        const jobname =
          window.location.href.split("workspace/").length > 1
            ? window.location.href.split("workspace/")[1]
            : null;

        if (jobname && jobname === body.data.jobname) {
          if (body.data.status === "COMPLETED") {
            clearInterval(transcriptionProgressInterval);
            resultStat = await getResultStat(body.data.jobname);
            await updateTranscriptionIndexDB(
              body.data.jobname,
              body.data.refid,
              resultStat,
            ).then(() => {
              setProjectStatus(body.data.jobname, "COMPLETED");
              if (body.data.diarization === "COMPLETED_WO_DIA_ANNOT") {
                return getResultStat(jobname).then((resultStat) => {
                  transcriptionCompleted = true;
                  //showStatText('edit mode is enabled')
                  setModeDependingOnFeatureLockStatus(editFeatureTitle);

                  //if(transcriptionProgressInterval){
                  //    clearInterval(transcriptionProgressInterval)
                  //}
                });
              }
              //  else {
              //     if (!isProjectAlreadyInLoadingPhase || (isProjectAlreadyInLoadingPhase && !transcriptionCompleted)) getStatus(true)
              // }
            });
            if (uploadProgressStatus) {
              const uploadedProject =
                uploadProgressStatus.uploadedProjectList.find(
                  (project) => project.id === jobname,
                );
              uploadedProject.status = "COMPLETED";
            }
            //!check here progress element i think progress come from here
            const progress = document.getElementById(
              body.data.jobname + "_progress",
            );
            const duration = document.getElementById(
              body.data.jobname + "_projectDuration",
            );
            if (progress) {
              transcriptionCompleted = true;
              progress.remove(); //!check
              document.getElementById(
                resultStat.jobname + "_projectDuration",
              ).style.display = "flex";
            }
            if (duration) {
              //duration.textContent = cueFormatters("mm:ss")(kooliojob.duration)
              duration.style.visibility = "visible";
            }

            if (document.getElementById(body.data.jobname + "_view")) {
              document.getElementById(
                body.data.jobname + "_view",
              ).style.opacity = 1.0;
              document.getElementById(
                body.data.jobname + "_view",
              ).style.cursor = "pointer";
              document.getElementById(body.data.jobname + "_view").disabled =
                false;
              // document
              //   .getElementById(body.data.jobname + "_view")
              //   .setAttribute(
              //     "onclick",
              //     document
              //       .getElementById(body.data.jobname + "_view")
              //       .getAttribute("onclick_detail"),
              //   );
            }

            if (document.getElementById(body.data.jobname + "_del")) {
              document.getElementById(
                body.data.jobname + "_del",
              ).style.opacity = 1.0;
              document.getElementById(body.data.jobname + "_del").style.cursor =
                "pointer";
              document.getElementById(body.data.jobname + "_del").disabled =
                false;
              // document
              //   .getElementById(body.data.jobname + "_del")
              //   .setAttribute(
              //     "onclick",
              //     document
              //       .getElementById(body.data.jobname + "_del")
              //       .getAttribute("onclick_detail"),
              //   );
            }

            //store.dispatch(projectDataSliceActions.projectReducerData({""}))
            //window.location.reload(false);
            retrieveAudioFileByJobname(jobname).then(async (projectData) => {
              resultStat = projectData.data;
              store.dispatch(
                projectDataSliceActions.projectReducerData({ resultStat }),
              );
              await loadTranscription(resultStat);
            });
          }
          if (body.data.status === "IN_PROGRESS") {
            var updateKS = async function (message) {
              var ks = await getKooliospace();
              for (
                var kjIndexLocal = 0;
                kjIndexLocal < ks.length;
                kjIndexLocal++
              ) {
                if (ks[kjIndexLocal].jobname === body.data.jobname) {
                  if (ks[kjIndexLocal].status === "Uploading") {
                    if (
                      message === "Transcribing" ||
                      message === "Transcribing With Diarization"
                    ) {
                      // show transcription progress bar
                      transcriptionProgressLoaderNewArch(
                        body.data.jobname,
                        "kooliospace",
                        transcriptionProgressInterval,
                      );
                    }
                    if (message === "Processing") {
                      // show processing
                    }
                    ks[kjIndexLocal].status = message;
                  } else {
                    if (ks[kjIndexLocal].status === "Transcribing") return;
                    ks[kjIndexLocal].status = message;
                  }
                }
              }
            };
            updateKS(body.data.status_message);
            // try {
            //   //document.getElementById(body.data.jobname + "_progress").style.display = 'none'
            //   //if(document.getElementById(body.data.jobname + '_projectDuration').style.visibility != "visible")document.getElementById(body.data.jobname + '_projectDuration').style.visibility = "visible"
            //   document.getElementById(
            //     body.data.jobname + "_view",
            //   ).style.opacity = 1.0;
            //   document.getElementById(
            //     body.data.jobname + "_view",
            //   ).style.cursor = "pointer";
            //   document
            //     .getElementById(body.data.jobname + "_view")
            //     .setAttribute(
            //       "onclick",
            //       document
            //         .getElementById(body.data.jobname + "_view")
            //         .getAttribute("onclick_detail"),
            //     );
            // } catch (e) {
            //   //if(document.getElementById(body.data.jobname + '_projectDuration').style.visibility != "visible")document.getElementById(body.data.jobname + '_projectDuration').style.visibility = "visible"
            //   document.getElementById(
            //     body.data.jobname + "_view",
            //   ).style.opacity = 1.0;
            //   document.getElementById(
            //     body.data.jobname + "_view",
            //   ).style.cursor = "pointer";
            //   document
            //     .getElementById(body.data.jobname + "_view")
            //     .setAttribute(
            //       "onclick",
            //       document
            //         .getElementById(body.data.jobname + "_view")
            //         .getAttribute("onclick_detail"),
            //     );
            // }
            //$('#' + body.data.jobname + ' > .koolio-project-duration').text(body.data.status_message)
          }
          //if (body.data.status_message.match('Error in file conversion')) {
          //    $('#half-modals').html(fileCorruptedMessage)
          //    $('#half-modals').css('visibility', 'visible')
          //    koolioModals()
          //    document.getElementById('file-corrupted').innerHTML = 'File Conversion Failed!'
          //    document.getElementById('file-corrupted-reason').innerHTML = 'file got corrupted. Please try with a different file.'
          //    if (body.data.should_undo_import) {
          //        setTimeout(() => {
          //            return undoOperation()
          //                .then(() => {
          //                    document.getElementById('redo').style.opacity = '0.2'
          //                })
          //        }, 1500)

          //    }
          //}
          //if (body.data.status === 'COMPLETED') {
          //    if(transcriptionProgressInterval){
          //        clearInterval(transcriptionProgressInterval)
          //    }
          //    let currentlocalResultStat = await getResultStat(body.data.jobname)
          //    updateTranscriptionIndexDB(body.data.jobname, body.data.refid, currentlocalResultStat)
          //        .then(()=>{
          //            setProjectStatus(body.data.jobname, 'COMPLETED')
          //            if (body.data.diarization === 'COMPLETED_WO_DIA_ANNOT') {
          //                return getResultStat(jobname)
          //                    .then(resultStat => {
          //                        transcriptionCompleted = true
          //                        //showStatText('edit mode is enabled')
          //                        setModeDependingOnFeatureLockStatus(editFeatureTitle)

          //                        //if(transcriptionProgressInterval){
          //                        //    clearInterval(transcriptionProgressInterval)
          //                        //}
          //                    })
          //            } else {
          //                if (!isProjectAlreadyInLoadingPhase || (isProjectAlreadyInLoadingPhase && !transcriptionCompleted)) getStatus(true)
          //            }
          //        })
          //}
        } else {
          if (body.data.status === "COMPLETED") {
            //clearInterval(transcriptionProgressInterval)
            const transcribedResultStat = await getResultStat(
              body.data.jobname,
            );
            //store.dispatch(projectDataSliceActions.projectReducerData({resultStat}))
            await updateTranscriptionIndexDB(
              body.data.jobname,
              body.data.refid,
              transcribedResultStat,
            );
            let kooliospace = await getKooliospace();
            kooliospace.forEach((kooliojob) => {
              if (kooliojob.jobname === body.data.jobname) {
                kooliojob.status = "COMPLETED";
                transcriptionCompleted = true;
                updateKSFromLocal(kooliojob);
                const progress = document.getElementById(
                  body.data.jobname + "_progress",
                );
                // const duration = document.getElementById(
                // body.data.jobname + "_projectDuration",
                // );
                const duration = document.getElementById(
                  body.data.jobname + "_projectDuration",
                );
                if (progress) {
                  progress.remove();
                }
                if (duration) {
                  duration.textContent = cueFormatters("mm:ss")(
                    kooliojob.duration,
                  );
                  duration.style.display = "flex";
                }
              }
            });
            const uploadedProject =
              uploadProgressStatus.uploadedProjectList.find(
                (project) => project.id === jobname,
              );
            if (uploadedProject) {
              uploadedProject.status = "COMPLETED";
            }
            const progress = document.getElementById(
              body.data.jobname + "_progress",
            );
            const duration = document.getElementById(
              body.data.jobname + "_projectDuration",
            );
            if (progress) {
              transcriptionCompleted = true;
              progress.remove();
            }
            if (duration) {
              //duration.textContent = cueFormatters("mm:ss")(kooliojob.duration)
              duration.style.visibility = "visible";
            }

            if (document.getElementById(body.data.jobname + "_view")) {
              document.getElementById(
                body.data.jobname + "_view",
              ).style.opacity = 1.0;
              document.getElementById(
                body.data.jobname + "_view",
              ).style.cursor = "pointer";
              document.getElementById(body.data.jobname + "_view").disabled =
                false;
              // document
              //   .getElementById(body.data.jobname + "_view")
              //   .setAttribute(
              //     "onclick",
              //     document
              //       .getElementById(body.data.jobname + "_view")
              //       .getAttribute("onclick_detail"),
              //   );
            }

            if (document.getElementById(body.data.jobname + "_del")) {
              document.getElementById(
                body.data.jobname + "_del",
              ).style.opacity = 1.0;
              document.getElementById(body.data.jobname + "_del").style.cursor =
                "pointer";
              document.getElementById(body.data.jobname + "_del").disabled =
                false;
              // document
              //   .getElementById(body.data.jobname + "_del")
              //   .setAttribute(
              //     "onclick",
              //     document
              //       .getElementById(body.data.jobname + "_del")
              //       .getAttribute("onclick_detail"),
              //   );
            }
          }
          if (body.data.status === "IN_PROGRESS") {
            var updateKS = async function (message) {
              var ks = await getKooliospace();
              for (
                var kjIndexLocal = 0;
                kjIndexLocal < ks.length;
                kjIndexLocal++
              ) {
                if (ks[kjIndexLocal].jobname === body.data.jobname) {
                  if (ks[kjIndexLocal].status === "Uploading") {
                    if (
                      message === "Transcribing" ||
                      message === "Transcribing With Diarization"
                    ) {
                      // show transcription progress bar
                      transcriptionProgressLoaderNewArch(
                        body.data.jobname,
                        "kooliospace",
                        transcriptionProgressInterval,
                      );
                    }
                    if (message === "Processing") {
                      // show processing
                    }
                    ks[kjIndexLocal].status = message;
                  } else {
                    if (ks[kjIndexLocal].status === "Transcribing") return;
                    ks[kjIndexLocal].status = message;
                  }
                }
              }
            };
            updateKS(body.data.status_message);
            // try {
            //   //document.getElementById(body.data.jobname + "_progress").style.display = 'none'
            //   //if(document.getElementById(body.data.jobname + '_projectDuration').style.visibility != "visible")document.getElementById(body.data.jobname + '_projectDuration').style.visibility = "visible"
            //   document.getElementById(
            //     body.data.jobname + "_view",
            //   ).style.opacity = 1.0;
            //   document.getElementById(
            //     body.data.jobname + "_view",
            //   ).style.cursor = "pointer";
            //   document
            //     .getElementById(body.data.jobname + "_view")
            //     .setAttribute(
            //       "onclick",
            //       document
            //         .getElementById(body.data.jobname + "_view")
            //         .getAttribute("onclick_detail"),
            //     );
            // } catch (e) {
            //   //if(document.getElementById(body.data.jobname + '_projectDuration').style.visibility != "visible")document.getElementById(body.data.jobname + '_projectDuration').style.visibility = "visible"
            //   // document.getElementById(body.data.jobname + "_view").style.opacity =
            //   //   1.0;
            //   document.getElementById(
            //     body.data.jobname + "_view",
            //   ).style.cursor = "pointer";
            //   document
            //     .getElementById(body.data.jobname + "_view")
            //     .setAttribute(
            //       "onclick",
            //       document
            //         .getElementById(body.data.jobname + "_view")
            //         .getAttribute("onclick_detail"),
            //     );
            // }
            //$('#' + body.data.jobname + ' > .koolio-project-duration').text(body.data.status_message)
          }
          //if (body.data.status.match('ERROR')) {
          //    setProjectStatus(body.data.jobname, 'ERROR')
          //    clearProjectFromUploadList(body.data.jobname)
          //    getKooliospace()
          //        .then(kooliospace => {
          //            let fileName
          //            let oldFilesName
          //            for (let i = 0; i < kooliospace.length; i++) {
          //                if (kooliospace[i].jobname === body.data.jobname) {
          //                    fileName = kooliospace[i].project_title + '.' + kooliospace[i].format
          //                    break
          //                }
          //            }
          //            if (document.getElementById(body.data.jobname + '_projectDuration').style.visibility != "visible") document.getElementById(body.data.jobname + '_projectDuration').style.visibility = "visible"
          //            document.getElementById(body.data.jobname + '_view').style.opacity = 0.5
          //            document.getElementById(body.data.jobname + '_view').style.cursor = 'not-allowed'
          //            document.getElementById(body.data.jobname + '_view').title = 'corrupted'
          //            document.getElementById(body.data.jobname + '_view').onclick = ''
          //            if (document.getElementById('half-modals').style.visibility === 'visible') {
          //                oldFilesName = getOldFilesName()
          //                fileName = fileName + ', ' + oldFilesName
          //            }
          //            if((body.data.status_message.match('Error in file conversion')) || (body.data.status_message.match('Failed transcribing') || body.data.status_message.match('TRANSCRIPTION failed'))){
          //                try{
          //                    //2937 starts here
          //                    //deleting the corrupted project
          //                    deleteKoolioJob(body.data.jobname).then(() => {
          //                        return cacheManager.deleteJobCache(body.data.jobname)
          //                            .then(() => {
          //                                return displayUploadWidget()
          //                            })
          //                    }).catch(e => {
          //                        // console.error(e)
          //                    })
          //                    //showing modal to user
          //                    $('#half-modals').html(fileErrorModal)
          //                    $('#half-modals').css('visibility', 'visible')
          //                    koolioModals()
          //                    document.getElementById('file-error-reupload').addEventListener('click',()=>{
          //                        addProjectKoolioSpace()
          //                        closeHalfModal()
          //                    })
          //                    //restricting user to move to kooliospace for first 2-3 sec so that project can be easily deleted
          //                    setTimeout(()=>{
          //                        if(document.getElementById('file-error-cancel')){
          //                            document.getElementById('file-error-cancel').addEventListener('click',()=>{
          //                                closeHalfModal()
          //                            })
          //                            document.getElementById('file-error-cancel').style.opacity = '1'
          //                        }
          //                        document.getElementById('file-error-cancel').style.pointerEvents = 'unset'
          //                    },3000)

          //                }
          //                catch (error) {
          //                    // console.error(error)
          //                }
          //                //2937 ends here
          //            }
          //        })
          //    $('#' + body.data.jobname + ' > .koolio-project-duration').text(body.data.status_message)
          //}
          //if (body.data.status.match('CREATED')) {
          //    setProjectStatus(body.data.jobname,'CREATED')
          //    // commenting this for 2915, for smooth transition from upload to transcription, not removing it might be helpful in future
          //    // document.getElementById(body.data.jobname + "_innerProgress").style.width = 100 + "%"
          //    // document.getElementById(body.data.jobname + "_innerProgressText").textContent = 100
          //    //document.getElementById(body.data.jobname + "_view").style.opacity = 0.5
          //    //document.getElementById(body.data.jobname + "_view").style.cursor = 'not-allowed'
          //    //document.getElementById(body.data.jobname + "_view").onclick = ""
          //    $('#' + body.data.jobname + ' > .koolio-project-duration').text(body.data.status_message)
          //    if(!JSON.parse(sessionStorage.getItem("transcriptUploadOption"))){
          //        document.getElementById(body.data.jobname + '_view').style.opacity = 1.0
          //        document.getElementById(body.data.jobname + '_view').style.cursor = 'pointer'
          //        document.getElementById(body.data.jobname + '_view').setAttribute('onclick', document.getElementById(body.data.jobname + '_view').getAttribute('onclick_detail'))
          //        clearProjectFromUploadList(body.data.jobname)
          //        transcriptionCompleted = true
          //        setTimeout(()=>{
          //            getKooliospace()
          //                .then(kooliospace => {
          //                    kooliospace.forEach(kooliojob => {
          //                        if(kooliojob.jobname === body.data.jobname) {
          //                            kooliojob.status = 'COMPLETED'
          //                            updateKSFromLocal(kooliojob)
          //                            const progress = document.getElementById(body.data.jobname + "_progress")
          //                            const duration = document.getElementById(body.data.jobname + "_projectDuration")
          //                            if(progress)
          //                                progress.remove()
          //                            if(duration) {
          //                                duration.textContent = cueFormatters("mm:ss")(kooliojob.duration)
          //                                duration.style.visibility = 'visible'
          //                            }
          //                        }
          //                    })
          //                })
          //        },1000)
          //    }
          //}
          //if (body.data.status.match('IN_PROGRESS') && JSON.parse(sessionStorage.getItem("transcriptUploadOption"))) {
          //    setProjectStatus(body.data.jobname,'IN_PROGRESS')
          //    var updateKS = async function (message){
          //        var ks = await getKooliospace()
          //        for (var kjIndexLocal = 0; kjIndexLocal < ks.length; kjIndexLocal++) {
          //            if (ks[kjIndexLocal].jobname === body.data.jobname) {
          //                if (!ks[kjIndexLocal].status) {
          //                    if (message === 'Transcribing'||message==="Transcribing With Diarization") {
          //                        // show transcription progress bar
          //                        transcriptionProgressLoaderNewArch(body.data.jobname, 'kooliospace', transcriptionProgressInterval)
          //                    }
          //                    if (message === 'Processing') {
          //                        // show processing
          //                    }
          //                    ks[kjIndexLocal].status = message
          //                }
          //                else {
          //                    if (ks[kjIndexLocal].status === 'Transcribing')
          //                        return
          //                    ks[kjIndexLocal].status = message
          //                }
          //            }
          //        }
          //    }
          //    updateKS(body.data.status_message)
          //    try {
          //        //document.getElementById(body.data.jobname + "_progress").style.display = 'none'
          //        //if(document.getElementById(body.data.jobname + '_projectDuration').style.visibility != "visible")document.getElementById(body.data.jobname + '_projectDuration').style.visibility = "visible"
          //        document.getElementById(body.data.jobname + '_view').style.opacity = 1.0
          //        document.getElementById(body.data.jobname + '_view').style.cursor = 'pointer'
          //        document.getElementById(body.data.jobname + '_view').setAttribute('onclick', document.getElementById(body.data.jobname + '_view').getAttribute('onclick_detail'))
          //    } catch (e) {
          //        //if(document.getElementById(body.data.jobname + '_projectDuration').style.visibility != "visible")document.getElementById(body.data.jobname + '_projectDuration').style.visibility = "visible"
          //        document.getElementById(body.data.jobname + '_view').style.opacity = 1.0
          //        document.getElementById(body.data.jobname + '_view').style.cursor = 'pointer'
          //        document.getElementById(body.data.jobname + '_view').setAttribute('onclick', document.getElementById(body.data.jobname + '_view').getAttribute('onclick_detail'))
          //    }
          //    $('#' + body.data.jobname + ' > .koolio-project-duration').text(body.data.status_message)
          //}
          ////$('#' + body.data.jobname + ' > .koolio-project-duration').text(body.data.status_message)
          //if (body.data.status == 'COMPLETED') {
          //    clearInterval(transcriptionProgressInterval)
          //    let currentlocalResultStat = await getResultStat(body.data.jobname)
          //    updateTranscriptionIndexDB(body.data.jobname, body.data.refid, currentlocalResultStat)
          //        .then(()=>{
          //            clearProjectFromUploadList(body.data.jobname)
          //            getKooliospace()
          //                .then(kooliospace => {
          //                    kooliospace.forEach(kooliojob => {
          //                        if(kooliojob.jobname === body.data.jobname) {
          //                            kooliojob.status = 'COMPLETED'
          //                            transcriptionCompleted = true
          //                            updateKSFromLocal(kooliojob)
          //                            const progress = document.getElementById(body.data.jobname + "_progress")
          //                            const duration = document.getElementById(body.data.jobname + "_projectDuration")
          //                            if(progress){
          //                                transcriptionCompleted = true
          //                                progress.remove()
          //                            }
          //                            if(duration) {
          //                                duration.textContent = cueFormatters("mm:ss")(kooliojob.duration)
          //                                duration.style.visibility = 'visible'
          //                            }
          //                        }
          //                    })
          //                })
          //            $('#' + body.data.jobname + ' > .koolio-project-duration').text(body.data.status_message)
          //        })

          //}
        }
        break;
      }
      case "commitoperation": {
        if (operationQ[body.data.refid]) {
          operationQ[body.data.refid].complete = true;
          // saveOperation(body.data)
        } else {
          showStatText("synchronizing...");
          if (jobname === body.data.jobname) {
            applyOperations(body.data);
          } else {
            showStatText("synchronize on jobload");
          }
        }
        break;
      }
      case "multitab": {
        Koolioai.goOffline();
        clearTimeout(wssreconnect);
        clearInterval(pinginterval);
        Koolioai.isLive = false;
        showStatText("disconnected", false);
        break;
      }
      case "sync": {
        let resultStat = store.getState().projectData.resultStat;
        if (body.data.jobname !== resultStat.jobname) return;
        if ("operation" in body.data) {
          saveState(resultStat, "replaceAnnot");
          let operation = body.data.operation;
          if (operation.operation.worker === "replaceAnnot") {
            let nestedFiles = store.getState().nestedFile.nestedFiles;
            let nestedFile;
            for (let i = 0; i < nestedFiles.length; i++) {
              if (nestedFiles[i].refid === operation.parent_refid) {
                nestedFile = nestedFiles[i];
              }
            }
            if (nestedFile.length === 0) return;
            let currentStartTime;
            resultStat.speakers_segments.map((segment) => {
              segment.annotations.map((annot) => {
                if (annot.id === nestedFile.id) {
                  currentStartTime = annot.start_time;
                }
              });
            });

            let previousStartTime = nestedFile.start_time;
            let differenceInShift = currentStartTime - previousStartTime;
            let transcriptions = [...operation.operation.inputs.transcript];
            if (differenceInShift > 0) {
              for (let i = 0; i < transcriptions.length; i++) {
                let transcript = transcriptions[i];
                transcript.end_time = transcript.end_time + differenceInShift;
                transcript.start_time =
                  transcript.start_time + differenceInShift;
              }
              operation.operation.inputs.transcript = [...transcriptions];
            }

            const newResultStat = JSON.parse(JSON.stringify(resultStat));
            newResultStat.fades = resultStat.fades ? resultStat.fades : [];
            let newannot, toInsertSegment, nestedFileAnnot;
            if (newResultStat.speakers_segments) {
              newResultStat.speakers_segments.forEach((track) => {
                if (
                  track.start_time <= currentStartTime &&
                  track.end_time >= currentStartTime
                ) {
                  toInsertSegment = track.speaker_label;
                }
                // else if (track.start_time > start) {
                //   track.start_time += end - start;
                //   track.end_time += end - start;
                // }
              });
            }
            operation.operation.inputs.toInsertSegment = toInsertSegment;
            operation.operation.inputs.currentStartTime = currentStartTime;
            operation.operation.inputs.nestedFileId = nestedFile.id;

            [newannot, nestedFileAnnot] = insertAnnotations(
              toInsertSegment,
              currentStartTime,
              0,
              toInsertSegment,
              false,
              transcriptions,
              nestedFile.id,
            );

            if (newResultStat.speakers_segments) {
              newResultStat.speakers_segments.forEach((track, index) => {
                track.annotations = newannot[index];
              });
            }
            newResultStat.refid = operation.refid;
            newResultStat.speakers_src = resultStat.speakers_src;
            newResultStat.sfx_src = resultStat.sfx_src;

            operation.parent_refid = resultStat.refid;
            operation.operation.refid = operation.refid;
            operation.operation.parentRefid = operation.parent_refid;
            operation.operation.alias_id = operation.alias_id;
            operation.operation.jobname = operation.jobname;

            restThings(operation.operation, newResultStat);
            store.dispatch(ToggleSliceActions.updateToggleVal({ value: true }));
            let props = store.getState().propsSlice.props;
            // data.annottrackstack.push(nestedFileAnnot);
            document.getElementById(nestedFileAnnot[0].id + "_div").remove();
            let deleteannot = annotUIshiftSub(newResultStat, props);
            if (deleteannot) {
              //kjsdfhk
              data.annottrackstack.push(deleteannot);
            }
          }
        }

        break;
      }
      case "wsoperation": {
        //let wsoperation = body.data
        websocketOperations.push(body.data);
        if (!isWebsocketApplyOperationActive) {
          showplaylistloader();
          isWebsocketApplyOperationActive = true;
          await initiateApplyOperationFromWebsocket();
          isWebsocketApplyOperationActive = false;
          hideplaylistloader();
        }
        break;
      }
      case "ws_flags":
        const jobnam = store.getState().projectData.resultStat.jobname;
        if (jobnam === body.data.jobname) {
          if (body.data.task === "add") {
            loadFlags(jobnam, true, false, true, body.data.inputs);
            // addFlagInPlaylist(body.data.inputs);
          } else if (body.data.task === "edit") {
            editFlag(body.data.inputs);
            //  editFlagInPlaylist(body.data.inputs)
          } else if (body.data.task === "delete") {
            deleteFlag(true, body.data.inputs);
            //  deleteFlagInPlaylist(body.data.inputs)
          } else {
            //  adjustFlagsInPlaylist(body.data.inputs)
          }
        }
        break;
      case "ws_dissociate_job":
        const projectData = await retrieveAudioFileByJobname(body.data.jobname);
        let resultStat = projectData.data;
        const jobname = resultStat.jobname;
        if (jobname === body.data.jobname) {
          Koolioai.handleError(JSON.parse(body.data.error.body),body.data?.jobname);
        }
        getKooliospace().then((kooliospaces) => {
          // if (!playlist.jobname || playlist.jobname === '') {
          //     clearWorkspace()
          // }
          return Promise.resolve();
        });
        break;
      case "ws_feature_lock":
        // let jobname = store.getState().projectData.resultStat.jobname
        let username = Koolioai.username;
        if (
          body.data.lockedBy &&
          body.data.lockedBy === username &&
          body.data.lockStatus !== "unlocked"
        )
          break;
        if (
          body.data.jobname === store.getState().projectData.resultStat.jobname
        ) {
          if (body.data.lockStatus === "unlocked") {
            const featureLockedByIcon = document.getElementById(
              store.getState().shareAndCollab.editFeatureLockedBy + "-circle",
            );
            if (featureLockedByIcon) {
              featureLockedByIcon.style.backgroundColor = "#E2522B";
              featureLockedByIcon.src =
                "https://ui-avatars.com/api/?name=" +
                store.getState().shareAndCollab.editFeatureLockedBy +
                "&background=181818&color=fff&rounded=true&size=28";
            }

            store.dispatch(
              shareAndCollabActions.updateEditFeatureLockedBy({
                editFeatureLockedBy: "",
              }),
            );
            store.dispatch(
              shareAndCollabActions.updateEditFeatureEnable({
                isEditFeatureEnabled: true,
              }),
            );
            store.dispatch(
              viewEditSliceActions.updateViewEditMode({ mode: "edit" }),
            );

            showMessage(
              "Edit mode is enabled",
              "Please select edit mode to edit the project",
              8000,
              "success",
              "workspace",
            );

            if (
              body.data.lockedBy &&
              body.data.lockedBy === Koolioai.username &&
              body.data.lockStatus === "unlocked" &&
              document.getElementById("control-opt-edit").style.color ===
                "rgb(226, 82, 43)"
            ) {
              // viewControls(true)
              store.dispatch(
                viewEditSliceActions.updateViewMode({ value: true }),
              );
            }
          } else {
            const featureLockedByIcon = document.getElementById(
              store.getState().shareAndCollab.editFeatureLockedBy + "-circle",
            );
            if (featureLockedByIcon) {
              featureLockedByIcon.style.backgroundColor = "#E2522B";
              featureLockedByIcon.src =
                "https://ui-avatars.com/api/?name=" +
                store.getState().shareAndCollab.editFeatureLockedBy +
                "&background=E2522B&color=fff&rounded=true&size=28";
            }

            if (store.getState().shareAndCollab.isEditFeatureEnabled) {
              store.dispatch(
                shareAndCollabActions.updateEditFeatureLockedBy({
                  editFeatureLockedBy: body.data.lockedBy,
                }),
              );
              store.dispatch(
                shareAndCollabActions.updateEditFeatureEnable({
                  isEditFeatureEnabled: false,
                }),
              );
              store.dispatch(
                viewEditSliceActions.updateViewEditMode({ mode: "edit" }),
              );

              if (
                store
                  .getState()
                  .shareAndCollab.editFeatureLockedBy.startsWith("google_")
              ) {
                const url = _config.api + "/get-email";
                const params = {
                  username: store.getState().shareAndCollab.editFeatureLockedBy,
                };
                const response = await axios.get(url, {
                  params,
                  headers: {
                    "Content-Type": "application/json",
                  },
                });

                if (response.status === 200) {
                  store.dispatch(
                    shareAndCollabActions.updateEditFeatureLockedBy({
                      editFeatureLockedBy: response.data.email.split("@")[0],
                    }),
                  );
                }
              }
              const message =
                "Another collaborator, " +
                store.getState().shareAndCollab.editFeatureLockedBy +
                ", is currently working on the project. Please wait or request access from them to edit";
              showMessage(
                "Edit mode disabled",
                message,
                8000,
                "error",
                "workspace",
              );
              store.dispatch(
                viewEditSliceActions.updateViewMode({ value: true }),
              );
              // viewControls(true)
            }
          }
          break;
        }
        break;
      case "ws_get_shareholder_live":
        prepareShareholderStatusView(body.data);
        setTimeout(() => {
          let jobname = store.getState().projectData.resultStat.jobname;
          if (jobname) updateOnlineShareholderView();
        }, 40000);
        break;
      case "__pong__": {
        pingPongConnectivityObj.fileSize =
          encodeURI(message.data).split(/%..|./).length - 1;
        pong();
        break;
      }
      case "notification": {
        showStatText(body.data.msg);
        store.dispatch(
          notificationDetailsActions.updateNotificationsList({
            value: body.data.notifications,
          }),
        );
        store.dispatch(
          notificationDetailsActions.updatenewNotifications({
            value: body.data.count,
          }),
        );
        setNewNotificationCount();
        //displaying the update release info when user is online -2988
        if (body.data.admin_notifications == true && body.data) {
          viewNotification(body.data.notification_action);
        }
        //2988 ends
        break;
      }
      default:
        break;
    }
    clearTimeout(wssreconnect);
  } catch (err) {
    console.trace(err);
  }
}

export function onwssopen() {
  clearTimeout(wssreconnect);
  ping();
  pinginterval = setInterval(ping, 29000); // 29000
}

export function onwsserror(e) {
  clearTimeout(wssreconnect);
  console.error(e);
}

export function onwssclose(message) {
  // clearTimeout(wssreconnect)
}

export function startApp() {
  const username = Koolioai.getDisplayName();
  const email = Koolioai.email;
  const profileImageUrl = `https://ui-avatars.com/api/?name=${username}&background=181818&color=fff&rounded=true&border=ffa500&size=56`;
  initiateACM();
  getNotifications()
    .then((notifications) => {
      store.dispatch(
        notificationDetailsActions.updateNotificationsList({
          value: notifications.notifications,
        }),
      );
      store.dispatch(
        notificationDetailsActions.updatenewNotifications({
          value: notifications.count,
        }),
      );
      setNewNotificationCount();
    })
    .catch((exp) => {
      store.dispatch(notificationDetailsActions.clearNOtifiactionDetails());
    });
  // Setup an interval to determine internet speed depending on pong count.
  /* if(timer === undefined){
        timer = setInterval(calculateInternetConnectivity, 5*60000)
    } */
  Koolioai.goLive(onwssopen, onwsserror, onwssclose, onwssmessage).catch(
    Koolioai.handleError,
  );
  //getUnwantedWordDictionaryForFiltering().then((wordList)=>{
  //    localStorage.setItem('unWantedWords', wordList)
  //})
  //setStripeKeyDependingOnENV()

  setTimeout(pollRefreshToken, 30 * 1000);
  return { name: username, email: email, profileImageUrl: profileImageUrl };
}

export function convertTimeToPixel(time) {
  let wavesurferObj = store.getState().wavesurferObj.wavsurferObj;
  let minPxPerSec = wavesurferObj.params.minPxPerSec;
  return Math.ceil(time * minPxPerSec);
}
export function convertTimeToPixelForRecording(time, wavesurferObj) {
  let minPxPerSec = wavesurferObj.params.minPxPerSec;
  return Math.ceil(time * minPxPerSec);
}

export function convertTimeToVw(time) {
  return (100 * convertTimeToPixel(time)) / window.innerWidth;
}

export function convertTimeToPixelWithPixPerSec(
  startTime,
  endTime,
  width,
  resolution,
  sampleRate,
) {
  const pixPerSec = sampleRate / resolution;
  const pixOffset = 0;
  const horizontaloffset = 0;
  const left = Math.ceil(
    pixPerSec * (startTime + (endTime - startTime) / 2) -
      width / 2 -
      pixOffset +
      horizontaloffset,
  );
  return left;
}

export let isOperationsTransActive = false;
export let operationCounter = 0;
export let importedFileFormat = undefined;
let isWorking = false;

export async function sendOperation() {
  let operationListData = store.getState().operationListData.operationList;
  let operationList = [...operationListData];
  if (operationList.length < 1) return;
  isOperationsTransActive = true;
  while (operationList.length !== 0) {
    operationCounter++; // for backend apply scheduler
    //const operationInfo = operationList.length>1 ?operationList.pop() : operationList[0]
    let operation;
    if (operationList.length > 1) operation = operationList.pop();
    else {
      operation = operationList[0];
      operationList = [];
    }
    const opParams = { ...operation.params };
    const opdata = { ...operation, params: opParams };
    const operationInfo = opdata;
    store.dispatch(
      operationListSliceActions.operationReducerData(operationList),
    );
    try {
      // we are converting float32ArrayBuffer to file format to upload to backend during insert and paste
      if (operationInfo.containsFile) {
        for (let i = 0; i < operationInfo.tracks.length; i++) {
          const refid = operationInfo.params.refid;
          showStatText("uploading...", false);
          if (!importedFileFormat) importedFileFormat = "wav";
          //else importedFileFormat = '.' + importedFileFormat
          let copyBuffer = store.getState().copyBuffer.copyBuffer;
          let bufferFloat32 = operationInfo.tracks[i].src;

          const audioCtx = new (window.AudioContext ||
            window.webkitAudioContext)();
          const durationInSeconds = bufferFloat32.length / audioCtx.sampleRate;

          // Create an empty buffer at the sample rate of the AudioContext
          const audioBuffer = audioCtx.createBuffer(
            1,
            bufferFloat32.length,
            audioCtx.sampleRate,
          );

          // Copy the audio data from the Float32Array to the AudioBuffer
          const channelData = audioBuffer.getChannelData(0);
          channelData.set(bufferFloat32);

          // Log the details of the created AudioBuffer

          const audioBlob = await convertAudioBufferToBlob(audioBuffer);
          let file = new File([audioBlob], refid + ".wav", {
            type: audioBlob.type,
            lastModified: Date.now(),
          });

          await Koolioai.uploadClipboard(
            file,
            refid + "." + importedFileFormat,
          ); //'.wav'
          showStatText("uploaded", false);

          // if (
          //   operationInfo.trackInfo.customClass.split("-")[0] === "speakers"
          // ) {
          operationInfo.params.filename =
            operationInfo.params.refid + "." + importedFileFormat;
          operationInfo.params.trackid = operationInfo.tracks[i].id;
          operationInfo.params.customClass =
            operationInfo.trackInfo.customClass;
          // }
        }
      }
      let res = await sendOperationToBackend(operationInfo);
      if (res.status === 200) {
        if (
          operationInfo.containsFile &&
          operationInfo.trackInfo.isImport &&
          operationInfo.trackInfo.customClass?.split("-")[0] === "speakers"
        ) {
          let transcriptionRequired = JSON.parse(
            sessionStorage.getItem("NestedtranscriptUploadOption"),
          );
          if (transcriptionRequired) {
            await initiateTranscription(operationInfo.params);
            showStatText("transcription initiated", false);
          }
        }
        if (operationInfo.params.worker === "undo") {
          updateIndexedDBoperation(
            operationInfo.params.parentRefid,
            "synchronized",
            "true",
          );
        } else {
          updateIndexedDBoperation(
            operationInfo.params.refid,
            "synchronized",
            "true",
          );
        }

        //if (operationInfo.sync) {
        //    updateWorkspaceWithOrigin(operationInfo.params.refid)
        //        .then(() => {
        //            playlist.setMode('edit')
        //        })
        //}
      } else if (res.status === 201) {
        showStatText("origin is ahead of local");
        hideplaylistloader();
        operationList = [];
        //await updateWorkspaceWithOrigin(operationInfo.parentRefid)
      } else if (res.status === 404) {
        operationList = [];
        showStatText("conflict", false);
      } else {
        operationList = [];
        showStatText("unknown status", res.status);
      }
    } catch (e) {
      console.error(e);
    }
  }
  if (operationList.length < 1) {
    showStatText("synchronized");
    //if(creatingCustomClass){
    //    creatingCustomClass=false
    //}
  }
  isOperationsTransActive = false;
  store.dispatch(
    operationListSliceActions.operationReducerData(isOperationsTransActive),
  );
  importedFileFormat = undefined;
}

export async function getCurrentFeatureWiseCount(feature) {
  let featureWiseCountList = JSON.parse(
    localStorage.getItem("featureWiseCount"),
  );
  if (featureWiseCountList) {
    for (let i = 0; i < featureWiseCountList.length; i++) {
      if (featureWiseCountList[i].feature === feature) {
        return featureWiseCountList[i].count;
      }
    }
  }
  return 0;
}

export async function updateTrialFeatureUsageCount(feature, count) {
  await updateFeatureWiseCount(feature, count);
  const params = {
    username: Koolioai.username,
    feature: feature,
    count: count,
  };
  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
    (tokens) => {
      return axios
        .post(_config.api + "/trial-feature-usage-count", params, {
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        })
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((e) => {
          return Promise.reject(e);
        });
    },
  );
}

async function updateFeatureWiseCount(feature, count) {
  let featureWiseCountList = JSON.parse(
    localStorage.getItem("featureWiseCount"),
  );
  for (let i = 0; i < featureWiseCountList.length; i++) {
    if (featureWiseCountList[i].feature === feature) {
      featureWiseCountList[i].count = count;
    }
  }
  localStorage.setItem(
    "featureWiseCount",
    JSON.stringify(featureWiseCountList),
  );
}

export async function exportHighQualityAudio(
  options,
  aiEnabled = false,
  publish = false,
  publishInfo = {},
) {
  let option = JSON.stringify(options);
  let publishIn = JSON.stringify(publishInfo);
  if (publish) {
    document.getElementById("publish-button").disabled = true;
    document.getElementById("publish-button").style.backgroundColor = "#E2522B";
    document.getElementById("publish-button").style.opacity = "0.4";
    document.getElementById("publish-button").style.color = "#c4c4c4";
    let jobname = store.getState().projectData.resultStat.jobname;
    return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
      (tokens) =>
        axios
          .get(_config.api + "/hqexport", {
            params: {
              jobname,
              options: option,
              publish,
              publishInfo: publishIn,
              aiEnabled,
            },
            headers: {
              Authorization: `${tokens[0]}`,
              AccessToken: `${tokens[1]}`,
            },
          })
          .then(() => {
            setTimeout(() => {
              document.getElementById("publish-button").disabled = false;
              document.getElementById("publish-button").style.backgroundColor =
                "#0c0c0c";
              document.getElementById("publish-button").style.opacity = "1";

              document
                .getElementById("publish-button")
                .addEventListener("mouseover", function () {
                  document.getElementById(
                    "publish-button",
                  ).style.backgroundColor = "#E2522B";
                  document.getElementById("publish-button").style.color =
                    "#ffffff";
                });

              document
                .getElementById("publish-button")
                .addEventListener("mouseout", function () {
                  document.getElementById(
                    "publish-button",
                  ).style.backgroundColor = "#0c0c0c";
                  document.getElementById("publish-button").style.color =
                    "#c4c4c4";
                });
            }, 10000);
            return showMessage(
              "Processing...",
              "Please wait, we are processing the project to publish",
              10000,
              "success",
              "more-options",
            );
          }),
    );
  }
  let jobname = store.getState().projectData.resultStat.jobname;

  return Promise.all([Koolioai.authToken(), Koolioai.accessToken()]).then(
    (tokens) =>
      axios
        .get(_config.api + "/hqexport", {
          params: {
            jobname,
            options: option,
            publish,
            publishInfo: publishIn,
            aiEnabled,
          },
          headers: {
            Authorization: `${tokens[0]}`,
            AccessToken: `${tokens[1]}`,
          },
        })
        .then((res) => {
          showMessage(
            "Processing...",
            "Please wait, we are processing the project for Enhanced audio",
            10000,
            "success",
            "more-options",
          );
          return res;
        }),
  );
}

export async function downloadFromURL(link, options) {
  let state = store.getState().selectedApiProvider;
  const selectedApiProvider = state.selectedApiProvider;
  const isNativeExport = state.isNativeExport;
  // Check if native export is enabled
  if (isNativeExport) {
    // Create a new anchor element
    const $link = $("<a/>", {
      href: link,
      id: "downloadlink",
      download: options.filename + "." + options.format,
      class: "btn btn-small btn-download-link",
      style: "display: none;",
    });

    $(".btn-download-link").remove();
    $("#controls").after($link);
    document.getElementById("downloadlink").click();
    $("#downloadlink").remove();
  } else {
    // Check if the selected API provider is the headliner
    if (apiProviderHeadliner === selectedApiProvider) {
      // Publish audio with the selected API provider
      await publishAudio(selectedApiProvider, "", "", null, "", "", options);
    } else {
      // Fetch the audio file as a blob
      const blob = await fetch(link).then((r) => r.blob());

      // Check if a selected API provider is provided
      if (selectedApiProvider !== "" && selectedApiProvider !== undefined) {
        // Get the API key, show title or podcast ID, episode title, and episode content
        const apiKey = document.getElementById("api-key").value;
        const showTitleOrPodcastID =
          document.getElementById("podcast-id").value;
        const episodeTitle = document.getElementById("episode-title").value;
        const episodeContent = document.getElementById("episode-content").value;

        // Check if the API key input field is visible
        if (document.getElementById("api-key").classList.contains("block")) {
          // Check if the API key is provided
          if (apiKey === "" || apiKey === undefined) {
            // Show a message to provide an API key
            showStatText("Please provide API Key!");
          } else {
            // Publish audio with the selected API provider
            await publishAudio(
              selectedApiProvider,
              showTitleOrPodcastID,
              episodeTitle,
              blob,
              apiKey,
              episodeContent,
              options,
            );
          }
        } else {
          // Publish audio with the selected API provider
          await publishAudio(
            selectedApiProvider,
            showTitleOrPodcastID,
            episodeTitle,
            blob,
            apiKey,
            episodeContent,
            options,
          );
        }
      }
    }
  }
  // Set isNativeExport to true
  store.dispatch(
    SelectedApiProviderSliceActions.updateIsNativeExport({
      isNativeExport: true,
    }),
  );
}

export async function initiateACM() {
  return ACM.initiate();
}

export function checkAccountDetails() {
  // other variables
  const remainingDays = getRemainingDays();

  //html elements to hold account role and validity
  const accountRoleHolder = document.getElementById("account-role");
  const accountValidityHolder = document.getElementById("account-validity");

  if (
    ACM.role !== "trial" &&
    ACM.planId !== freePlanId &&
    ACM.planId !== adminPlanId &&
    ACM.planName.toLowerCase() !== "admin"
  ) {
    document.getElementById("account-role").innerHTML = ACM.planName; //+ ' Account' //'Admin Account'
    let expiryText = "(Expires on ";
    if (remainingDays < 0) expiryText = "(Expired on ";

    accountValidityHolder.innerHTML = expiryText + ACM.planExpiry + ")";
    accountValidityHolder.style.visibility = "visible";
    return false;
  } else if (ACM.planId === freePlanId || ACM.planId === adminPlanId) {
    document.getElementById("account-role").innerHTML =
      ACM.planName + " Account"; //'Admin Account'
    accountValidityHolder.innerHTML = "";
    return false;
  } else {
    document.getElementById("account-role").innerHTML = "Trial Account";
  }

  //if admin, nothing to do further
  if (ACM.role === "admin") {
    accountRoleHolder.innerHTML = "Admin Account";

    return false;
  } else if (ACM.role === "trial") {
    accountRoleHolder.innerHTML = "Trial Account";

    // if (remainingDays <= 7) {
    //   //if role is trial and near end of trial-1, ask for trial extension
    //   if (ACM.getPlanID() === plans["trial-1"]) {
    //     accountRoleHolder.innerHTML = "Click to extend trial";
    //     $("#account-extension").attr("onclick", "confirmExpiryExtension()");
    //   }
    //   //if role is trial and near end of trial-2, ask for subscription plan
    //   else if (ACM.getPlanID() === plans["trial-2"]) {
    //     // show subscription page
    //     showPlanSubscriptionModal((wasOnTrial = true));
    //   }
    // } else {
    //   $("#account-extension").attr("onclick", "");
    // }
  }
  // else user is on some plan
  else {
    // need to show plan name

    accountRoleHolder.innerHTML = ACM.getPlanName() + " Plan";

    if (remainingDays <= 7) {
      //show subscription page
      <SubscriptionModal />;
      // showPlanSubscriptionModal();
    }
  }
  if (ACM.isPlanActive) {
    if (remainingDays > 1) {
      accountValidityHolder.innerHTML = "(" + remainingDays + " days left)";
    } else if (remainingDays === 1) {
      accountValidityHolder.innerHTML = "(" + remainingDays + " day left)";
    } else {
      accountValidityHolder.innerHTML = "(Expired)";
    }
    accountValidityHolder.style.visibility = "visible";
  }
}

export function getRemainingDays() {
  const today = getCurrentDate();
  const expiryDate = ACM.planExpiry.split("-");
  const parsedExpiryDate =
    expiryDate[1] + "-" + expiryDate[2] + "-" + expiryDate[0];
  const remainingDays = datediff(parseDate(today), parseDate(parsedExpiryDate));
  return remainingDays;
}

function getCurrentDate() {
  let today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  const yyyy = today.getFullYear();
  today = mm + "-" + dd + "-" + yyyy;
  return today;
}

function parseDate(str) {
  const mdy = str.split("-");
  return new Date(mdy[2], mdy[0] - 1, mdy[1]);
}

function datediff(first, second) {
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.
  return Math.round((second - first) / (1000 * 60 * 60 * 24));
}

export async function checkFileConversionStatus(jobname) {
  const file = await retrieveAudioFileByJobname(jobname);
  const conversionStatus = file.data.status;
  if (conversionStatus && conversionStatus === "COMPLETED") {
    return true;
  } else {
    return false;
  }
}

export function validateEmail(email) {
  // eslint-disable-next-line no-useless-escape
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

let backendApplyScheduler;
export function initiateBackendApplyScheduler() {
  let resultStat = store.getState().projectData.resultStat;
  if (resultStat && !backendApplyScheduler) {
    backendApplyScheduler = setInterval(
      () => {
        if (operationCounter > 19 && resultStat.jobname) {
          operationCounter = 0;
          applyOperationsInBackend(resultStat.jobname, true);
        }
      },
      5 * 60 * 1000,
    );
  }
}
export function clearBackendApplyScheduler() {
  if (!backendApplyScheduler) clearInterval(backendApplyScheduler);
  backendApplyScheduler = undefined;
  operationCounter = 0;
}

export function scrollToSfx(index) {
  const sfxsSliderTranscript = document.getElementById(
    "sfx-sliders-transcript",
  );

  const sfxs = Array.from(
    new Set(
      document.querySelectorAll(
        `#${sfxsSliderTranscript ? "sfx-sliders-transcript" : "sfx-sliders"} [id*="-slider-sfx"]`,
      ),
    ),
  );

  if (!sfxs) return;
  if (index < 0 || index >= sfxs.length) return;

  sfxs[index].scrollIntoView({
    behavior: "smooth",
    inline: "center",
    block: "center",
  });
}

export function loadSlickSliders(resultStat) {
  $(".slider-dp").remove();
  $(".slider-text").remove();

  let counter = {};
  Object.keys(resultStat.speakers).forEach((key, index) => {
    let sldCounter = resultStat.speakers[key].id + 1;
    let customClass = resultStat.speakers[key].customClass,
      name = resultStat.speakers[key].name;
    let customClassObj = {
      id: index,
      name,
      customClass,
      trackType: "speakers",
      gain: 1.0,
      panning: 0,
      img:
        "https://ui-avatars.com/api/?name=" +
        name +
        "&background=BB417C&rounded=true&size=31&color=fff",
      fades: [],
    };
    const id = customClass + "-slider";

    $("#speakers-sliders").append(
      '<div id="' +
        id +
        `" class="${index === 0 ? "slick-current" : ""} slider-dp slider-speakers">` +
        "<a data-customclass='" +
        customClassObj.customClass +
        "' data-slide='" +
        sldCounter +
        "'>" +
        "<img id='" +
        id +
        "-spk" +
        "' title='" +
        customClassObj.name +
        "' src='" +
        customClassObj.img +
        "' class='spk-slid'>" +
        "</a>" +
        "</div>",
    );
    const idT = customClass + "-slider-transcript";

    $("#speakers-sliders-transcript").append(
      '<div id="' +
        idT +
        `"class="${index === 0 ? "slick-current" : ""} slider-dp slider-speakers">` +
        "<a data-customclass='" +
        customClassObj.customClass +
        "' data-slide='" +
        counter["speaker"] +
        "'>" +
        "<img id='" +
        idT +
        "-spk" +
        "' title='" +
        customClassObj.name +
        "' src='" +
        customClassObj.img +
        "' class='spk-slid'>" +
        "</a>" +
        "</div>",
    );
  });
  // After creating speakers sliders
  const speakerSliderElements = document.querySelectorAll(
    "#speakers-sliders .slider-speakers a, #speakers-sliders-transcript .slider-speakers a",
  );
  speakerSliderElements.forEach((a) => {
    const tooltipContent = a.querySelector("img").title; // Use the title as tooltip content
    a.querySelector("img").removeAttribute("title"); // Remove the native tooltip

    tippy(a, {
      content: tooltipContent, // Use speaker name in Tippy.js tooltip
      trigger: "mouseenter", // Show on hover
      hideOnClick: true, // Hide the tooltip on click
      animation: "fade", // Fade animation
      delay: [500, 100], // Delay for showing and hiding
      theme: "custom", // Use a custom theme
      onShow(instance) {
        const tooltipBox = instance.popper.firstElementChild;
        tooltipBox.style.backgroundColor = "black"; // Set background to black
        tooltipBox.style.color = "white"; // Set text color to white
        tooltipBox.style.borderRadius = "4px"; // Optional: Rounded corners
        tooltipBox.style.padding = "8px"; // Optional: Add padding
      },
    });
  });

  const speakersSlider = document.getElementById("speakers-sliders");
  const speakersSliderTranscript = document.getElementById(
    "speakers-sliders-transcript",
  );
  const speakers = Array.from(
    document.querySelectorAll(
      `#${speakersSliderTranscript ? "speakers-sliders-transcript" : "speakers-sliders"} .slider-speakers`,
    ),
  );

  if (!speakers) return; // If there are no speakers, return

  for (const speaker of speakers) {
    speaker.addEventListener("click", () => {
      // Use forEach to prevent blocking rest of the function
      speakers.forEach((speaker1) => {
        if (speaker1.id === speaker.id) {
          speaker1.classList.add("slick-current");
          return;
        }

        speaker1.classList.remove("slick-current");
      });

      if (speakers.length < 2) return;

      let soloSpkList = [];

      resultStat.speakers_segments.forEach((annot, index) => {
        if (
          annot.speaker_label.replace("speakers-", "") ===
          speaker.id.replace("-slider", "").split("-").pop()
        ) {
          let result = [...document.getElementsByClassName(`speaker-${index}`)];

          result.forEach((element) => {
            element.classList.replace("inactive-annot", "active-annot");
          });
        } else {
          let result = [...document.getElementsByClassName(`speaker-${index}`)];

          result.forEach((element) => {
            element.classList.replace("active-annot", "inactive-annot");
          });
        }
        if (
          parseInt(speaker.id.match(/^\d+/), 10) ===
          parseInt(annot.speaker_label.match(/\d+$/), 10)
        )
          soloSpkList.push({ start: annot.start_time, end: annot.end_time });
        const currentSpeakerID = document
          .querySelector(".slick-current")
          ?.id.split("-")[1];
        if (!currentSpeakerID) return;
        const { speakers_segments: speakerSegments } =
          store.getState().projectData.resultStat;
        speakerSegments.forEach((segment) => {
          segment.annotations.forEach((annotation) => {
            const annotationElement = document.getElementById(
              `${annotation.id}_div`,
            );
            if (annotationElement) {
              const isActive =
                annotation.speaker_label.split("_")[1] === currentSpeakerID;
              annotationElement.classList.toggle("active-annot", isActive);
              annotationElement.classList.toggle("inactive-annot", !isActive);
            }
          });
        });
      });

      store.dispatch(soloListActions.updateSoloSpkList(soloSpkList));

      if (speakersSlider) {
        let leftValue = "calc((70 / 1440) * 100vw)";

        if (speaker.id.replace("-slider", "") === "speakers-0")
          leftValue = "calc((100 / 1440) * 100vw)";

        if (
          speaker.id.replace("-slider", "") ===
          `speakers-${speakers.length - 1}`
        )
          leftValue =
            speakers.length > 2
              ? "calc((34 / 1440) * 100vw)" // Only if the number of speakers is more than two
              : "calc((66 / 1440) * 100vw)"; // Only if the number of speakers is two

        speakersSlider.style.left = leftValue; // Adjust the left value of the slider
      }

      if (speakersSliderTranscript) {
        let leftValue = "38.6%";

        if (speaker.id.replace("-slider", "") === "speakers-0")
          leftValue = "41%";

        if (
          speaker.id.replace("-slider", "") ===
          `speakers-${speakers.length - 1}`
        )
          leftValue = "36.2%";

        speakersSliderTranscript.style.left = leftValue; // Adjust the left value of the slider
      }

      // Scroll to the selected speaker
      speaker.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "center",
      });
    });

    speaker.addEventListener("contextmenu", (e) => {
      if (store.getState().viewEditMode.mode === "view") {
        showStatText("operation is not permitted in view mode");
        return;
      }

      e.preventDefault();

      let tracktype = speaker.id.match(/^[a-zA-Z]+-\d+/)[0];

      store.dispatch(
        speakerBtnClickedSliceActions.updateSpeakerRightClick({
          value: true,
        }),
      );

      store.dispatch(
        speakerBtnClickedSliceActions.updateSpeakerType({
          tracktype,
        }),
      );
    });
  }

  Object.keys(resultStat.sfx).forEach((key, index) => {
    let name = resultStat.sfx[key].name,
      customClass = resultStat.sfx[key].customClass;
    let title = name
      .split(" ")
      .map((word, index) => (index < 2 ? word[0].toUpperCase() : word))
      .join("");
    const id = customClass + "-slider";

    $("#sfx-sliders").append(
      '<div id="' +
        id +
        '" class="slider-text slider-sfx">' +
        "<a data-customclass='" +
        customClass +
        "' data-slide='" +
        resultStat.sfx[key].id +
        1 +
        "'>" +
        "<span data-bs-placement='bottom' id='" +
        id +
        "-sfx" +
        "' title='" +
        name +
        "' class='sfx-text'> " +
        title +
        "</span></a>" +
        "</div>",
    );

    $("#sfx-sliders-transcript").append(
      '<div id="' +
        id +
        '" class="slider-text slider-sfx">' +
        "<a data-customclass='" +
        customClass +
        "' data-slide='" +
        resultStat.sfx[key].id +
        1 +
        "'>" +
        "<span data-bs-placement='bottom' id='" +
        id +
        "-sfx" +
        "' title='" +
        name +
        "' class='sfx-text'> " +
        title +
        "</span></a>" +
        "</div>",
    );

    scrollToSfx(0);
  });

  // After creating SFX sliders
  const sfxSliderElements = document.querySelectorAll(
    "#sfx-sliders .slider-sfx a, #sfx-sliders-transcript .slider-sfx a",
  );
  sfxSliderElements.forEach((a) => {
    const tooltipContent = a.querySelector(".sfx-text").title; // Use the title as tooltip content
    a.querySelector(".sfx-text").removeAttribute("title"); // Remove the native tooltip

    tippy(a, {
      content: tooltipContent, // Use SFX name in Tippy.js tooltip
      trigger: "mouseenter", // Show on hover
      hideOnClick: true, // Hide the tooltip on click
      animation: "fade", // Fade animation
      delay: [500, 100], // Delay for showing and hiding
      theme: "custom", // Use a custom theme
      onShow(instance) {
        const tooltipBox = instance.popper.firstElementChild;
        tooltipBox.style.backgroundColor = "black"; // Set background to black
        tooltipBox.style.color = "white"; // Set text color to white
        tooltipBox.style.borderRadius = "4px"; // Optional: Rounded corners
        tooltipBox.style.padding = "8px"; // Optional: Add padding
      },
    });
  });

  const sfxs = Array.from(document.getElementsByClassName("slider-sfx"));
  if (!sfxs) return;

  for (const sfx of sfxs) {
    sfx.addEventListener("contextmenu", (e) => {
      if (store.getState().viewEditMode.mode === "view") {
        showStatText("operation is not permitted in view mode");
        return;
      }

      e.preventDefault();

      let tracktype = sfx.id.match(/^[a-zA-Z]+-\d+/)[0];

      store.dispatch(
        speakerBtnClickedSliceActions.updateSpeakerRightClick({
          value: true,
        }),
      );

      store.dispatch(
        speakerBtnClickedSliceActions.updateSpeakerType({
          tracktype,
        }),
      );
    });
  }
}
export function createAnnotDiv(annot, toInsertSegment) {
  let wavesurferObj = store.getState().wavesurferObj.wavsurferObj;
  let wavesurferObjSFX = store.getState().wavesurferObjSFX.wavesurferObjSFX;
  let start = annot.start_time;
  let end = annot.end_time;
  let transcriptionsDiv = document.getElementById("transcriptions_div");
  const annotdata = document.createElement("div");
  annotdata.id = `${annot.id}_div`;
  let startTime = parseFloat(start);
  let endTime = parseFloat(end);
  let annotWidth = convertTimeToVw(endTime - startTime);
  let annotLeft = convertTimeToPixel(startTime);
  annotdata.setAttribute(
    "style",
    `display: inline; transition: all 0.3s; cursor: pointer; font-size: 0.8em;  position: absolute; width: ${annotWidth}vw; left: ${annotLeft}px; text-align: center;`,
  );
  annotdata.classList.add("active-annot");
  annotdata.classList.add(toInsertSegment);
  annotdata.addEventListener("click", function (e) {
    const regionaudio = wavesurferObj.regions.list;
    const regionSFX = wavesurferObjSFX.regions.list;
    for (const regionKey in regionaudio) {
      regionaudio[regionKey].remove();
    }
    for (const regionKey in regionSFX) {
      regionSFX[regionKey].remove();
    }
    e.preventDefault();
    wordMultiSelect(annot);
    // annotdata.classList.add("clicked-annot");
    annotdata.style.transform = "scale(1.1)";
    wavesurferObj.addRegion({
      start: parseFloat(start), // start time of the region
      end: parseFloat(end), // end time of the region
      color: "hsla(265, 100%, 86%, 0.4)", // color of the region
    });
  });
  annotdata.addEventListener("dblclick", function (e) {
    e.preventDefault();
    if (store.getState().viewEditMode.mode != "view")
      openTextEditor(annotdata, annot, "updateAnnot");
    else showStatText("operation not permited in view mode");
  });
  annotdata.addEventListener("contextmenu", function (e) {
    const regionaudio = wavesurferObj.regions.list;
    const regionSFX = wavesurferObjSFX.regions.list;
    for (const regionKey in regionaudio) {
      regionaudio[regionKey].remove();
    }
    for (const regionKey in regionSFX) {
      regionSFX[regionKey].remove();
    }
    e.preventDefault(); // Prevent the default context menu
    annotdata.classList.add("right-clickedannot");
    store.dispatch(
      sfxModalSliceActions.updateSfxModal({
        showModal: true,
        clickedAnnot: annot.content,
      }),
    );
    wavesurferObj.addRegion({
      start: parseFloat(annot.start_time), // start time of the region
      end: parseFloat(annot.end_time), // end time of the region
      color: "hsla(265, 100%, 86%, 0.4)", // color of the region
    });
  });

  annotdata.innerText = annot.content.replace(/\s+/g, "");

  transcriptionsDiv.appendChild(annotdata);
  //const transcriptRegion = document.querySelector(`[data-id=${annot.id}_region]`)
  //transcriptRegion.appendChild(annotdata)
  const transcriptionWrapper = document.getElementById("transcription-wrapper");

  if (transcriptionWrapper) {
    transcriptionWrapper.appendChild(transcriptionsDiv);
  }
  return annotdata;
}
export async function addAnnotation(event) {
  if (event.target !== event.currentTarget) {
    return;
  }
  let wavesurferObj = store.getState().wavesurferObj.wavsurferObj;
  const wavesurferObjSFX = store.getState().wavesurferObjSFX.wavesurferObjSFX;
  let res = store.getState().projectData.resultStat;
  let resultstat = JSON.parse(JSON.stringify(res));
  resultstat.fades = res.fades ? res.fades : [];
  resultstat.speakers_src = res.speakers_src;
  resultstat.sfx_src = res.sfx_src;
  const region = Object.values(wavesurferObj.regions.list)[0];

  if (region && store.getState().viewEditMode.mode != "view") {
    var buffer = wavesurferObj.backend.buffer;
    var start = Math.floor(region.start * buffer.sampleRate);
    var end = Math.floor(region.end * buffer.sampleRate);
    var data = buffer.getChannelData(0).slice(start, end);
    var hasAudio = data.some(function (amplitude) {
      return Math.abs(amplitude) > 0.01; // Replace 0.01 with the threshold you want
    });
    if (hasAudio) {
      let toInsertSegment;
      let annot;
      let start = region.start;
      let end = region.end;
      resultstat.speakers_segments.forEach((track) => {
        if (
          track.start_time <= region.start &&
          track.end_time >= region.start
        ) {
          toInsertSegment = track.speaker_label;
          annot = {
            confidence: 1,
            content: "Text",
            end_time: end.toFixed(2),
            id: `spk_${toInsertSegment.charAt(toInsertSegment.length - 1)}_${create_UUID()}`,
            is_silenced: false,
            speaker_label: `spk_${toInsertSegment.charAt(toInsertSegment.length - 1)}`,
            start_time: start.toFixed(2),
          };
          track.annotations.push(annot);
        }
      });
      store.dispatch(
        projectDataSliceActions.projectReducerData({ resultStat: resultstat }),
      );
      let annotdata = createAnnotDiv(annot, toInsertSegment);
      openTextEditor(annotdata, annot, "addAnnot");
    } else {
      showStatText("there is no audio in the selected region", false);
    }
  }
}
export async function loadTranscription(resultStat) {
  const wavesurferObj = store.getState().wavesurferObj.wavsurferObj;
  const wavesurferObjSFX = store.getState().wavesurferObjSFX.wavesurferObjSFX;
  let duration = wavesurferObj.getDuration();
  let width = wavesurferObj.params.minPxPerSec * duration;
  let existingElement = document.getElementById("transcriptions_div");
  if (existingElement) {
    existingElement.remove(); // Remove the existing one if necessary
  }
  const transcriptionsDiv = document.createElement("div");
  transcriptionsDiv.id = "transcriptions_div";
  transcriptionsDiv.setAttribute(
    "style",
    `position: absolute; overflow: hidden; height: 4vh;width:${width}px; margin-top:1vh; font-weight:700;`,
  );
  transcriptionsDiv.addEventListener("dblclick", function (event) {
    addAnnotation(event);
  });
  const transcriptionWrapper = document.getElementById("transcription-wrapper");
  if (transcriptionWrapper) {
    transcriptionWrapper.appendChild(transcriptionsDiv);
  }
  const sfxtranscriptionsDiv = document.createElement("div");
  sfxtranscriptionsDiv.id = "transcriptions_div_sfx";
  sfxtranscriptionsDiv.setAttribute(
    "style",
    `position: absolute; overflow: hidden;width:${width}px; height: 4vh; margin-top:1vh; font-weight:700;`,
  );
  const sfxtranscriptWrapper = document.getElementById(
    "transcription-wrapper-sfx",
  );
  if (sfxtranscriptWrapper) {
    sfxtranscriptWrapper.appendChild(sfxtranscriptionsDiv);
  }
  resultStat?.sfx_segments.map((segment, ind) => {
    segment.annotations?.forEach((annot) => {
      const annotation = {
        id: `${segment.id}`,
        start: parseFloat(annot.start_time),
        end: parseFloat(annot.end_time),
        data: annot,
        drag: true,
        resize: true,
        speaker_label: annot.speaker_label,
        //color: "#00FFFFFF"
      };

      const annotdata = document.createElement("div");
      annotdata.id = `${annot.id}_div`;
      let startTime = parseFloat(annot.start_time);
      let endTime = parseFloat(annot.end_time);
      let annotWidth = convertTimeToVw(endTime - startTime);
      let annotLeft = convertTimeToPixel(startTime);
      annotdata.setAttribute(
        "style",
        `display: inline; transition: all 0.3s;color:white; cursor: pointer; font-size: 0.8em;  position: absolute; width: ${annotWidth}vw; left: ${annotLeft}px; text-align: center;`,
      );
      annotdata.classList.add("overflow-annot");
      // annotdata.classList.add(`speaker-0`);

      annotdata.addEventListener("click", function (e) {
        resetAnnotation();
        const regionaudio = wavesurferObj.regions.list;
        const regionSFX = wavesurferObjSFX.regions.list;
        for (const regionKey in regionaudio) {
          regionaudio[regionKey].remove();
        }
        for (const regionKey in regionSFX) {
          regionSFX[regionKey].remove();
        }
        e.preventDefault();
        dragDropWord(annotation);
        annotdata.classList.add("clicked-annot");
        annotdata.style.transform = "scale(1.1)";
        wavesurferObjSFX.addRegion({
          start: parseFloat(annotation.start), // start time of the region
          end: parseFloat(annotation.end), // end time of the region
          color: "hsla(265, 100%, 86%, 0.4)", // color of the region
        });
      });
      annotdata.innerText = annot.content.replace(/\s+/g, "");
      store.dispatch(
        annotationListActions.updateHighlightSFXAnnotationList(annot),
      );
      sfxtranscriptionsDiv.appendChild(annotdata);
    });
  });
  // console.log(resultStat);

  resultStat.speakers_segments.map((segment, ind) => {
    segment.annotations.forEach((annot) => {
      const annotation = { ...annot };
      //wavesurferObj.addRegion(annotation)
      const annotdata = document.createElement("div");
      annotdata.id = `${annot.id}_div`;
      let startTime = parseFloat(annot.start_time);
      let endTime = parseFloat(annot.end_time);
      let annotWidth = convertTimeToVw(endTime - startTime);
      let annotLeft = convertTimeToPixel(startTime);
      annotdata.setAttribute(
        "style",
        `display: inline; transition: all 0.3s; cursor: pointer; font-size: 0.8em;  position: absolute; width: ${annotWidth}vw; left: ${annotLeft}px; text-align: center;`,
      );

      if (annot.speaker_label === "spk_0") {
        annotdata.classList.add("active-annot");
      } else {
        annotdata.classList.add("inactive-annot");
      }

      annotdata.classList.add(`speaker-${ind}`);

      if (annot.is_silenced === true) {
        annotdata.classList.remove(
          "active-annot",
          "inactive-annot",
          "clicked-annot",
        );
        annotdata.style.color = "#818181";
      }

      let nextAnnotIndex = segment.annotations.indexOf(annot) + 1;

      if (nextAnnotIndex > segment.annotations.length - 1)
        nextAnnotIndex = segment.annotations.length - 1;

      const nextAnnot = segment.annotations[nextAnnotIndex];
      const nextStartTime = parseFloat(nextAnnot.start_time);
      const timeDifference = (nextStartTime - endTime) * 100;

      if (annot.content.length <= 2 && timeDifference > 0.5)
        annotdata.classList.add("non-overflow-annot");
      else annotdata.classList.add("overflow-annot");

      annotdata.onclick = function (e) {
        e.preventDefault();

        const regionaudio = wavesurferObj.regions.list;
        const regionSFX = wavesurferObjSFX.regions.list;
        for (const regionKey in regionaudio) {
          regionaudio[regionKey].remove();
        }
        for (const regionKey in regionSFX) {
          regionSFX[regionKey].remove();
        }

        wordMultiSelect(annotation);
        // annotdata.classList.add("clicked-annot");
        annotdata.style.transform = "scale(1.1)";
        wavesurferObj.addRegion({
          start: parseFloat(parseFloat(annotation.start_time)), // start time of the region
          end: parseFloat(parseFloat(annotation.end_time)), // end time of the region
          color: "hsla(265, 100%, 86%, 0.4)", // color of the region
        });

        const transcriptionWrapperPara = document.getElementById(
          "transcription-wrapperpara",
        );
        if (!transcriptionWrapperPara) return; // Does not exist, return

        const transcriptPanelAnnotations = document.querySelectorAll(
          "#transcription-wrapperpara > div > p > span",
        );
        if (!transcriptPanelAnnotations) return; // No annotations, return

        for (const annotation of transcriptPanelAnnotations) {
          if (annotation.innerText === annot.content) {
            annotation.parentElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        }
      };
      annotdata.addEventListener("dblclick", function (e) {
        e.stopPropagation();
        e.preventDefault();
        if (store.getState().viewEditMode.mode != "view")
          openTextEditor(annotdata, annot, "updateAnnot");
        else showStatText("operation not permited in view mode");
      });
      annotdata.addEventListener("contextmenu", function (e) {
        const regionaudio = wavesurferObj.regions.list;
        const regionSFX = wavesurferObjSFX.regions.list;
        for (const regionKey in regionaudio) {
          regionaudio[regionKey].remove();
        }
        for (const regionKey in regionSFX) {
          regionSFX[regionKey].remove();
        }
        e.preventDefault(); // Prevent the default context menu
        annotdata.classList.add("right-clickedannot");
        store.dispatch(
          sfxModalSliceActions.updateSfxModal({
            showModal: true,
            clickedAnnot: annot.content,
          }),
        );
        wavesurferObj.addRegion({
          start: parseFloat(annotation.start_time), // start time of the region
          end: parseFloat(annotation.end_time), // end time of the region
          color: "hsla(265, 100%, 86%, 0.4)", // color of the region
        });
      });

      annotdata.innerText = annot.content;
      transcriptionsDiv.appendChild(annotdata);
    });
  });
}

function openTextEditor(annotationDiv, annot, mode) {
  const textInput = document.createElement("input");
  textInput.type = "text";
  textInput.value = annot.content;
  textInput.style.border = "none";
  textInput.style.backgroundColor = "transparent";
  textInput.style.outline = "none"; // Added to remove the focus outline
  textInput.style.fontSize = window.getComputedStyle(annotationDiv).fontSize;
  textInput.style.fontFamily =
    window.getComputedStyle(annotationDiv).fontFamily;
  textInput.style.color = window.getComputedStyle(annotationDiv).color;
  textInput.style.width = `${annotationDiv.offsetWidth}px`;
  textInput.style.height = `${annotationDiv.offsetHeight}px`;
  textInput.style.textAlign = window.getComputedStyle(annotationDiv).textAlign;
  textInput.style.padding = "0"; // Added to remove any padding
  textInput.className = "EditAnnot";
  annotationDiv.innerHTML = "";
  annotationDiv.appendChild(textInput);
  textInput.focus();
  textInput.addEventListener("blur", function () {
    const newText = textInput.value;
    annotationDiv.innerText = newText;
    textInput.classList.remove("EditAnnot");

    if (newText != annot.content)
      updateResultstatforeditannotation(annot, newText, mode);
  });

  textInput.addEventListener("keydown", function (event) {
    if (event.key === "Enter") {
      textInput.blur();
    }
  });
}
async function updateResultstatforeditannotation(annotData, newText, mode) {
  let AnnotData = { ...annotData };
  let oldres = store.getState().projectData.resultStat;
  let resultStat = JSON.parse(JSON.stringify(oldres));
  resultStat.fades = oldres.fades ? oldres.fades : [];
  resultStat.speakers_src = oldres.speakers_src;
  resultStat.sfx_src = oldres.sfx_src;
  let trackid, params, customClass;
  resultStat.speakers_segments.forEach((track) => {
    track.annotations.forEach((annot) => {
      if (annot.id == AnnotData.id) {
        annot.content = newText;
        AnnotData.content = newText;
        trackid = track.id;
        customClass = track.speaker_label;
      }
    });
  });
  const operationId = create_UUID();
  if (mode == "updateAnnot") {
    let annotation = {
      confidence: annotData.confidence,
      content: newText,
      duration: (annotData.end_time - annotData.start_time).toFixed(2),
      end_time: annotData.end_time,
      id: annotData.id,
      is_silenced: false,
      previousDuration: (annotData.end_time - annotData.start_time).toFixed(2),
      previousEndTime: annotData.end_time,
      previousStartTime: annotData.start_time,
      speaker_label: annotData.speaker_label,
      start_time: annotData.start_time,
    };
    params = {
      jobname: resultStat.jobname,
      refid: operationId,
      parentRefid: resultStat.refid,
      worker: "updateTranscript",
      inputs: {
        trackid,
        annotation,
      },
    };
  } else {
    params = {
      jobname: resultStat.jobname,
      refid: operationId,
      parentRefid: resultStat.refid,
      worker: "addAnnotation",
      inputs: {
        newAnnotation: AnnotData,
        trackIndex: trackid,
        newAnnotIndex: 1,
        selectedCustomClasses: customClass,
        customClass,
      },
    };
  }
  resultStat.refid = operationId;
  await restThings(params, resultStat)
}
export function sendLog(data) {
  try {
    if (Koolioai.isLive) {
      Koolioai.wss.send(
        JSON.stringify({
          action: "user_activity",
          data,
        }),
      );
    }
  } catch (error) {
    //console.log(error)
  }
}
function getShareUserData(listOfUser) {
  listofSharedUser = [];
  let users = listOfUser.user;
  for (let i = 0; i < users.length; i++) {
    listofSharedUser.push(users[i]);
  }
}
function customOperationTitle(operartionTitle) {
  switch (operartionTitle) {
    case "cutDeleteCustomClass":
      return "cut";
    default:
      return operartionTitle;
  }
}

export function enableHistoryOptions(check) {
  if (check) {
    document.getElementById("control-opt-preview").innerText = "Accept";
    document.getElementById("control-opt-edit").innerText = "Reject";
    document.getElementById("undoBtn").style.display = "none";
    document.getElementById("redoBtn").style.display = "none";
    document.getElementById("control-opt-preview").style.color = "#8A2BE2";
    document.getElementById("control-opt-edit").style.color = "#E2522B";
  } else {
    document.getElementById("control-opt-preview").innerText = "View";
    document.getElementById("control-opt-edit").innerText = "Edit";
    document.getElementById("undoBtn").style.display = "block";
    document.getElementById("redoBtn").style.display = "block";
    document.getElementById("control-opt-preview").style.color = "#8A2BE2";
    document.getElementById("control-opt-edit").style.color = "#494949";
    hideplaylistloader();
  }
}

//restore history code
export async function restoreHistory(refid, isPassiveOP = false) {
  showplaylistloader();

  let currentResultStat = store.getState().projectData.resultStat;
  store.dispatch(
    restoreDataSliceActions.storeFromRefId(currentResultStat.refid),
  );
  store.dispatch(restoreDataSliceActions.storeToRefId(refid));
  let jobhead = currentResultStat.refid;
  let currentFlags = currentResultStat.flags || [];
  try {
    let res = await restoreProjectHistory(jobhead, refid, isPassiveOP);

    if (res.resultStat.speakers_src.key) {
      res.resultStat.speakers_src = await Koolioai.getSignedObject(
        res.resultStat.speakers_src.key,
      );
    }
    if (res.resultStat.sfx_src.key)
      res.resultStat.sfx_src = await Koolioai.getSignedObject(
        res.resultStat.sfx_src.key,
      );
    let promises = [
      getArrayBufferFromSrcURL(res.resultStat.speakers_src),
      getArrayBufferFromSrcURL(res.resultStat.sfx_src),
    ];
    Promise.all(promises).then((audioBuffersArray) => {
      res.resultStat.speakers_src = audioBuffersArray[0];
      res.resultStat.sfx_src = audioBuffersArray[1];
      let newResultStat = JSON.parse(JSON.stringify(currentResultStat));
      newResultStat.speakers_src = currentResultStat.speakers_src;
      newResultStat.sfx_src = currentResultStat.sfx_src;
      store.dispatch(
        restoreDataSliceActions.restoreReducerData({
          ...res,
          currentResultStat: newResultStat,
        }),
      );
      store.dispatch(
        projectDataSliceActions.projectReducerData({
          resultStat: res.resultStat,
        }),
      );
      let wavesurferObj = store.getState().wavesurferObj.wavsurferObj;
      let wavesurferObjSFX = store.getState().wavesurferObjSFX.wavesurferObjSFX;
      let wavesurferObjGlobal =
        store.getState().wavesurferObjGlobal.wavsurferObjGlobal;
      let audioBlob = new Blob([audioBuffersArray[0]]);
      let blobURL = URL.createObjectURL(audioBlob);
      wavesurferObj.load(blobURL);
      wavesurferObjGlobal.load(blobURL);
      audioBlob = new Blob([audioBuffersArray[1]]);
      blobURL = URL.createObjectURL(audioBlob);
      wavesurferObjSFX.load(blobURL);
      wavesurferObjGlobal.setVolume(0);
      Promise.all([
        new Promise((resolve) => wavesurferObj.on("ready", resolve)),
        new Promise((resolve) => wavesurferObjSFX.on("ready", resolve)),
      ]).then(async () => {
        loadSlickSliders(res.resultStat);
        let pixPerSecond = wavesurferObj.params.minPxPerSec;
        let newWidth = Math.round(wavesurferObj.getDuration() * pixPerSecond);
        if (document.getElementById("transcriptions_div")) {
          document.getElementById("transcriptions_div").style.width = newWidth;
        }
        if (document.getElementById("transcriptions_div_sfx")) {
          document.getElementById("transcriptions_div_sfx").style.width =
            newWidth;
        }
        store.dispatch(
          wavesurferObjActions.updateWavesurferObj({
            wavesurferObj: wavesurferObj,
          }),
        );

        store.dispatch(
          wavesurferObjSFXActions.updateWavesurferObjSFX({
            wavesurferObjSFX: wavesurferObjSFX,
          }),
        );

        let props = {
          wavesurferObj,
          wavesurferObjSFX,
          wavesurferObjGlobal,
        };

        document.getElementById("transcriptions_div").innerHTML = "";
        document.getElementById("transcriptions_div_sfx").innerHTML = "";
        //load the annotations till restore Point
        store.dispatch(propsActions.updateProps({ props: props }));
        let resultStat = store.getState().projectData.resultStat;
        let propsDup = store.getState().propsSlice.props;
        await annotUIshiftSub(resultStat, propsDup);
        // console.log(res);
        applyOperations(res.operations, res.flags.body, [], false, true).then(
          async () => {
            if (res.flags.body) {
              let currflagsList = store.getState().flag.flagsList;
              await restoreFlags(
                JSON.parse(res.flags.body).flags,
                currflagsList,
                res.resultStat,
                wavesurferObj,
              );
            }
          },
        ).then(() => {
          hideplaylistloader();
        });
      });
    });
    store.dispatch(viewEditSliceActions.updateViewEditMode({ mode: "view" }));
  } catch (error) {
    hideplaylistloader();
    return Promise.reject(error);
  }
  //.then(res => {
  //if (res.status === 200) {
  //    return playlist.clear()
  //        .then(() => {
  //            return loadSegments(res.resultStat)
  //                .then(() => applyOperations(res.operations,res.flags.body,currentFlags, false, true))
  //                .then(async () => {
  //                    // await loadFlagsInPlaylist(playlist.flags, res.resultStat.page_loadable)

  //                    /* #2808 fix we will move into the restoreFlags() only if we are trying to restore to a particular point */
  //                    if (res.flags.body){
  //                        restoreFlags(res.flags.body, currentFlags)
  //                    }
  //                    // #2808

  //                    destroySliders()
  //                    document.getElementById('track-controls').style.display = 'block'
  //                    loadSlickSliders(playlist.getCustomClasses()).then(() => loadSliders(playlist.numberOfSpeakers))
  //                    playlist.setJobHead(res.jobhead)
  //                    playlist.setJobCode(res.resultStat.job_code)
  //                    playlist.setMode(document.getElementById('edit-control-options').classList.contains('enabled-ctrl') ? 'edit' : 'view')
  //                    if(isTranscriptDrivenEdit){
  //                        switch1()
  //                    }
  //                    return Promise.resolve(hideplaylistloader())
  //                })
  //        })
  //} else if (res.status === 201) {
  //    return updateWorkspaceWithOrigin()
  //        .then(() => {
  //            document.getElementById('track-controls').style.display = 'block'
  //            showStatText('restore aborted, origin ahead of local', false)
  //            hideplaylistloader()
  //        })
  //} else if (res.status === 404) {
  //    document.getElementById('track-controls').style.display = 'block'
  //    showStatText('conflict')
  //    hideplaylistloader()
  //}
}

export async function fetchHistoryData(offset, target = 0, props) {
  //document.getElementById("history-main").style.display = 'none'
  let data = await getHistory(offset);
  let mode = store.getState().viewEditMode.mode;
  let jobname = store.getState().projectData.resultStat.jobname;
  for (let i = 0; i < data.operations.length; i++) {
    let historyData = document.createElement("div");
    historyData.classList.add(
      "history-version",
      "col-12",
      "d-none",
      "text-center",
      "align-items-center",
    );

    let creators = document.createElement("div");
    creators.classList.add("history-data", "col-3");
    let creatorsData = document.createElement("img");
    creatorsData.classList.add("user-profile-border");
    creatorsData.setAttribute("id", "history-user-profile");

    // Used '/get-email' api to fetch user email who did
    // operations on workspace (prominently used if have collaboraters)
    // providing username as parameter and getting email & username as response
    let user_email;
    const url = _config.api + "/get-email";
    const params = {
      username: data.operations[i].created_by,
    };
    try {
      const response = await axios.get(url, {
        params,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        user_email = response.data.email;
      }
    } catch (error) {
      return;
    }
    creatorsData.src =
      "https://ui-avatars.com/api/?name=" +
      user_email +
      "&background=181818&color=fff&rounded=true&size=28";
    creators.appendChild(creatorsData);

    let timestamp = document.createElement("div");
    timestamp.classList.add("history-data", "col-3");
    let timeData = document.createTextNode(
      new Date(data.operations[i].created_at).toLocaleString(),
    );
    timestamp.appendChild(timeData);

    let operations = document.createElement("div");
    operations.classList.add("history-data", "col-3");
    let opsData = document.createTextNode(
      customOperationTitle(data.operations[i].operation.worker),
    );
    operations.appendChild(opsData);

    let action = document.createElement("div");

    let actionData = document.createElement("img");
    actionData.classList.add("view-history-icon");
    actionData.setAttribute("id", "view-history");
    actionData.src = "/image/view-history.png";
    // actionData.setAttribute('style', 'height: 15%; width: 15%')
    action.appendChild(actionData);

    if (i === 0 || mode === "view") {
      action.classList.add("history-data", "col-3");
      actionData.classList.add("restore-disabled");
      if (i === 0 && offset === 0) {
        actionData.title = "Current Version";
      } else {
        actionData.title = "Only available in Edit Mode";
      }
      actionData.setAttribute("data-bs-placement", "bottom");
    } else {
      action.classList.add("history-data", "col-3");
      actionData.classList.add("restore-enabled");
      actionData.title = "Restore to this version";
    }
    actionData.onclick = async () => {
      const permResponse = ACM.checkPermission("restore");
      if (!permResponse.success) {
        return showMessage(
          permResponse.message,
          "History restore is not available in the free plan",
          2000,
          "error",
          "more-options",
        );
      }
      if (!(await checkFileConversionStatus(jobname))) {
        return showMessage(
          "History restore is not available currently",
          "File conversion in progress. Please, try after some time",
          2000,
          "error",
          "more-options",
        );
      }

      if (actionData.classList.contains("restore-enabled")) {
        document.getElementById("control-mode").style.visibility = "hidden";
        document.getElementById("track-controls").style.display = "none";
        document.getElementById("undoBtn").style.display = "none";
        document.getElementById("redoBtn").style.display = "none";

        restoreHistory(data.operations[i].refid, true).then(() => {
          //document.getElementById('koolio_workspace').style.visibility = 'visible'
          document.getElementById("control-mode").style.visibility = "visible";
          document.getElementById("track-controls").style.display = "block";
          document.getElementById("edit-control-options-left").classList.add =
            "disabled-ctrl ";
          document.getElementById("edit-control-options-right").classList.add =
            "disabled-ctrl ";

          props.onClose();
          enableHistoryOptions(true);
        });
      }
    };

    historyData.appendChild(creators);
    historyData.appendChild(timestamp);
    historyData.appendChild(operations);
    historyData.appendChild(action);
    if (document.getElementById("history-main")) {
      document.getElementById("history-main").appendChild(historyData);
    } else {
      return;
    }
  }

  const pages = Math.ceil(data.total_number_of_records / 20);

  if (pages > 1) {
    document.getElementById("history-page-arrow-left").style.visibility =
      "visible";
    document.getElementById("history-page-arrow-right").style.visibility =
      "visible";
    if (target === 0) {
      document
        .getElementById("history-page-arrow-left")
        .setAttribute("style", "opacity: 0.2; cursor: not-allowed");
      document
        .getElementById("history-page-arrow-right")
        .setAttribute("style", "opacity: 1; cursor: pointer");
    } else if (target === pages - 1) {
      document
        .getElementById("history-page-arrow-left")
        .setAttribute("style", "opacity: 1; cursor: pointer");
      document
        .getElementById("history-page-arrow-right")
        .setAttribute("style", "opacity: 0.2; cursor: not-allowed");
    } else {
      document
        .getElementById("history-page-arrow-left")
        .setAttribute("style", "opacity: 1; cursor: pointer");
      document
        .getElementById("history-page-arrow-right")
        .setAttribute("style", "opacity: 1; cursor: pointer");
    }
  }

  if (pages > 0) {
    for (let i = 0; i < pages; i++) {
      const li = document.createElement("li");
      li.classList.add("page-item");
      if (target === i) {
        li.innerHTML = `<span class="page-link btn-active">${i == 0 ? i + 1 : i * 20 + 1}-${(i + 1) * 20}</span></li>`;
      } else {
        li.innerHTML = `<span class="page-link">${i == 0 ? i + 1 : i * 20 + 1}-${(i + 1) * 20}</span></li>`;
      }
      li.onclick = function (e) {
        const permResponse = ACM.checkPermission("restore");
        if (!permResponse.success)
          return showMessage(
            permResponse.message,
            "History restore is not available in the free plan",
            2000,
            "error",
            "more-options",
          );

        historyOptions(i * 20, i);
      };
      document.getElementById("historyPageContainer").appendChild(li);
    }
  }

  if (target < 5 && pages > 11) {
    document
      .getElementById("historyPageContainer")
      .childNodes.forEach((c, i) => {
        if (i > 10) {
          return c.classList.add("d-none");
        }
      });
    const liDot = document.createElement("li");
    liDot.classList.add("page-item");
    liDot.innerHTML = `<span class="page-link">...</span></li>`;

    const liLast = document.createElement("li");
    liLast.classList.add("page-item");
    liLast.innerHTML = `<span class="page-link">${(pages - 1) * 20 + 1}-${pages * 20}</span></li>`;
    liLast.onclick = function (e) {
      const permResponse = ACM.checkPermission("restore");
      if (!permResponse.success)
        return showMessage(
          permResponse.message,
          "History restore is not available in the free plan",
          2000,
          "error",
          "more-options",
        );
      historyOptions((pages - 1) * 20, pages - 1);
    };
    document.getElementById("historyPageContainer").appendChild(liDot);
    document.getElementById("historyPageContainer").appendChild(liLast);
  } else if (target && target >= 5) {
    const leftPages = pages - (target + 1);
    if (leftPages >= 5) {
      for (let i = target; i < pages; i++) {
        document
          .getElementById("historyPageContainer")
          .childNodes[i].classList.remove("d-none");
        if (i <= target + 5) {
          document
            .getElementById("historyPageContainer")
            .childNodes[i].classList.add("d-inline-block");
        } else {
          document
            .getElementById("historyPageContainer")
            .childNodes[i].classList.add("d-none");
        }
      }
      for (let i = target; i >= 0; i--) {
        document
          .getElementById("historyPageContainer")
          .childNodes[i].classList.remove("d-none");
        if (i >= target - 5) {
          document
            .getElementById("historyPageContainer")
            .childNodes[i].classList.add("d-inline-block");
        } else {
          document
            .getElementById("historyPageContainer")
            .childNodes[i].classList.add("d-none");
        }
      }
      if (leftPages > 5) {
        const liDot = document.createElement("li");
        liDot.classList.add("page-item");
        liDot.innerHTML = `<span class="page-link">...</span></li>`;

        const liLast = document.createElement("li");
        liLast.classList.add("page-item");
        liLast.innerHTML = `<span class="page-link">${(pages - 1) * 20 + 1}-${pages * 20}</span></li>`;
        liLast.onclick = function (e) {
          const permResponse = ACM.checkPermission("restore");
          if (!permResponse.success)
            return showMessage(
              permResponse.message,
              "History restore is not available in the free plan",
              2000,
              "error",
              "more-options",
            );
          historyOptions((pages - 1) * 20, pages - 1);
        };
        document.getElementById("historyPageContainer").appendChild(liDot);
        document.getElementById("historyPageContainer").appendChild(liLast);
      }
    } else {
      for (let i = target; i < pages; i++) {
        document
          .getElementById("historyPageContainer")
          .childNodes[i].classList.remove("d-none");

        document
          .getElementById("historyPageContainer")
          .childNodes[i].classList.add("d-inline-block");
      }
      for (let i = target; i >= 0; i--) {
        document
          .getElementById("historyPageContainer")
          .childNodes[i].classList.remove("d-none");
        if (i > target - (11 - leftPages)) {
          document
            .getElementById("historyPageContainer")
            .childNodes[i].classList.add("d-inline-block");
        } else {
          document
            .getElementById("historyPageContainer")
            .childNodes[i].classList.add("d-none");
        }
      }
    }
  }

  //koolioRes()
}

export async function historyOptions(offset = 0, target = 0, props) {
  //if (!(transcriptionCompleted)) {
  //    moreOptionsAlert('transcription in progress, operation disabled')
  //    return
  //}
  const historyMain = document.getElementById("history-main");
  const historyPageContainer = document.getElementById("historyPageContainer");
  if (historyMain.childNodes.length) {
    while (historyMain.firstChild) {
      historyMain.removeChild(historyMain.lastChild);
    }
  }
  if (historyPageContainer.childNodes.length) {
    while (historyPageContainer.firstChild) {
      historyPageContainer.removeChild(historyPageContainer.lastChild);
    }
  }
  const main = document.getElementById("history-main");
  const loadingText = document.createElement("div");
  loadingText.id = "history-loading";
  loadingText.innerText = "Loading history...";
  main.appendChild(loadingText);
  if (document.getElementById("history-page-arrow-left"))
    document.getElementById("history-page-arrow-left").style.visibility =
      "hidden";
  if (document.getElementById("history-page-arrow-right"))
    document.getElementById("history-page-arrow-right").style.visibility =
      "hidden";
  //sendLog({
  //    activity: 'click',
  //    target: 'open history tab',
  //    timeStamp: new Date().toISOString(),
  //    jobname,
  //    status: 'success',
  //    statusMsg: 'success'
  //})
  //topbar.show();
  //(function step() {
  //    setTimeout(function () {
  //        if (topbar.progress('+.01') < 1) step()
  //    }, 30)
  //})()
  await fetchHistoryData(offset, target, props).then(() => {
    loadingText.innerText = "";
    if (document.getElementById("history-main")) {
      document.getElementById("history-main").style.removeProperty("display");
    } else {
      return;
    }
    if (document.querySelectorAll(".history-version")) {
      document.querySelectorAll(".history-version").forEach((ele) => {
        ele.classList.remove("d-none");
        ele.classList.add("d-flex");
      });
    } else {
      return;
    }

    //topbar.hide()
  });
  //document.getElementById('export').style.display = 'none'
  //document.getElementById('share').style.display = 'none'
  //document.getElementById('delete').style.display = 'none'
  //document.getElementById('history').style.display = 'block'
  document.getElementById("delete-icon").style.opacity = "0.5";
  document.getElementById("export-icon").style.opacity = "0.5";
  document.getElementById("share-icon").style.opacity = "0.5";
  document.getElementById("history-icon").style.cssText =
    "opacity:1 !important";
  return;
}

export async function checkAccess() {
  let kooliospace = await getKooliospace();
  const jobname = store.getState().projectData.resultStat.jobname;
  for (let i = 0; i < kooliospace.length; i++) {
    if (jobname === kooliospace[i].jobname) {
      if (kooliospace[i].modes.includes("edit")) {
        return true;
      }
      break;
    }
  }
  return false;
}

export async function initiateOperationCountHandlerScheduler(jobname) {
  if (!store.getState().operationCountData.operationCountHandler) {
    let state = store.getState();
    let operationCountHandler = state.operationCountData.operationCountHandler;
    // let jobname = state.projectData.resultStat.jobname;
    operationCountHandler = setInterval(() => {
      if (jobname && Koolioai.username) getOperationCount(jobname);
    }, 10000);
  }
}

export function clearOperationCountHandlerScheduler() {
  let state = store.getState();
  if (!state.operationCountData.operationCountHandler)
    clearInterval(state.operationCountData.operationCountHandler);
  store.dispatch(
    OperationsCountHandlerSliceActions.updateOperationsHandler({
      operationCountHandler: undefined,
    }),
  );
}

async function initiateApplyOperationFromWebsocket() {
  while (websocketOperations.length > 0) {
    let resultStat = store.getState().projectData.resultStat;
    let jobname = store.getState().projectData.resultStat.jobname;
    let wsoperation = websocketOperations.pop();
    // if (wsoperation.operation.inputs.tracks.length === 0) return;
    if (wsoperation.jobname !== jobname) return;
    if (wsoperation.refid === resultStat.refid) return;
    let operationList = store.getState().operationListData.operationList;
    const newOperationList = [...operationList];
    if (newOperationList.includes(wsoperation.refid)) return;
    if (wsoperation.operation.inputs.operationPerformedBy == Koolioai.username)
      return;
    //new arch add operation to indexedDB
    let operationData = {
      inputs: wsoperation.operation.inputs,
      jobname: wsoperation.jobname,
      parentRefid: wsoperation.parentRefid || wsoperation.parent_refid,
      refid: wsoperation.refid,
      worker: wsoperation.operation.worker,
    };
    let containsFile = false;
    let trackInfo;
    if (wsoperation.operation.inputs.tracks?.length) {
      trackInfo = {
        customClass: wsoperation.operation.inputs.customClass,
        isImport: wsoperation.operation.inputs.isImport,
      };
      containsFile = true;
    }

    await addOperationsToDB(
      operationData,
      undefined,
      containsFile,
      "true",
      "pending",
      "false",
    ).then(async (res) => {
      let operationList = store.getState().operationListData.operationList;
      let jobname = store.getState().projectData.resultStat.jobname;
      const newOperationList = [...operationList];

      if (newOperationList.includes(wsoperation.parentRefid))
        return updateWorkspaceWithOrigin(jobname, resultStat.refid, true);
      if (resultStat.refid !== wsoperation.parentRefid)
        return updateWorkspaceWithOrigin(jobname, resultStat.refid, true);
      if (
        resultStat.refid === wsoperation.parentRefid &&
        !["undo", "redo"].includes(wsoperation.operation.worker)
      )
        return updateWorkspaceWithOrigin(jobname, resultStat.refid, true);
      if (["undo", "redo"].includes(wsoperation.operation.worker)) {
        // document.getElementById("status-disp").style.display = "flex";
        // $("#koolio_workspace").css("visibility", "hidden");
        // $("#control-mode").css("visibility", "hidden");
        //     createVanta()
        // $("#status-disp  span.status").text(message);
        showStatText("applying operations", false);

        await restoreHistory(wsoperation.refid, true)
          .then(() => {
            // playlist.clearUndoStack()
            data.undoStack = [];
            data.redoStack = [];
            // playlist.clearRedoStack()
          })
          .then(() => {
            // document.getElementById("status-bar").style.display = "none";
            // document.getElementById("status-disp").style.display = "none";
            // document.getElementById("cont").style.display = "block";
            // let message = "";
            // $("#status-disp  span.status").text(message);
            // $("#koolio_workspace").css("visibility", "visible");
            // $("#control-mode").css("visibility", "visible");
            // destroySliders()
            // playlist.draw(playlist.render())
            // loadSlickSliders(playlist.getCustomClasses()).then(() => loadSliders(playlist.numberOfSpeakers))
            // drawGlobalView()
            hideplaylistloader();
            // destroyProgressBar()
            // destroyVanta()
          });
      } else {
        // document.getElementById("status-disp").style.display = "flex";
        // $("#koolio_workspace").css("visibility", "hidden");
        // $("#control-mode").css("visibility", "hidden");
        // createVanta()
        // constantProgress(10, 'Applying Operations')
        // document.getElementById('progress-detail').innerHTML = 'Please wait...'
        showStatText("applying operations", false);

        applyOperations([wsoperation])
          .then(() => {
            // playlist.clearUndoStack()
            // playlist.clearRedoStack()
          })
          .then(() => {
            // document.getElementById("status-bar").style.display = "none";
            // document.getElementById("status-disp").style.display = "none";
            // document.getElementById("cont").style.display = "block";
            // let message = "";
            // $("#status-disp  span.status").text(message);
            // destroyProgressBar()
            // destroyVanta()
            $("#koolio_workspace").css("visibility", "visible");
            $("#control-mode").css("visibility", "visible");
            // destroySliders()
            // playlist.draw(playlist.render())
            // loadSlickSliders(playlist.getCustomClasses()).then(() => loadSliders(playlist.numberOfSpeakers))
            // drawGlobalView()
            if (store.getState().viewEditMode.mode !== "edit")
              updateOperationSpace();
            hideplaylistloader();
          });
      }
    });
    hideplaylistloader();
    //new arch add operation to indexedDB
  }
}

export function handleHorizontalScroll(value, type = null) {
  let container = document.getElementById("playlist-wrapper");
  let containerW = container.offsetWidth / 2;
  if (type === "scroll") containerW = 0;
  const scrollAmount = value;
  container.scrollTo({
    top: 0,
    left: scrollAmount - containerW,
    // behavior: "smooth",
  });
}
export function handleHorizontalScrollForRecordspace(value, type = null) {
  let container = document
    .querySelector(".waveform-recording")
    .querySelector("wave");
  let containerW = container.offsetWidth / 2;
  if (type === "scroll") containerW = 0;
  const scrollAmount = value;
  container.scrollTo({
    top: 0,
    left: scrollAmount - containerW,
    // behavior: "smooth",
  });
}

export function formatTime(time) {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  const milliseconds = Math.floor((time * 1000) % 1000);

  const padZero = (num, length = 2) => String(num).padStart(length, "0");

  return `${padZero(minutes)}:${padZero(seconds)}:${padZero(milliseconds, 3)}`;
}

export function updateCurrentTime(clickedTime, waveSurfObj) {
  const wavesurfeObj =
    waveSurfObj || store.getState().wavesurferObj.wavsurferObj;

  if (!wavesurfeObj) return;

  const time = clickedTime || wavesurfeObj.getCurrentTime();

  store.dispatch(utilsSliceActions.updateCurrentTime({ currentTime: time }));

  const globalViewTime = document.querySelector(
    "#global-playlist-time .vertical-center-left",
  );
  if (globalViewTime) globalViewTime.innerHTML = formatTime(time);
  let outerWaveElement = document.querySelector("#waveform>wave");
  let cursorTimeDiv = outerWaveElement.querySelector(".cursorTimeStamp");
  let computedWidth = wavesurfeObj.params.minPxPerSec * time + 8;
  if (cursorTimeDiv) cursorTimeDiv.remove();
  cursorTimeDiv = document.createElement("div");
  cursorTimeDiv.className = "cursorTimeStamp";
  cursorTimeDiv.style.position = "absolute";
  cursorTimeDiv.style.left = `${computedWidth}px`;
  cursorTimeDiv.style.fontSize = "10px";
  cursorTimeDiv.style.color = "#999999";
  cursorTimeDiv.style.zIndex = "5";
  outerWaveElement.appendChild(cursorTimeDiv);
  cursorTimeDiv.innerHTML = formatTime(time);
}
//Binary search
export function findClosestAnnotationIndex(targetTime, array) {
  let left = 0;
  let right = array.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const midTime = array[mid].start;

    if (midTime === targetTime) {
      return mid;
    } else if (midTime < targetTime) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }
  if (left >= array.length) {
    return right;
  }
  if (right < 0) {
    return left;
  }
  const leftDiff = Math.abs(array[left].start - targetTime);
  const rightDiff = Math.abs(array[right].start - targetTime);
  return leftDiff < rightDiff ? left : right;
}

export function updateDuration() {
  const wavesurfeObj = store.getState().wavesurferObj.wavsurferObj;

  if (!wavesurfeObj) return;

  const duration = wavesurfeObj.getDuration();

  const globalViewDuration = document.querySelector(
    "#global-playlist-duration .vertical-center-right",
  );
  if (globalViewDuration) globalViewDuration.innerHTML = formatTime(duration);
}

export let currenTimeScheduler;

export function initiateGetCurrentTimeScheduler() {
  if (currenTimeScheduler) clearCurrentTimeScheduler();

  let wavesurfeObj = store.getState().wavesurferObj.wavsurferObj;

  wavesurfeObj.un("finish"); // Clear any previous event listeners

  wavesurfeObj.on("finish", function () {
    clearCurrentTimeScheduler();
  });

  currenTimeScheduler = setInterval(() => {
    wavesurfeObj = store.getState().wavesurferObj.wavsurferObj;

    updateCurrentTime();
  }, 50);
}

export function clearCurrentTimeScheduler() {
  if (currenTimeScheduler) clearInterval(currenTimeScheduler);
}
// document.addEventListener("visibilitychange", function () {
//   if (document.visibilityState === "visible") {
//     const props = store.getState().propsSlice.props;
//     const { wavesurferObj, wavesurferObjSFX, wavesurferObjGlobal } = {
//       ...props,
//     };

//     if (!wavesurferObj || !wavesurferObjSFX || !wavesurferObjGlobal) return;

//     wavesurferObj.drawBuffer();
//     wavesurferObjSFX.drawBuffer();
//     wavesurferObjGlobal.drawBuffer();
//   }
// });
export function renderFade(resultsStat) {
  let wavesurfeObj = store.getState().wavesurferObj.wavsurferObj;

  const sfxAreaContainer = document.querySelector("#sfxarea");
  const waveformContainer = document.querySelector("#waveform");

  if (!sfxAreaContainer || !waveformContainer) {
    return;
  }

  // Then only remove these canvas elements
  [sfxAreaContainer, waveformContainer].forEach((container) => {
    Array.from(container.getElementsByClassName("fade-canvas")).forEach(
      (canvas) => {
        canvas.parentNode.removeChild(canvas);
      },
    );
  });
  resultsStat.fades?.forEach((fade) => {
    document.getElementById("fade_btn_" + fade.id)?.remove();
    let container;
    const canvas = document.createElement("canvas");
    canvas.classList.add("fade-canvas");
    let topoffset = 0;
    if (fade.trackType === "sfx_segments") {
      container = sfxAreaContainer;
      topoffset = 20;
    } else container = waveformContainer;
    let startTime = fade.start_time;
    let endTime = fade.end_time;
    const ctx = canvas.getContext("2d");
    let duration = endTime - startTime;
    const minPxPerSec = wavesurfeObj.params.minPxPerSec;
    const totalWidth = duration * minPxPerSec;
    const containerRect = container.getBoundingClientRect();
    const leftOffset = startTime * minPxPerSec;
    canvas.style.cssText = `position: absolute; top: ${topoffset}vh; left: ${leftOffset}px; pointer-events: none; transition: 0.5s;`;
    canvas.width = totalWidth;
    canvas.height = containerRect.height;
    canvas.id = "canva_" + fade.id;
    container.appendChild(canvas);

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.beginPath();
    const shape = fade.shape;
    const fadeInOut = fade.type;
    switch (shape) {
      case "linear":
        if (fadeInOut === "fadein") {
          ctx.moveTo(0, canvas.height);
          ctx.lineTo(canvas.width, 0);
        } else {
          ctx.moveTo(0, 0);
          ctx.lineTo(canvas.width, canvas.height);
        }
        break;
      case "exponential":
        for (let i = 0; i <= 1; i += 0.01) {
          const x = i * canvas.width;
          let y;
          if (fadeInOut === "fadein") {
            y = canvas.height - Math.pow(i, 2) * canvas.height;
          } else {
            y = Math.pow(i, 2) * canvas.height;
          }
          ctx.lineTo(x, y);
        }
        break;
      case "logarithmic":
        for (let i = 0; i <= 1; i += 0.01) {
          const x = i * canvas.width;
          let y;
          if (fadeInOut === "fadein") {
            y =
              canvas.height -
              (Math.log(i * 10 + 1) / Math.log(11)) * canvas.height;
          } else {
            y = (Math.log(i * 10 + 1) / Math.log(11)) * canvas.height;
          }
          ctx.lineTo(x, y);
        }
        break;
      case "sCurve":
        for (let i = 0; i <= 1; i += 0.01) {
          const x = i * canvas.width;
          let y;
          if (fadeInOut === "fadein") {
            y =
              canvas.height - 0.5 * (1 - Math.cos(Math.PI * i)) * canvas.height;
          } else {
            y = 0.5 * (1 - Math.cos(Math.PI * i)) * canvas.height;
          }
          ctx.lineTo(x, y);
        }
        break;
      default:
        console.error("Invalid fade type");
        return;
    }

    ctx.strokeStyle = "#E2522B";
    ctx.stroke();

    const deleteButton = document.createElement("div");
    // deleteButton.innerHTML = "&#10006;"; // Unicode for "heavy multiplication X"
    deleteButton.innerHTML = "Delete";
    deleteButton.id = "fade_btn_" + fade.id;
    deleteButton.style.cssText = `
            top: ${topoffset}vh;
            left: ${leftOffset + (totalWidth - (window.innerWidth * 3.25) / 100) / 2}px;
        `;
    container.appendChild(deleteButton);

    deleteButton.addEventListener("mouseenter", (e) => {
      e.preventDefault();

      canvas.style.backgroundColor = "rgba(0, 0, 0, 0.3)";
    });

    deleteButton.addEventListener("mouseleave", (e) => {
      e.preventDefault();

      canvas.style.backgroundColor = "transparent";
    });

    // Add click event listener to the delete button
    deleteButton.addEventListener("click", async (e) => {
      e.stopPropagation();
      e.stopPropagation();

      if (store.getState().viewEditMode.mode === "view") {
        showStatText("operation not permitted in view mode");
        return;
      }

      await removeFade(fade.id); // Assuming you have a removeFade function
      container.removeChild(deleteButton);
      container.removeChild(canvas);
    });
  });
}

export function changeVolumeForRegion(wavesurferObj, region, volumeLevel) {
  const endTime = region.end,
    startTime = region.start;
  const originalBuffer = wavesurferObj.backend.buffer;
  const sampleRate = originalBuffer.sampleRate;
  const startSample = Math.floor(startTime * sampleRate);
  const endSample = Math.floor(endTime * sampleRate);
  const regionLength = endSample - startSample;

  // Create a new buffer for the selected region
  const regionBuffer = wavesurferObj.backend.ac.createBuffer(
    originalBuffer.numberOfChannels,
    regionLength,
    sampleRate,
  );

  // Copy the original audio data to the region buffer
  for (let channel = 0; channel < originalBuffer.numberOfChannels; channel++) {
    const originalData = originalBuffer.getChannelData(channel);
    const regionData = regionBuffer.getChannelData(channel);
    for (let i = 0; i < regionLength; i++) {
      regionData[i] = originalData[startSample + i];
    }
  }

  // Modify the volume of the region buffer
  for (let channel = 0; channel < regionBuffer.numberOfChannels; channel++) {
    const regionData = regionBuffer.getChannelData(channel);
    for (let i = 0; i < regionLength; i++) {
      regionData[i] *= volumeLevel;
    }
  }

  // Create a new buffer with the modified region
  const modifiedBuffer = originalBuffer.getChannelData(0).slice();
  modifiedBuffer.set(regionBuffer.getChannelData(0), startSample);
  const newBuffer = wavesurferObj.backend.ac.createBuffer(
    1,
    modifiedBuffer.length,
    originalBuffer.sampleRate,
  );
  newBuffer.copyToChannel(modifiedBuffer, 0);
  // Update the WaveSurfer instance with the new buffer
  wavesurferObj.loadDecodedBuffer(newBuffer);
  wavesurferObj.drawer.updateSize();
  return newBuffer;
}
export function saveState(currentresultStat, type) {
  let wavesurferObjSFX = store.getState().wavesurferObjSFX.wavesurferObjSFX;
  let wavesurfeObj = store.getState().wavesurferObj.wavsurferObj;
  let currentState = _.cloneDeep(wavesurfeObj);
  let currentSFXState = _.cloneDeep(wavesurferObjSFX);
  currentState.buffer = wavesurfeObj.backend.buffer;
  currentSFXState.buffer = wavesurferObjSFX.backend.buffer;
  data.undoStack.push({
    currentState,
    currentSFXState,
    currentresultStat,
    type,
  });
  updateOperationSpace();
  data.redoStack = [];
  data.canvasRedoStack = [];
}

function setProjectStatus(id, status) {
  const state = store.getState();
  let uploadProgressStatus = state.uploadProgressStatus.uploadProgress;
  uploadProgressStatus.uploadedProjectList.forEach((project) => {
    if (id === project.id) project.status = status;
  });
}
/******************fns for cut annot adjustment */
export function adjustAnnot(annot, region) {
  let adjustedAnnot = {
    ...annot,
    start_time: parseFloat(annot.start_time).toString(),
    end_time: parseFloat(annot.end_time).toString(),
  };

  if (
    parseFloat(annot.start_time) < region.start &&
    parseFloat(annot.end_time) > region.start &&
    parseFloat(annot.end_time) < region.end
  ) {
    adjustedAnnot.end_time = region.start.toString();
  }
  if (
    parseFloat(annot.start_time) < region.end &&
    parseFloat(annot.start_time) > region.start &&
    parseFloat(annot.end_time) > region.end
  ) {
    adjustedAnnot.start_time = (
      region.end -
      (region.end - region.start)
    ).toString();
    adjustedAnnot.end_time = (
      adjustedAnnot.end_time -
      (region.end - region.start)
    ).toString();
  }
  if (
    parseFloat(annot.start_time) > region.end &&
    parseFloat(annot.end_time) > region.end
  ) {
    adjustedAnnot.start_time = (
      annot.start_time -
      (region.end - region.start)
    ).toString();
    adjustedAnnot.end_time = (
      annot.end_time -
      (region.end - region.start)
    ).toString();
  }
  return adjustedAnnot;
}

export function processAnnotations(annotations, region) {
  let newAnnotations = [];
  let deleteannot = [];
  for (let i = 0; i < annotations.length; i++) {
    let annot = annotations[i];
    if (
      parseFloat(annot.end_time) <= region.end &&
      parseFloat(annot.start_time) >= region.start
    ) {
      deleteannot.push(annot);
    } else {
      newAnnotations.push(adjustAnnot(annot, region));
    }
  }
  return { newAnnotations, deleteannot };
}

export function renderFlag() {
  let flagsList = store.getState().flag.flagsList;
  let wavesurfeObj = store.getState().wavesurferObj.wavsurferObj;
  let resultstat = store.getState().projectData.resultStat;
  var width = wavesurfeObj.params.minPxPerSec * resultstat.duration;
  var widthG = document.getElementById("globalview").clientWidth;
  flagsList.forEach((flag) => {
    let ele = document.getElementById("flag-" + flag.id);
    let globalele = document.getElementById("global-flag-" + flag.id);
    if (!ele) {
      let ctr =
        flag.trackFlagMapping.customClass === "speaker"
          ? "#waveform"
          : "#sfxarea";
      ele = document.createElement("img");
      globalele = document.createElement("img");
      let globalWaveformContainer = document.querySelector("#globalview");
      let waveformContainer = document.querySelector(ctr);
      ele.style.top =
        flag.trackFlagMapping.customClass === "speaker" ? "0vh" : "21vh";
      ele.src = "/image/workspace/controls/flag.png"; // Set path to your flag image file
      ele.style.position = "absolute";
      ele.id = "flag-" + flag.id;
      ele.style.zIndex = 999;
      ele.addEventListener("click", function () {
        store.dispatch(flagListActions.updateFlagId(flag.id));
        store.dispatch(flagListActions.updateFlagShowModal(true));
      });
      globalele.addEventListener("click", function () {
        globalFlagTriggerFunction(flag.id);
      });
      ele.style.cursor = "pointer";
      ele.title = `${Koolioai.username}: ${flag.flagContent.comment}`;
      waveformContainer.appendChild(ele);
      globalele.src = "/image/workspace/controls/flag.png"; // Set path to your flag image file
      globalele.style.position = "absolute";
      globalele.style.width = "0.8463541666666666vw";
      globalele.style.top = "-10px";
      globalele.id = "global-flag-" + flag.id;
      globalele.style.zIndex = 99;
      globalele.style.cursor = "pointer";
      globalele.title = `${Koolioai.username}: ${flag.flagContent.comment}`;
      globalWaveformContainer.appendChild(globalele);
    }

    let positionX =
      (flag.trackFlagMapping.startTime / resultstat.duration) * width;
    let positionXG =
      (flag.trackFlagMapping.startTime / resultstat.duration) *
      parseInt(widthG);
    globalele.style.left = positionXG - 3 + "px"; // Adjust based on your needs
    ele.style.left = positionX - 3 + "px"; // Adjust based on your needs
  });
}

export function annotUIshiftSub(resultStats, props) {
  let deleteannot = [];

  resultStats.speakers_segments.forEach((element) => {
    element.annotations.forEach((annot) => {
      let annotdata = document.getElementById(annot.id + "_div");
      let startTime = parseFloat(annot.start_time);
      let endTime = parseFloat(annot.end_time);
      let annotWidth = convertTimeToPixel(endTime - startTime);
      let annotLeft = convertTimeToPixel(startTime);
      if (!annotdata) {
        deleteannot.push(annot);
        annotdata = document.createElement("div");
        annotdata.id = `${annot.id}_div`;
        annotdata.addEventListener("click", function (e) {});
        annotdata.innerText = annot.content;
        annotdata.classList.add("overflow-annot");
        let transcriptionsDiv = document.getElementById("transcriptions_div");
        transcriptionsDiv.appendChild(annotdata);
      }
      annotdata.onclick = null;
      annotdata.removeAttribute("style");
      annotdata.setAttribute(
        "style",
        `display: inline; cursor: pointer; font-size: 0.8em;  position: absolute; width: ${annotWidth}px; left: ${annotLeft}px; text-align: center;`,
      );
      let currentSpeakerId = document
        .getElementsByClassName("slick-current")[0]
        .id.split("-")[1];
      if (currentSpeakerId == annotdata.id.split("_")[1]) {
        annotdata.classList.add("active-annot");
      } else {
        annotdata.classList.add("inactive-annot");
      }
      if (annot.is_silenced === true) {
        annotdata.classList.remove("active-annot", "inactive-annot");
        annotdata.style.color = "#818181";
      }
      if (annotdata.transcribed) {
        annotdata.onclick = function (e) {
          showMessage(
            "Please wait",
            "Transcription is in progress",
            500,
            "error",
            "workspace",
          );
        };
      } else {
        annotdata.onclick = function (e) {
          const regionaudio = props.wavesurferObj.regions.list;
          const regionSFX = props.wavesurferObjSFX.regions.list;
          for (const regionKey in regionaudio) {
            regionaudio[regionKey].remove();
          }
          for (const regionKey in regionSFX) {
            regionSFX[regionKey].remove();
          }
          e.preventDefault();
          const annotation = {
            id: `${annot.id}`,
            start: parseFloat(annot.start_time),
            end: parseFloat(annot.end_time),
            data: annot,
            drag: true,
            resize: true,
          };
          wordMultiSelect(annotation);
          annotdata.classList.add("clicked-annot");
          props.wavesurferObj.addRegion({
            start: parseFloat(annotation.start), // start time of the region
            end: parseFloat(annotation.end), // end time of the region
            color: "hsla(265, 100%, 86%, 0.4)", // color of the region
          });
        };
      }
    });
  });
  resultStats.sfx_segments.forEach((element) => {
    element.annotations?.forEach((annot) => {
      let annotdata = document.getElementById(annot.id + "_div");
      let startTime = parseFloat(annot.start_time);
      let endTime = parseFloat(annot.end_time);
      let annotWidth = convertTimeToPixel(endTime - startTime);
      let annotLeft = convertTimeToPixel(startTime);
      if (!annotdata) {
        deleteannot.push(annot);
        //deleteannot[annot.id] = annot;
        annotdata = document.createElement("div");
        annotdata.classList.add("overflow-annot");
        annotdata.id = `${annot.id}_div`;
        annotdata.addEventListener("click", function (e) {});
        annotdata.innerText = annot.content.replace(/\s+/g, "");
        let sfxtranscriptionsDiv = document.getElementById(
          "transcriptions_div_sfx",
        );
        if (!sfxtranscriptionsDiv) {
          sfxtranscriptionsDiv = document.createElement("div");
          sfxtranscriptionsDiv.id = "transcriptions_div_sfx";
          sfxtranscriptionsDiv.setAttribute(
            "style",
            "position: absolute; overflow: visible; height: 4vh; margin-top:1vh; font-weight:700;",
          );
          const sfxtranscriptWrapper = document.getElementById(
            "transcription-wrapper-sfx",
          );
          if (sfxtranscriptWrapper) {
            sfxtranscriptWrapper.appendChild(sfxtranscriptionsDiv);
          }
        }
        sfxtranscriptionsDiv.appendChild(annotdata);
      }

      annotdata.onclick = null;
      annotdata.onclick = function (e) {
        resetAnnotation();
        const regionaudio = props.wavesurferObj.regions.list;
        const regionSFX = props.wavesurferObjSFX.regions.list;
        for (const regionKey in regionaudio) {
          regionaudio[regionKey].remove();
        }
        for (const regionKey in regionSFX) {
          regionSFX[regionKey].remove();
        }
        e.preventDefault();
        const annotation = {
          id: `${annot.id}`,
          start: parseFloat(annot.start_time),
          end: parseFloat(annot.end_time),
          data: annot,
          drag: true,
          resize: true,
        };
        dragDropWord(annotation);
        annotdata.classList.add("clicked-annot");
        props.wavesurferObjSFX.addRegion({
          start: parseFloat(annotation.start), // start time of the region
          end: parseFloat(annotation.end), // end time of the region
          color: "hsla(265, 100%, 86%, 0.4)", // color of the region
        });
      };
      annotdata.removeAttribute("style");
      annotdata.setAttribute(
        "style",
        `display: inline; cursor: pointer; color: white; font-size: 0.8em;  position: absolute; width: ${annotWidth}px; left: ${annotLeft}px; text-align: center;`,
      );
    });
  });
  //changing the annotations div width according to new width
  let wavesurferObj = store.getState().wavesurferObj.wavsurferObj;
  let pixPerSecond = wavesurferObj.params.minPxPerSec;
  let newWidth = Math.round(wavesurferObj.getDuration() * pixPerSecond);
  let fitwaveformtovw = false;
  if (newWidth < document.getElementById("playlist").offsetWidth) {
    newWidth = document.getElementById("playlist").offsetWidth;
    pixPerSecond = Math.round(newWidth / wavesurferObj.getDuration());
    fitwaveformtovw = true;
  }
  handleZoomLevel(props, newWidth, pixPerSecond, fitwaveformtovw);
  return deleteannot;
}

export function ajustAnnotandFadeAfterZoom() {
  let resultStat = store.getState().projectData.resultStat;
  resultStat.speakers_segments.forEach((track) => {
    track.annotations.forEach((annot) => {
      let width = convertTimeToPixel(annot.end_time - annot.start_time) + "px";
      let left = convertTimeToPixel(annot.start_time) + "px";
      if (document.getElementById(annot.id + "_div")) {
        document.getElementById(annot.id + "_div").style.width = width;
      }
      if (document.getElementById(annot.id + "_div")) {
        document.getElementById(annot.id + "_div").style.left = left;
      }
    });
  });
  resultStat.sfx_segments.forEach((track) => {
    track.annotations?.forEach((annot) => {
      let width = convertTimeToPixel(annot.end_time - annot.start_time) + "px";
      let left = convertTimeToPixel(annot.start_time) + "px";
      if (document.getElementById(annot.id + "_div")) {
        document.getElementById(annot.id + "_div").style.width = width;
      }
      if (document.getElementById(annot.id + "_div")) {
        document.getElementById(annot.id + "_div").style.left = left;
      }
    });
  });
  renderFade(resultStat);
  renderFlag();
}

export function handleZoomLevel(
  props,
  newWidth,
  newZoom,
  fitwaveformtovw = false,
) {
  props.wavesurferObj.zoom(newZoom);
  props.wavesurferObjSFX.zoom(newZoom);
  document.querySelectorAll("wave").forEach((wave) => {
    if (
      wave.style.position === "relative" &&
      wave.parentElement.id !== "globalview"
    ) {
      const width = props.wavesurferObj.getDuration() * newZoom;
      wave.style.width = `${width}px`;
      // wave.children[0].style.width = `${0}px`;
      wave.style.overflow = "visible";
    }
  });
  if (document.getElementById("transcriptions_div")) {
    document.getElementById("transcriptions_div").style.width = `${newWidth}px`;
  }

  if (document.getElementById("transcriptions_div_sfx")) {
    document.getElementById("transcriptions_div_sfx").style.width =
      `${newWidth}px`;
  }
  if (fitwaveformtovw) {
    ajustAnnotandFadeAfterZoom();
  }
  updateCurrentTime("", props.wavesurferObj);
}
export function activeAnnotationColor() {
  const annotationList = store.getState().annotationList.annotationList;
  if (annotationList.length > 0) {
    annotationList.map((annot) => {
      const currAnnotElem = document.getElementById(`${annot.id}_div`);

      if (currAnnotElem) {
        currAnnotElem.classList.replace("inactive-annot", "active-annot");
        currAnnotElem.classList.remove("box-shadow");
      }
    });
    store.dispatch(annotationListActions.clearAnnotationList());
  }
}
export function resetAnnotation() {
  const highlightAnnotationList =
    store.getState().annotationList.highlightAnnotationList;
  const highlightSFXAnnotationList =
    store.getState().annotationList.highlightSFXAnnotationList;
  if (highlightAnnotationList.length > 0) {
    highlightAnnotationList.map((annot) => {
      const currAnnotElem = document.getElementById(`${annot.id}_div`);
      const currTransAnnotElem = document.getElementById(`${annot.id}_span`);
      const currTransModeAnnotElem = document.getElementById(
        `${annot.id}_tspan`,
      );
      if (currAnnotElem) {
        currAnnotElem.classList.remove("clicked-annot");
        currAnnotElem.classList.remove("box-shadow");
        if (currTransAnnotElem) {
          currTransAnnotElem.classList.remove("clicked-annot");
        }
        if (currTransModeAnnotElem) {
          currTransModeAnnotElem.classList.remove("clicked-annot");
        }
      }
    });
    store.dispatch(annotationListActions.clearAnnotationList());
  }
  if (highlightSFXAnnotationList.length > 0) {
    highlightSFXAnnotationList.map((annot) => {
      const currAnnotElem = document.getElementById(`${annot.id}_div`);
      if (currAnnotElem) {
        currAnnotElem.classList.remove("clicked-annot");
      }
    });
    // store.dispatch(annotationListActions.clearSFXAnnotationList());
  }
}

export function handleWindowLoadForKoolioTagline(val = true) {
  document.getElementById("koolio-space").classList.add("d-none");
  val
    ? document.getElementById("koolio-login-logo").classList.add("d-none")
    : document.querySelector(".koolio-landing").classList.add("d-none");

  document.getElementById("landing-taglines-profile").innerHTML = "";
  const img1 = document.createElement("img");
  img1.setAttribute("src", "/image/landing-page/Tagline01@4x.png");
  img1.setAttribute("class", "tag1");
  img1.setAttribute("id", "tag1");
  img1.setAttribute("alt", "Tagline01");
  const img2 = document.createElement("img");
  img2.setAttribute("src", "/image/landing-page/Tagline02@4x.png");
  img2.setAttribute("class", "tag2");
  img2.setAttribute("id", "tag2");
  img2.setAttribute("alt", "Tagline02");
  const img3 = document.createElement("img");
  img3.setAttribute("src", "/image/landing-page/Tagline03@4x.png");
  img3.setAttribute("class", "tag3");
  img3.setAttribute("id", "tag3");
  img3.setAttribute("alt", "Tagline03");
  document.getElementById("landing-taglines-profile").appendChild(img1);
  document.getElementById("landing-taglines-profile").appendChild(img2);
  document.getElementById("landing-taglines-profile").appendChild(img3);
  authRes();
  setTimeout(() => {
    val ? mediaQueriesss() : mediaQueriess();
  }, 1500);
}

export const disableIconSpacingButtons = async () => {
  // Access elements with class name 'icon-spacing'
  const iconElements = document.getElementsByClassName("disableBtnClass");

  // Convert HTMLCollection to an array to use array methods
  Array.from(iconElements).forEach((element) => {
    // Add 'disable-operation-btns' class to each element
    element.classList.add("disable-operation-btns");
  });
};
export const enableIconSpacingButtons = async () => {
  // Access elements with class name 'icon-spacing'
  const iconElements = document.getElementsByClassName("disableBtnClass");

  // Convert HTMLCollection to an array to use array methods
  Array.from(iconElements).forEach((element) => {
    // Add 'disable-operation-btns' class to each element
    element.classList.remove("disable-operation-btns");
  });
};
export const disableOperationsForNestedImport = async () => {
  // Access elements with class name 'icon-spacing'
  const iconElements = document.getElementsByClassName("disableBtnClass");

  // Convert HTMLCollection to an array to use array methods
  Array.from(iconElements).forEach((element) => {
    // Add 'disable-operation-btns' class to each element
    if (
      element.id !== "btn-play" &&
      element.id !== "btn-loop" &&
      element.id !== "btn-rewind-cont" &&
      element.id !== "btn-forward-cont"
    ) {
      element.classList.add("disable-operation-btns");
    }
  });
};
export function fetchuserName() {
  let username = JSON.parse(localStorage.getItem("username"));
  if (!username) {
    username = Koolioai.getDisplayName();
  }
  const profileImageUrl = `https://ui-avatars.com/api/?name=${username}&background=181818&color=fff&rounded=true&border=ffa500&size=56`;
  return profileImageUrl;
}
/***************INSTANT DOWNLOAD Function *****************************/
export const handlePrint = (fileNameForExport, exportTrans = false) => {
  const permResponse = ACM.checkPermission("transcript-print");
  if (!exportTrans && !permResponse.success)
    return showMessage(
      permResponse.message,
      "Transcription print is not available in the free plan",
      2000,
      "error",
      "workspace",
    );

  const resultStat = store.getState().projectData.resultStat;
  const segments = resultStat.speakers_segments; // fetch the segments from resulstat

  if (!segments || !segments.length) {
    showStatText("Segments are undefined or empty");
    return;
  }

  const pdf = new jsPDF();
  let lines = []; // to store all contents from resultstat including speaker name, start_time and annotations
  const maxWidth = 450;
  const indentation = "";
  const lineHeight = 12;
  const marginTop = 20;
  const marginBottom = 20;

  const logoImg = new Image(); //create new
  logoImg.src = "/img/logo.png";
  logoImg.onload = function () {
    const imgWidth = 60;
    const imgHeight = (logoImg.height * imgWidth) / logoImg.width;
    const x = (pdf.internal.pageSize.getWidth() - imgWidth) / 2; // Center logo horizontally

    const pageHeight = pdf.internal.pageSize.getHeight();

    // Add logo at the top center of the page
    pdf.addImage(logoImg, "PNG", x, marginTop, imgWidth, imgHeight);

    // Centered title below the logo
    pdf.setFont(undefined, "bold");
    pdf.setFontSize(18);
    const titleText = document.getElementById("projectname").textContent;
    const titleWidth = pdf.getTextWidth(titleText);
    const titleX = (pdf.internal.pageSize.getWidth() - titleWidth) / 2;
    const titleY = marginTop + imgHeight + 20; // Position below the logo
    pdf.text(titleText, titleX, titleY);

    // Subtitle below the title with font size 8
    pdf.setFont(undefined, "normal");
    pdf.setFontSize(8);
    const subtitleText = "Transcription with Diarization";
    const subtitleWidth = pdf.getTextWidth(subtitleText);
    const subtitleX = (pdf.internal.pageSize.getWidth() - subtitleWidth) / 2;
    const subtitleY = titleY + 5; // Position below the title with padding
    pdf.setTextColor(128, 128, 128); // Gray color to simulate 50% opacity
    pdf.text(subtitleText, subtitleX, subtitleY);

    // Set starting Y position for segments below the title and subtitle
    let startY = subtitleY + 20;

    segments.forEach((segment) => {
      const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
      };

      lines.push({
        type: "speaker-line",
        speaker: "Speaker " + segment.speaker_label.split("-")[1],
        time: formatTime(segment.start_time),
      });

      let segmentText = "";
      segment.annotations.forEach((annotation) => {
        if (typeof annotation === "string") {
          segmentText += annotation + " ";
        } else if (typeof annotation === "object" && annotation.content) {
          segmentText += annotation.content + " ";
        }
      });

      if (segmentText.trim()) {
        let words = segmentText.trim().split(" ");
        let currentLine = indentation;

        words.forEach((word) => {
          let testLine =
            currentLine + (currentLine === indentation ? "" : " ") + word;
          let width = pdf.getStringUnitWidth(testLine) * 10;

          if (width < maxWidth) {
            currentLine = testLine;
          } else {
            lines.push({ type: "text", content: currentLine });
            currentLine = indentation + word;
          }
        });

        if (currentLine !== indentation) {
          lines.push({ type: "text", content: currentLine });
        }
      }

      lines.push({ type: "empty" });
    });

    let currentPage = 1;
    let currentLine = 0;
    let currentY = startY;

    const renderPage = () => {
      while (currentLine < lines.length) {
        if (currentY > pageHeight - marginBottom) {
          pdf.addPage();
          currentPage++;
          currentY = marginTop;
        }

        const line = lines[currentLine];

        if (line.type === "speaker-line") {
          pdf.setFont(undefined, "bold");
          pdf.setFontSize(14);
          pdf.setTextColor(0, 0, 0);
          pdf.text(line.speaker, 10, currentY);

          const speakerWidth =
            (pdf.getStringUnitWidth(line.speaker) * 12) /
            pdf.internal.scaleFactor;
          pdf.setFont(undefined, "normal");
          pdf.setFontSize(10);
          pdf.setTextColor(128, 128, 128);
          pdf.text(line.time, 10 + speakerWidth + 10, currentY);

          currentY += lineHeight;
        } else if (line.type === "text") {
          pdf.setFont(undefined, "normal");
          pdf.setFontSize(11);
          pdf.setTextColor(0, 0, 0);
          pdf.text(line.content, 10, currentY);

          currentY += 5;
        } else if (line.type === "empty") {
          currentY += lineHeight;
        }

        currentLine++;
      }
    };

    renderPage();

    //conditions to separate the export transcriptions with print transcriptions
    if (!exportTrans) {
      const pdfBlob = pdf.output("blob");
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const newWindow = window.open(pdfUrl, "_blank");

      newWindow.onload = function () {
        newWindow.print();
      };
    } else {
      let fileNameTitle =
        fileNameForExport.length > 0 ? fileNameForExport : "Transcript";
      pdf.save(`${fileNameTitle}.pdf`);
    }
  };
};
/***************INSTANT DOWNLOAD Function *****************************/
export function insertAnnotations(
  affectedTrack,
  startTime,
  insertDuration,
  trackType,
  refid = false,
  transcriptions = [],
  nestedImportFileID = null,
) {
  let newAnnotations = [];
  let nestedFileAnnot = []; // this is for the annotation for nestedFileTranscription
  const resultStat = JSON.parse(
    JSON.stringify(store.getState().projectData.resultStat),
  );
  let copiedAnnotations = store.getState().copiedAnnotation.copiedAnnotation;
  if (trackType == "sfx_segments") {
    resultStat.sfx_segments.forEach((track) => {
      if (track.start_time >= startTime) {
        track.start_time = (parseFloat(track.start_time) + insertDuration)
          .toFixed(2)
          .toString();
        track.end_time = (parseFloat(track.end_time) + insertDuration)
          .toFixed(2)
          .toString();
        track.annotations[0].start_time = track.start_time;
        track.annotations[0].end_time = track.end_time;
      }
      newAnnotations.push(track);
    });

    if (affectedTrack === "sfx_segments") {
      let newCopyAnnotationsList = []; //  we will pass the copied annotations data to global so that correct copied anotations id would be send to backend
      copiedAnnotations.forEach((annot) => {
        let newAnnot = JSON.parse(JSON.stringify(annot));
        newAnnot.start_time = startTime.toFixed(2).toString();
        newAnnot.end_time = (startTime + insertDuration).toFixed(2).toString();
        newAnnot.annotations[0].start_time = startTime.toFixed(2).toString();
        newAnnot.annotations[0].end_time = (startTime + insertDuration)
          .toFixed(2)
          .toString();
        newAnnot.annotations[0].id = `sfx_0_${create_UUID()}`;

        newAnnotations.push(newAnnot);
        newCopyAnnotationsList.push(newAnnot);
      });
      store.dispatch(
        copiedAnnotActions.updSendAnnot({
          toSendAnnot: newCopyAnnotationsList,
        }),
      );
    }
  } else {
    resultStat.speakers_segments.forEach((track) => {
      let trackAnnotation = [];
      track.annotations.forEach((annotation) => {
        if (nestedImportFileID !== annotation.id) {
          if (annotation.start_time >= startTime) {
            let newAnnotation = {
              ...annotation,
              start_time: (parseFloat(annotation.start_time) + insertDuration)
                .toFixed(2)
                .toString(),
              end_time: (parseFloat(annotation.end_time) + insertDuration)
                .toFixed(2)
                .toString(),
            };
            trackAnnotation.push(newAnnotation);
          } else {
            trackAnnotation.push(annotation);
          }
        } else {
          nestedFileAnnot.push(annotation);
        }
      });
      if (track.speaker_label == trackType) {
        if (transcriptions.length > 0) {
          trackAnnotation.push(...transcriptions);
        } else {
          if (
            affectedTrack !== "sfx_segments" &&
            track.start_time < startTime &&
            track.end_time > startTime
          ) {
            let newCopyAnnotationsList = []; //  we will pass the copied annotations data to global so that correct copied anotations id would be send to backend
            copiedAnnotations.forEach((annot) => {
              let newAnnot = { ...annot };
              newAnnot.start_time = (startTime + parseFloat(annot.start_time))
                .toFixed(2)
                .toString();
              newAnnot.end_time = (startTime + parseFloat(annot.end_time))
                .toFixed(2)
                .toString();
              newAnnot.speaker_label =
                "spk_" + trackType.charAt(trackType.length - 1);
              newAnnot.id =
                "spk_" +
                trackType.charAt(trackType.length - 1) +
                "_" +
                create_UUID();
              if (annot.transcribed) {
                let file = {
                  id: newAnnot.id,
                  start_time: newAnnot.start_time,
                  end_time: newAnnot.end_time,
                  refid,
                };
                store.dispatch(nestedFileSliceActions.addNestedFiles({ file }));
              }
              trackAnnotation.push(newAnnot);
              newCopyAnnotationsList.push(newAnnot);
            });
            store.dispatch(
              copiedAnnotActions.updSendAnnot({
                toSendAnnot: newCopyAnnotationsList,
              }),
            );
          }
        }
      }

      newAnnotations.push(trackAnnotation);
    });
  }
  if (transcriptions.length > 0) {
    return [newAnnotations, nestedFileAnnot];
  } else {
    return newAnnotations;
  }
}
export async function restThings(
  params,
  resultStat,
  containsFile = false,
  isImport = false,
) {
  let operationList = store.getState().operationListData.operationList;
  let isOperationsTransActive =
    store.getState().operationListData.isOperationsTransActive;
  const newOperationList = [...operationList];
  const trackInfo = {
    isImport,
    customClass: params.inputs.customClass,
  };
  if (isImport) {
    let transcriptionRequired = JSON.parse(
      sessionStorage.getItem("NestedtranscriptUploadOption"),
    );
    if (transcriptionRequired) {
      params.inputs.isTranscriptInitiated = true;
    }
  }
  params.inputs.operationPerformedBy = Koolioai.username;
  newOperationList.unshift({
    params,
    containsFile,
    tracks: params.inputs.tracks ? params.inputs.tracks : [],
    trackInfo,
  });
  store.dispatch(
    operationListSliceActions.operationReducerData(newOperationList),
  );
  store.dispatch(projectDataSliceActions.projectReducerData({ resultStat }));
  await addOperationsToDB(
    params,
    undefined,
    false,
    "false",
    "pending",
    "false",
  );
  if (!isOperationsTransActive) await sendOperation();
}

export const hideZoomControls = () => {
  const zoomControls = document.getElementById("zoom-control");
  const revealZoomControls = document.getElementById("reveal-zoom-controls");
  const zoomWrapper = document.getElementById("zoom-wrapper");
  if (!zoomControls) return;
  if (!revealZoomControls) return;
  if (!zoomWrapper) return;

  revealZoomControls.style.visibility = "visible";
  revealZoomControls.style.opacity = "1";
  zoomWrapper.style.visibility = "hidden";
  zoomWrapper.style.opacity = "0";
  zoomControls.style.visibility = "hidden";
  zoomControls.style.opacity = "0";
};
export function adjustFade(resultStat, regionList, type, place) {
  if (resultStat.fades === undefined) {
    return;
  }
  let fades = [...resultStat.fades];
  // Sort regionList by start time to process regions in order
  let sortedRegionList = [...regionList].sort((a, b) => a.start - b.start);

  fades = fades.map((fade) => {
    let adjustedFade = JSON.parse(JSON.stringify(fade));
    let totalDuration = 0;

    for (let region of sortedRegionList) {
      if (type === "cut") {
        if (adjustedFade.trackType === place) {
          if (region.end <= adjustedFade.start_time) {
            // Cut is before fade, adjust start and end
            totalDuration += region.end - region.start;
          } else if (region.start < adjustedFade.end_time) {
            // Cut overlaps with fade
            if (region.start <= adjustedFade.start_time) {
              // Cut starts before or at fade start
              if (region.end >= adjustedFade.end_time) {
                // Cut entirely contains fade, remove fade
                return null;
              } else {
                // Cut partially overlaps fade start
                adjustedFade.start_time = region.end - totalDuration;
              }
            } else {
              // Cut starts within fade
              if (region.end >= adjustedFade.end_time) {
                // Cut ends after fade, adjust end
                adjustedFade.end_time = region.start - totalDuration;
              } else {
                // Cut is entirely within fade, return without changes
                return adjustedFade;
              }
            }
          }

          // Adjust fade times based on cuts
          if (adjustedFade.start_time > region.start) {
            adjustedFade.start_time -= region.end - region.start;
          }
          if (adjustedFade.end_time > region.start) {
            adjustedFade.end_time -= region.end - region.start;
          }
        }
      } else {
        // For insert, we move fades that start after the insertion point
        if (adjustedFade.start_time >= region.start) {
          adjustedFade.start_time += region.end - region.start;
          adjustedFade.end_time += region.end - region.start;
        } else if (adjustedFade.end_time > region.start) {
          // If insertion point is within the fade, extend the fade
          adjustedFade.end_time += region.end - region.start;
        }
      }
    }
    adjustedFade.src = fade.src;
    console.log(adjustedFade);
    return adjustedFade;
  });

  // Remove any fades that were nullified (entirely contained within a cut)
  fades = fades.filter((fade) => fade !== null);
  const updatedResultStat = { ...resultStat, fades: fades };
  renderFade(updatedResultStat);
  return updatedResultStat;
}
export const handleAnnotationShift = (
  trackType,
  curr_word_list,
  oldresultStat,
  region = false,
  gaps = null,
) => {
  let totalRemovedDuration = 0;
  let newAnnotations = [];
  let deleteannot = [];
  let resultStat = JSON.parse(JSON.stringify(oldresultStat));
  if (region) {
    if (trackType === "sfx_segments") {
      let segment = [];
      resultStat.sfx_segments.forEach((track) => {
        if (track.annotations.length) {
          if (
            track.annotations[0].end_time <= region.end &&
            track.annotations[0].start_time >= region.start
          ) {
            deleteannot.push(track.annotations[0]);
            return;
          } else if (
            track.annotations[0].start_time < region.start &&
            track.annotations[0].end_time > region.start &&
            track.annotations[0].end_time < region.end
          ) {
            track.end_time = region.start.toString();
            track.annotations[0].end_time = track.end_time;
          } else if (
            track.annotations[0].start_time < region.end &&
            track.annotations[0].start_time > region.start &&
            track.annotations[0].end_time > region.end
          ) {
            track.start_time = region.end - (region.end - region.start);
            track.annotations[0].start_time = track.start_time;

            track.end_time = track.end_time - (region.end - region.start);

            track.annotations[0].end_time = track.end_time;
          } else if (
            track.annotations[0].start_time > region.end &&
            track.annotations[0].end_time > region.end
          ) {
            track.start_time = track.start_time - (region.end - region.start);
            track.annotations[0].start_time = track.start_time;
            track.end_time = track.end_time - (region.end - region.start);

            track.annotations[0].end_time = track.end_time;
          }
        }
        segment.push(track);
      });
      newAnnotations = segment;
    } else {
      newAnnotations = resultStat.speakers_segments.map((track) => {
        const result = processAnnotations(track.annotations, region);
        result.deleteannot.map((delannot) => {
          deleteannot.push(delannot);
        });
        return result.newAnnotations;
      });
    }
  } else if (gaps) {
    resultStat.speakers_segments.forEach((track) => {
      let trackannot = [];

      track.annotations.forEach((annot) => {
        let annotation = { ...annot };
        let startTime = parseFloat(annotation.start_time);
        let endTime = parseFloat(annotation.end_time);
        let totalAdjustment = 0;

        // Calculate adjustment based on all preceding gaps
        gaps.forEach((gap) => {
          let gapStart = parseFloat(gap.start);
          let gapEnd = parseFloat(gap.end);
          let gapDuration = gapEnd - gapStart;

          // If annotation starts after the gap, subtract the gap duration
          if (startTime > gapEnd) {
            totalAdjustment += gapDuration;
          }
          // If annotation overlaps with gap, handle accordingly
          else if (startTime < gapEnd && endTime > gapStart) {
            // Skip this annotation as it overlaps with a gap
            return;
          }
        });

        // Only add the annotation if it wasn't skipped due to gap overlap
        if (totalAdjustment >= 0) {
          let newAnnotation = {
            ...annotation,
            start_time: (startTime - totalAdjustment).toString(),
            end_time: (endTime - totalAdjustment).toString(),
          };
          trackannot.push(newAnnotation);
        }
      });

      newAnnotations.push(trackannot);
    });
  } else {
    let wordsToRemove = curr_word_list;
    let wordsToRemoveMap = {};
    let cumulativeDuration = 0;

    wordsToRemove.forEach((word) => {
      wordsToRemoveMap[word.wordid] = word;
    });

    resultStat.speakers_segments.forEach((track) => {
      let trackAnnotation = [];

      // Sort annotations by start time
      const sortedAnnotations = [...track.annotations].sort(
        (a, b) => parseFloat(a.start_time) - parseFloat(b.start_time),
      );

      sortedAnnotations.forEach((annotation) => {
        if (wordsToRemoveMap[annotation.id]) {
          deleteannot.push(annotation);
          cumulativeDuration +=
            parseFloat(annotation.end_time) - parseFloat(annotation.start_time);
        } else {
          trackAnnotation.push({
            ...annotation,
            start_time: (
              parseFloat(annotation.start_time) - cumulativeDuration
            ).toString(),
            end_time: (
              parseFloat(annotation.end_time) - cumulativeDuration
            ).toString(),
          });
        }
      });

      newAnnotations.push(trackAnnotation);
    });
  }
  deleteannotation(deleteannot);
  return newAnnotations;
};
export function deleteannotation(annotarr) {
  if (annotarr) {
    annotarr.forEach((element) => {
      if (document.getElementById(element.id + "_div")) {
        document.getElementById(element.id + "_div").remove();
      }
    });
  }
}

export async function removeFade(fadeid) {
  let wavesurfeObj = store.getState().wavesurferObj.wavsurferObj;
  let wavesurferObjSFX = store.getState().wavesurferObjSFX.wavesurferObjSFX;
  let startTime, trackType, copyBuffer;
  let resultStat = store.getState().projectData.resultStat;
  let operationId = create_UUID();

  resultStat.fades.forEach((fade) => {
    if (fade.id === fadeid) {
      startTime = fade.start_time;
      trackType = fade.trackType;
      copyBuffer = fade.src;
    }
  });

  let params = {
    jobname: resultStat.jobname,
    refid: operationId,
    parentRefid: resultStat.refid,
    worker: "removeFades",
    inputs: {
      cursorPos: startTime,
      customClass: trackType,
      fadeid,
      selectedCustomClasses: ["speakers-0"],
    },
  };

  let currentsegment =
    trackType === "sfx_segments" ? wavesurferObjSFX : wavesurfeObj;

  const originalBuffer = currentsegment.backend.buffer;
  const sampleRate = originalBuffer.sampleRate;
  const startSample = Math.floor(startTime * sampleRate);

  // Create a new buffer with the same length as the original
  const newBuffer = currentsegment.backend.ac.createBuffer(
    1,
    originalBuffer.length,
    sampleRate,
  );

  // Copy the original buffer
  newBuffer.copyToChannel(originalBuffer.getChannelData(0), 0);

  // Replace the faded portion with the original (unfaded) audio from copyBuffer
  newBuffer.copyToChannel(copyBuffer, 0, startSample);

  // Update the buffer
  let newResultStat = JSON.parse(JSON.stringify(resultStat));
  newResultStat.fades = resultStat.fades ? resultStat.fades : [];

  if (trackType === "sfx_segments") {
    newResultStat.sfx_src = newBuffer;
    newResultStat.speakers_src = resultStat.speakers_src;
  } else {
    newResultStat.speakers_src = newBuffer;
    newResultStat.sfx_src = resultStat.sfx_src;
  }

  // Remove the fade from the resultStat.fades array
  newResultStat.fades = resultStat.fades.filter((fade) => fade.id !== fadeid);

  // Update the waveform display
  currentsegment.loadDecodedBuffer(newBuffer);

  restThings(params, newResultStat);
  updateOperationSpace();
}

export function toggleSpeakerImgSrc() {
  let resultStat = store.getState().projectData.resultStat;
  resultStat.speakers_segments.map((speaker) => {
    document.getElementById(speaker.id + "-spk").classList.toggle("d-none");
  });
}

export function changeSpeakerTrack(annot, annotationList) {
  const allSpeakers = document.querySelectorAll(".slick-current");
  allSpeakers.forEach((speaker) => {
    speaker.classList.remove("slick-current");
  });
  let currSpk = annot?.speaker_label;
  let currid = parseInt(currSpk.split("_")[1]);
  // const currSpeaker = document.querySelectorAll(`#speakers-${currid}-slider`);
  // currSpeaker.forEach((speaker) => {
  //   speaker.classList.add("slick-current");
  // });
  const currSpeaker = document.getElementById(`speakers-${currid}-slider`);
  currSpeaker?.classList.add("slick-current");
  const currSpeakerTrans = document.getElementById(
    `speakers-${currid}-slider-transcript`,
  );
  currSpeakerTrans?.classList.add("slick-current");
  annotationList.forEach((annot) => {
    if (annot.speaker_label === currSpk) {
      let annotdata = document.getElementById(annot.id + "_div");
      if (annotdata) {
        annotdata.classList.remove("inactive-annot");
        annotdata.classList.add("active-annot");
      }
    } else {
      let annotdata = document.getElementById(annot.id + "_div");
      if (annotdata) {
        annotdata.classList.remove("active-annot");
        annotdata.classList.add("inactive-annot");
      }
    }
  });
  let annotdata = document.getElementById(annot.id + "_tspan");
  if (annotdata) {
    annotdata.classList.add("clicked-annot");
    store.dispatch(annotationListActions.updateHighlightAnnotationList(annot));
  }
}

export function verticalScrollToView(element, container) {
  if (!element || !container) return; // Safeguard against null elements

  // Get the bounding rectangles of the element and the container
  const elementRect = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  // Calculate the offset by comparing the top of the element to the container's top
  let offset = elementRect.top - containerRect.top + container.scrollTop;

  // Scroll down if the element is out of the visible bottom range
  if (offset >= container.scrollTop + container.clientHeight - 30) {
    container.scrollTo({ top: offset - 40, behavior: "smooth" });
  }
  // Scroll up if the element is out of the visible top range
  else if (elementRect.top < containerRect.top) {
    container.scrollTo({ top: offset - 40, behavior: "smooth" });
  }
}

export async function mergeSegments(resultStat) {
  let newSegments = [];
  for (let index = 0; index < resultStat.speakers_segments.length; index++) {
    let segment = { ...resultStat.speakers_segments[index] };

    while (
      index + 1 < resultStat.speakers_segments.length && // Check that there is a next segment
      segment.speaker_label ===
        resultStat.speakers_segments[index + 1].speaker_label && // Same speaker
      segment.end_time === resultStat.speakers_segments[index + 1].start_time // end_time matches start_time of next segment
    ) {
      let nextSegment = resultStat.speakers_segments[index + 1];
      segment.end_time = nextSegment.end_time;
      segment.annotations = [
        ...segment.annotations,
        ...nextSegment.annotations,
      ];

      // Move to the next segment (which has been merged) by incrementing the index
      index++;
    }

    // Push the fully merged segment (or single segment if no merging occurred) to newSegments
    newSegments.push(segment);
  }
  return newSegments;
}
export function getSelectedCC(res) {
  return Object.keys(res.speakers).concat(Object.keys(res.sfx));
}
