import React, { useState, useEffect } from "react";
import "../../../../css/dark/playlistarea.css";
import "../../../../css/dark/editcontrolls.css";
import SfxModal from "../../../../components/modals/SfxModal";
//import Slider from 'react-slider';

// import '../Workspace.css'
import { clickedRegionActions } from "../../../../redux/slice/ClickedRegionSlice";
import { UseSelector, useDispatch, useSelector } from "react-redux";
import { multiSelectWordActions } from "../../../../redux/slice/MultiSelectWordList";
import { multiSelectActions } from "../../../../redux/slice/MultiselectSlice";
import ToggleButton from "../navbarWorkspace/ToggleButton";
import store from "../../../../redux/Store";
import ExportTranscript from "../../../../components/modals/exportTranscriptmodal";
import {
  ACM,
  ajustAnnotandFadeAfterZoom,
  changeSpeakerTrack,
  changeVolumeForRegion,
  create_UUID,
  disableIconSpacingButtons,
  enableIconSpacingButtons,
  findClosestAnnotationIndex,
  getCurrentFeatureWiseCount,
  handleHorizontalScroll,
  handlePrint,
  handleZoomLevel,
  hideZoomControls,
  loadSlickSliders,
  renderFade,
  renderFlag,
  resetAnnotation,
  saveState,
  scrollToSfx,
  sendOperation,
  updateCurrentTime,
  toggleSpeakerImgSrc,
  restThings,
  verticalScrollToView,
} from "../../../../utils/utils";
import { convertTimeToPixel } from "../../../../utils/utils";
import { sfxModalSliceActions } from "../../../../redux/slice/SfxModalSlice";
import { showStatText } from "../../../../utils/utils";
import { operationListSliceActions } from "../../../../redux/slice/OperationListSlice";
import { addOperationsToDB } from "../../../../utils/indexedDButils";
import { projectDataSliceActions } from "../../../../redux/slice/ProjectDataSlice";
import { AdjustAnnotationActions } from "../../../../redux/slice/RestoreAnnotationofCutSlice";
import { showMessage } from "../../../../utils/koolio-workspace";
import { setSfxDragAndDropActions } from "../../../../redux/slice/SfxDragAndDropSlice";
import { soloListActions } from "../../../../redux/slice/soloenable";
import { annotationListActions } from "../../../../redux/slice/AnnotationListSlice";
import MultiselectReplaceModal from "../../../modals/ShowReplaceModal";
import TranscriptDrivenTour from "../../../tours/TranscriptDrivenTour";
import useBootstrapTooltips from "../../../../hooks/useBootstrapTooltips";
let soloaply = false;
let zoomvalue = 0;
let zoomstep = 0;

const PlaylistArea = (props) => {
  const dispatch = useDispatch();
  const [showModalFlag, setShowModalFlag] = useState(false);
  const isChecked = props.isChecked;
  const setIsChecked = props.setIsChecked;
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [firstResultDisplayed, setFirstResultDisplayed] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [selectedWords, setSelectedWords] = useState([]);
  const [showModalsFlag, setShowModalsFlag] = useState(false);
  const [dragStartIndex, setDragStartIndex] = useState(null);
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
  const showModal = useSelector((state) => state.showSfxModal.showModal);
  const clickedAnnot = useSelector((state) => state.showSfxModal.clickedAnnot);
  const loaderValue = useSelector((state) => state.playlistLoader.showLoader);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [speakerVolumeLevel, setSpeakerVolumeLevel] = useState(5.0);
  const [sfxVolumeLevel, setSfxVolumeLevel] = useState(5.0);
  let previousSpeakerImgSrc = "";

  const closeModal = () => {
    setShowModalFlag(false);
    dispatch(sfxModalSliceActions.updateSfxModal({ showModal: false }));
  };

  const mode = useSelector((state) => state.viewEditMode.mode);
  const idsToDisable = [
    "add-spk-sfx-tracks",
    "spk-volume-alt-icon",
    "sfx-volume-alt-icon",
  ];
  const elementsToHide = document.querySelectorAll("[id*='fade_btn_']");

  useEffect(() => {
    for (const id of idsToDisable) {
      const element = document.getElementById(id);
      if (element) {
        element.style.opacity = mode === "view" ? "0.2" : "1";
        element.style.cursor = mode === "view" ? "not-allowed" : "pointer";
      }
    }

    for (const element of elementsToHide) {
      element.style.visibility = mode === "view" ? "hidden" : "visible";
    }
  }, [mode]);
  useBootstrapTooltips(isChecked ? "transcription-area" : "playlist-area");
  useEffect(() => {
    // Hide the transcript bar when the mode switch is toggled
    const transcriptText = document.getElementById("transcript-text");
    if (transcriptText)
      if (isChecked) transcriptText.style.visibility = "hidden";
      else transcriptText.style.visibility = "visible";
  }, [isChecked]);

  useEffect(() => {
    // Update showModalFlag based on clickedAnnot
    if (clickedAnnot && showModal) {
      setShowModalFlag(true);
    }
  }, [clickedAnnot, showModal]);

  const env = useSelector((state) => state.recordBtnTrigger.env);
  useEffect(() => {
    if (env == "workspaceToRecordspace") {
      document.getElementById("wrapper").style.opacity = "0.2";
    } else if (env == "recordspaceToWorkspace") {
      document.getElementById("wrapper").style.opacity = "1";
    }
  }, [env]);

  useEffect(() => {
    const handleRightClick = async (event) => {
      event.preventDefault(); // Prevent default right-click context menu

      async function checkForSfxOpeningCondition() {
        if (ACM.checkAllowedSFXCount() !== -1) {
          const currentCount = await getCurrentFeatureWiseCount(
            "allowed_number_of_sfx",
          );
          const allowed = ACM.checkAllowedSFXCount();
          if (allowed <= currentCount) {
            showStatText(
              "You can add maximum ",
              ACM.checkAllowedSFXCount(),
              " SFX/music from koolio library in the free plan. Please upgrade your account",
            );
            return 0;
          }
          return 1;
        }
      }
      let check = await checkForSfxOpeningCondition();
      if (check === 0) return;
      if (store.getState().viewEditMode.mode === "edit" && check !== 0) {
        setShowModalFlag(true);
      } else if (store.getState().viewEditMode.mode === "view") {
        setShowModalFlag(false);
        showStatText("operation not permitted in view mode");
      }
      // console.log("Right-click detected");
    };

    const waveformContainer = document.getElementById("waveform"); // Get the waveform container element
    const sfxareaContainer = document.getElementById("sfxarea"); // Get the sfxarea container element

    if (!isChecked) {
      waveformContainer.addEventListener("contextmenu", handleRightClick); // Add event listener for right-click
      sfxareaContainer.addEventListener("contextmenu", handleRightClick); // Add event listener for right-click
    }

    return () => {
      if (!isChecked) {
        waveformContainer.removeEventListener("contextmenu", handleRightClick); // Clean up event listener on component unmount
        sfxareaContainer.removeEventListener("contextmenu", handleRightClick); // Clean up event listener on component unmount
      }
    };
  }, [isChecked]);

  useEffect(() => {
    if (isChecked) {
      // If checked, hide the playlist area and show the transcription area
      document.getElementById("playlistarea").style.display = "none";
      // document.getElementById("transcription-area").style.display = "block";

      // Hide the transcript text when the transcription area is displayed
      if (document.getElementById("transcript"))
        document.getElementById("transcript").style.display = "none";
    } else {
      // If unchecked, show the playlist area and hide the transcription area
      document.getElementById("playlistarea").style.display = "block";
      // document.getElementById("transcription-area").style.display = "none";

      // Show the transcript text when the playlist area is displayed
      if (document.getElementById("transcript"))
        document.getElementById("transcript").style.display = "block";
    }
  }, [isChecked]);

  let resultStat = useSelector((state) => state.projectData);
  let annotationList = store.getState().annotationList.annotationList;

  const handleWaveSurferClick = (waveSurferObj, type = "wav") => {
    hideZoomControls();
    let multiSelectedWords = store.getState().multiSelectwordObject.wordsObject;
    multiSelectedWords.forEach((annot) => {
      const annotationDiv = document.getElementById(`${annot.id}_div`);
      if (annotationDiv) {
        annotationDiv.classList.remove("clicked-annot");
        annotationDiv.style.transform = "scale(1.0)";
      }
    });

    dispatch(
      clickedRegionActions.uploadSelectedRegion({ payload: { type: type } }),
    );
    dispatch(
      multiSelectWordActions.updateMultiSelectWordList({ wordsObject: [] }),
    );

    const regionaudio = props.wavesurferObj.regions.list;
    const regionSFX = props.wavesurferObjSFX.regions.list;
    const regionG = props.wavesurferObjGlobal.regions.list;

    for (const regionKey in regionaudio) {
      regionaudio[regionKey].remove();
    }
    for (const regionKey in regionG) {
      regionG[regionKey].remove();
    }
    for (const regionKey in regionSFX) {
      regionSFX[regionKey].remove();
    }
    resetAnnotation();
    const timer = setTimeout(() => {
      const clickedTime = waveSurferObj.getCurrentTime();
      updateCurrentTime(clickedTime);
      // highlight transcription mode annotation
      const index = findClosestAnnotationIndex(clickedTime, annotationList);
      const annot = annotationList[index];
      // console.log(annot);
      if (annot) {
        changeSpeakerTrack(annot, annotationList);

        const transcriptionModeElem = document.getElementById(
          `fullTranscript-workspace`,
        );
        const transcriptionModeAnnot = document.getElementById(
          `${annot.id}_tspan`,
        );
        verticalScrollToView(transcriptionModeAnnot, transcriptionModeElem);
      }

      if (props.wavesurferObjSFX) {
        props.wavesurferObjSFX.seekTo(
          clickedTime / waveSurferObj.getDuration(),
        );
      }
      if (props.wavesurferObjGlobal) {
        if (type == "global") {
          let seektime = convertTimeToPixel(clickedTime);
          handleHorizontalScroll(seektime);
        }
        props.wavesurferObjGlobal.seekTo(
          clickedTime / props.wavesurferObjGlobal.getDuration(),
        );
      }
      if (props.wavesurferObj) {
        props.wavesurferObj.seekTo(
          clickedTime / props.wavesurferObj.getDuration(),
        );
      }
    }, 5);

    return () => clearTimeout(timer);
  };

  const revealAddTrackSliderTranscript = () => {
    if (store.getState().viewEditMode.mode === "view") {
      showStatText("operation is not permitted in view mode");
      return;
    }

    hideZoomControls();

    const addTracksSliderTranscript = document.getElementById(
      "add-tracks-slider-transcript",
    );
    if (!addTracksSliderTranscript) return;

    const addTracksOperationsContainerTranscript = document.querySelector(
      "#add-tracks-slider-transcript > div",
    );
    if (!addTracksOperationsContainerTranscript) return;

    const addSpkSfxTranscript = document.getElementById(
      "add-spk-sfx-tracks-transcript",
    );
    if (!addSpkSfxTranscript) return;

    addTracksSliderTranscript.style.visibility = "visible";
    setTimeout(() => {
      addTracksSliderTranscript.style.opacity = "1";
      addTracksOperationsContainerTranscript.style.top = "50%";
      addSpkSfxTranscript.style.rotate = "45deg";
    }, 100);
  };

  const hideAddTrackSliderTranscript = () => {
    const addTracksSliderTranscript = document.getElementById(
      "add-tracks-slider-transcript",
    );
    if (!addTracksSliderTranscript) return;

    const addTracksOperationsContainerTranscript = document.querySelector(
      "#add-tracks-slider-transcript > div",
    );
    if (!addTracksOperationsContainerTranscript) return;

    const addSpkSfxTranscript = document.getElementById(
      "add-spk-sfx-tracks-transcript",
    );
    if (!addSpkSfxTranscript) return;

    addSpkSfxTranscript.style.rotate = "0deg";

    setTimeout(
      () => (addTracksSliderTranscript.style.visibility = "hidden"),
      100,
    );
    addTracksSliderTranscript.style.opacity = "0";

    addTracksOperationsContainerTranscript.style.top = "40%";
  };

  const resetAddTrackSlider = () => {
    if (store.getState().viewEditMode.mode === "view") {
      showStatText("operation is not permitted in view mode");
      return;
    }

    hideZoomControls();

    // Implement your resetAddTrackSlider functionality here
    const addTracksSlider = document.getElementById("add-tracks-slider");
    const addSpkSfx = document.getElementById("add-spk-sfx");

    if (!addTracksSlider || !addSpkSfx) return;

    const addTracksLeft = addTracksSlider.style.left || "-300%";

    const addSpkSfxRotation = addSpkSfx.style.rotate || "0deg";
    const addSpkSfxMarginLeft = addSpkSfx.style.marginLeft || "47.5%";

    addTracksSlider.style.left = addTracksLeft === "-300%" ? "0%" : "-300%";

    addTracksSlider.style.left = addSpkSfx.style.rotate =
      addSpkSfxRotation === "0deg" ? "45deg" : "0deg";
    addSpkSfx.style.marginLeft =
      addSpkSfxMarginLeft === "47.5%" ? "74.5%" : "47.5%";
  };

  // 	let curr_word_list=store.getState().multiSelectwordObject.wordsObject

  // 	let currentsegment
  // 	if(type == 'SFX'){
  // 		currentsegment=props.wavesurferObjSFX
  // 	  }
  // 	  else{
  // 		currentsegment=props.wavesurferObj
  // 	  }
  // 	  if (!isChecked) {
  // 		const volCtrlMenu = document.getElementById('vol-ctrl-menu');
  // 		if (volCtrlMenu.style.visibility === 'hidden') {
  // 			volCtrlMenu.style.visibility = 'visible';
  // 		} else {
  // 			volCtrlMenu.style.visibility = 'hidden';
  // 		}
  // 	} else {
  // 		const volCtrlMenuTranscription = document.getElementById('vol-ctrl-menu-transcription');

  // 		if (volCtrlMenuTranscription.style.visibility === 'hidden') {
  // 			volCtrlMenuTranscription.style.visibility = 'visible';
  // 		} else {
  // 			volCtrlMenuTranscription.style.visibility = 'hidden';
  // 		}

  // 	}
  // 	// const region = props.wavesurferObj.regions.list[Object.keys(currentsegment.regions.list)[0]];
  // 	// document.getElementById('volume').addEventListener('input', function (e) {
  // 	// 	var volumeValue = e.target.value / 100;
  // 	// 	currentsegment.setVolume(volumeValue);
  // 	// });
  // 	const volumeSlider = document.getElementById('volume');
  // if (volumeSlider && currentsegment) {
  //     volumeSlider.addEventListener('input', function (e) {
  //         var volumeValue = e.target.value / 100;
  //         currentsegment.setVolume(volumeValue);
  //     });
  // }

  // // Implement your volume control menu functionality here
  // };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const volumeControlSpk = document.getElementById("sub-vol-ctrl-spk");
      const volumeControlSfx = document.getElementById("sub-vol-ctrl-sfx");
      const volumeControlTranscript = document.querySelector(
        ".sub-vol-ctrl-transcript",
      );

      if (volumeControlSpk && !volumeControlSpk.contains(event.target)) {
        if (volumeControlSpk.style.visibility === "visible")
          volumeControlSpk.style.visibility = "hidden";
      }

      if (volumeControlSfx && !volumeControlSfx.contains(event.target)) {
        if (volumeControlSfx.style.visibility === "visible")
          volumeControlSfx.style.visibility = "hidden";
      }

      if (
        volumeControlTranscript &&
        !volumeControlTranscript.contains(event.target) &&
        event.target.id !== "spk-volume-alt-icon" // Ignore the volume icon
      ) {
        if (volumeControlTranscript.style.visibility === "visible")
          volumeControlTranscript.style.visibility = "hidden";
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  let currentsegment, cc;
  const volumeControlMenu = (event, type) => {
    if (store.getState().viewEditMode.mode === "view") {
      showStatText("operation is not permitted in view mode");
      return;
    }
    hideZoomControls();
    if (type === "SFX") {
      cc = "sfx";
      currentsegment = props.wavesurferObjSFX;
    } else {
      cc = "speakers-0";
      currentsegment = props.wavesurferObj;
    }

    if (!isChecked) {
      const volCtrlMenu = document.getElementById(
        type !== "SFX" ? "sub-vol-ctrl-spk" : "sub-vol-ctrl-sfx",
      );
      if (volCtrlMenu && volCtrlMenu.style.visibility === "hidden") {
        volCtrlMenu.style.visibility = "visible";
      } else if (volCtrlMenu) {
        volCtrlMenu.style.visibility = "hidden";
      }
    } else {
      const volCtrlMenuTranscription = document.querySelector(
        ".sub-vol-ctrl-transcript",
      );
      if (
        volCtrlMenuTranscription &&
        volCtrlMenuTranscription.style.visibility === "hidden"
      ) {
        volCtrlMenuTranscription.style.visibility = "visible";
      } else if (volCtrlMenuTranscription) {
        volCtrlMenuTranscription.style.visibility = "hidden";
      }
    }

    const volumeSlider = document.querySelector(
      `#${type !== "SFX" ? "sub-vol-ctrl-spk" : "sub-vol-ctrl-sfx"} .sub-vol-ctrl-range`,
    );
    const volumeSlidertrans = document.querySelector(
      ".sub-vol-ctrl-range-transcript",
    );

    if (volumeSlider && currentsegment) {
      // Remove existing event listeners
      volumeSlider.removeEventListener("mouseup", handleVolumeSliderMouseUp);
      // Attach new event listeners
      volumeSlider.addEventListener("mouseup", handleVolumeSliderMouseUp);
    } else if (volumeSlidertrans && currentsegment) {
      // Remove existing event listeners
      volumeSlidertrans.removeEventListener(
        "mouseup",
        handleVolumeSliderMouseUp,
      );
      // Attach new event listeners
      volumeSlidertrans.addEventListener("mouseup", handleVolumeSliderMouseUp);
    }
  };

  const handleVolumeSliderMouseUp = async (e) => {
    var volumeValue = (e.target.value * 10) / 100;
    let resultStat = { ...store.getState().projectData.resultStat };
    const operationId = create_UUID();
    const region = Object.values(currentsegment.regions.list)[0];
    let params, buffer;
    if (region) {
      if (volumeValue < 0.3) volumeValue = 0.3;
      params = {
        jobname: resultStat.jobname,
        refid: operationId,
        parentRefid: resultStat.refid,
        worker: "subVolume",
        inputs: {
          startTime: region.start,
          endTime: region.end,
          gain: volumeValue * 1.8,
          selectedCustomClasses: [cc],
        },
      };
      buffer = changeVolumeForRegion(currentsegment, region, volumeValue * 1.8);
      if (cc == "sfx") resultStat.sfx_src = buffer;
      else resultStat.speakers_src = buffer;
    } else {
      params = {
        jobname: resultStat.jobname,
        refid: operationId,
        parentRefid: resultStat.refid,
        worker: "volumeChange",
        inputs: {
          customClass: cc,
          value: volumeValue * 1.8,
          selectedCustomClasses: [cc],
        },
      };
      currentsegment.setVolume(volumeValue);
    }
    resultStat.refid = operationId;
    await restThings(params, resultStat);
    // Sync the volume value with the other slider
  };

  const handleVolumeSliderTranscriptionMouseLeave = (e) => {
    let resultStat = store.getState().projectData.resultStat;
    const operationId = create_UUID();
    var volumeValue = e.target.value / 100;
    const params = {
      jobname: resultStat.jobname,
      refid: operationId,
      parentRefid: resultStat.refid,
      worker: "volumeChange",
      inputs: {
        customClass: "speakers-0",
        value: volumeValue,
        selectedCustomClasses: ["speakers-0"],
      },
    };
    currentsegment.setVolume(volumeValue);
  };

  const mute = (type) => {
    hideZoomControls();
    let currentsegment;
    if (type == "sfx") {
      currentsegment = props.wavesurferObjSFX;
    } else currentsegment = props.wavesurferObj;
    let ele = document.getElementById(type + "-mute");
    if (ele.classList.contains("active")) {
      ele.classList.remove("active");
      currentsegment.setVolume(1);
    } else {
      ele.classList.add("active");
      currentsegment.setVolume(0);
    }
  };
  const solo = (type) => {
    hideZoomControls();
    soloaply = !soloaply;
    let ele = document.getElementById(type + "-solo");
    if (ele.classList.contains("active")) {
      ele.classList.remove("active");
    } else {
      ele.classList.add("active");
    }
    props.wavesurferObj.setVolume(1);
    store.dispatch(soloListActions.updateSoloSpkCheck(soloaply));
    // Implement your solo functionality here
  };
  const creatCustomClassOperation = async (type) => {
    let operationId = create_UUID();
    let wavesurferObj = store.getState().wavesurferObj.wavsurferObj;
    let res = store.getState().projectData.resultStat;
    saveState(res, "create CC");
    let resultStat = JSON.parse(JSON.stringify(res));
    resultStat.fades = res.fades ? res.fades : [];
    resultStat.speakers_src = res.speakers_src;
    resultStat.sfx_src = res.sfx_src;
    let trackNum, name, segment;
    segment = {
      start_time: 0,
      end_time: wavesurferObj.getDuration(),
      id: create_UUID(),
    };
    let newSpeaker;
    if (type === "speakers") {
      trackNum = Object.keys(resultStat.speakers).length;
      name = "speaker " + (trackNum + 1);
      segment.name = name;
      segment.img =
        "https://ui-avatars.com/api/?name=" +
        name +
        "&background=BB417C&rounded=true&size=31&color=fff";
      segment.speaker_label = `${type}-${trackNum}`;
      let SpeakerSeg = {
        annotations: [],
        backend_filename: "",
        cueIn: 0,
        cueOut: 0,
        end_time: 0,
        fades: [],
        filename: "",
        id: create_UUID(),
        speaker_label: `speakers-${trackNum}`,
        src: [],
        start_time: 0,
        sub_pannings: [],
        sub_volumes: [],
      };
      resultStat.speakers_segments.push(SpeakerSeg);
      newSpeaker = {
        customClass: `speakers-${trackNum}`,
        gain: 1,
        id: 0,
        img:
          "https://ui-avatars.com/api/?name=" +
          name +
          "&background=BB417C&rounded=true&size=31&color=fff",
        name,
        panning: 0,
        tracktype: type,
      };
      resultStat.speakers[
        `speakers-${Object.keys(resultStat.speakers).length}`
      ] = newSpeaker;
    } else {
      const musicCount = Object.values(resultStat.sfx).filter(
        (item) => item.trackType === "music",
      ).length;
      const sfxCount = Object.values(resultStat.sfx).filter(
        (item) => item.trackType === "sfx",
      ).length;
      trackNum = (type == "music" ? musicCount : sfxCount) + 1;
      name = type == "sfx" ? "sound effects " + trackNum : "music " + trackNum;
      segment.name = name;
      segment.img =
        "https://ui-avatars.com/api/?name=" +
        name +
        "&background=BB417C&rounded=true&size=31&color=fff";
      segment.speaker_label = `${type}-${Object.values(resultStat.sfx).length}`;
      newSpeaker = {
        customClass: "sfx-" + Object.values(resultStat.sfx).length,
        gain: 1,
        id: 0,
        img:
          "https://ui-avatars.com/api/?name=" +
          name +
          "&background=BB417C&rounded=true&size=31&color=fff",
        name,
        panning: 0,
        trackType: type,
      };
      if (type == "music") {
        segment.substituteCustomClass = "music-" + trackNum;
        newSpeaker.substituteCustomClass = "music-" + trackNum;
      }
      resultStat.sfx[`sfx-${Object.values(resultStat.sfx).length}`] =
        newSpeaker;
    }
    const params = {
      jobname: resultStat.jobname,
      refid: operationId,
      parentRefid: resultStat.refid,
      worker: "createCustomClass",
      inputs: {
        trackType: type,
        segment,
      },
    };
    resultStat.refid = operationId;
    await restThings(params, resultStat);
    loadSlickSliders(resultStat);
    const newIndex =
      document.querySelectorAll("[id*='-slider-sfx']").length - 1;
    scrollToSfx(newIndex);
    setCurrentIndex(newIndex);
    isChecked ? hideAddTrackSliderTranscript() : resetAddTrackSlider();
  };
  // const closeModal = () => {
  //   setShowModalFlag(false); // Close the modal

  // };
  const closeModalExport = () => {
    setShowModalsFlag(false); // Close the modal
  };
  const [disableZoomBtn, setDisableZoomBtn] = useState(false);
  useEffect(() => {
    if (disableZoomBtn) {
      // Call the function (you can trigger this on some event or directly on window load)
      disableIconSpacingButtons();
    } else {
      enableIconSpacingButtons();
    }
  }, [disableZoomBtn]);

  async function applyZoom(newZoom, zoomType) {
    let newWidth = Math.round(props.wavesurferObj.getDuration() * newZoom);
    if (newWidth < document.getElementById("playlist").offsetWidth) {
      if (zoomType === "zoomout") {
        showMessage(
          "Zoom out limit exceeded!",
          "Audio will be moved out of the viewWidth",
          2000,
          "error",
          "workspace",
        );
        return;
      }

      newWidth = document.getElementById("playlist").offsetWidth;
      newZoom = Math.round(newWidth / props.wavesurferObj.getDuration());
    }
    setDisableZoomBtn(true);
    await new Promise((resolve) => setTimeout(resolve, 0));
    handleZoomLevel(props, newWidth, newZoom);
    ajustAnnotandFadeAfterZoom();
    setDisableZoomBtn(false);
    const clickedTime = props.wavesurferObj.getCurrentTime();
    let seektime = convertTimeToPixel(clickedTime);
    handleHorizontalScroll(seektime);
    await new Promise((resolve) => setTimeout(resolve, 0));
  }
  function zoomIn() {
    if (!zoomvalue) {
      zoomvalue = document.querySelector("#waveform").offsetWidth / 5;
    }
    if (zoomstep > 2) {
      showMessage(
        "Zoom in limit exceeded!",
        "You can only zoom in double of the original track!",
        2000,
        "error",
        "workspace",
      );
      return;
    }
    zoomvalue += 35;
    zoomstep++;
    applyZoom(zoomvalue, "zoomin");
  }

  function zoomOut() {
    if (!zoomvalue) {
      zoomvalue = document.querySelector("#waveform").offsetWidth / 5;
    }
    if (zoomstep < -2) {
      showMessage(
        "Zoom out limit exceeded!",
        "You can only zoom out half of the original track!",
        2000,
        "error",
        "workspace",
      );
      return;
    }
    zoomvalue -= 35;
    zoomstep--;
    applyZoom(zoomvalue, "zoomout");
  }

  function resetZoom() {
    let containerWidth = document.querySelector("#waveform").offsetWidth;
    let minPxPerSec = containerWidth / 5;
    zoomvalue = minPxPerSec;
    zoomstep = 0;
    applyZoom(zoomvalue, resetZoom);
    store.dispatch(
      AdjustAnnotationActions.updateAdjustAnnotation({ adjustAnnot: true }),
    );
  }

  const handleExport = () => {
    setShowModalsFlag(true);
  };

  let multiSelectedWords = [];
  const wordMultiSelect = (annotation) => {
    let val = store.getState().multiSelectedWords.isMultiselect;
    if (val) {
      multiSelectedWords = [...multiSelectedWords, annotation];

      dispatch(
        multiSelectWordActions.updateMultiSelectWordList({
          wordsObject: multiSelectedWords,
        }),
      );
    } else {
      multiSelectedWords = [annotation];

      dispatch(
        multiSelectWordActions.updateMultiSelectWordList({
          wordsObject: multiSelectedWords,
        }),
      );
    }
  };
  const multiSelectOperation = () => {
    const multiselect = store.getState().multiSelectedWords.isMultiselect;
    if (!multiselect) {
      document.getElementById("multiselect-transcript").src =
        "/image/workspace/active-icons/annotations.png";
      dispatch(multiSelectActions.updateMultiselect({ isMultiselect: true }));
    } else {
      document.getElementById("multiselect-transcript").src =
        "/image/workspace/controls/annotations.png";
      dispatch(multiSelectActions.updateMultiselect({ isMultiselect: false }));
    }
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    searchTranscript(value);
    // Clear selected words and multi-select when search term is empty
    if (!value.trim()) {
      setSelectedWords([]);
      //remove region from global
      const regionG = props.wavesurferObjGlobal.regions.list;
      for (const regionKey in regionG) {
        regionG[regionKey].remove();
      }
      dispatch(
        multiSelectWordActions.updateMultiSelectWordList({ wordsObject: [] }),
      );
      dispatch(multiSelectActions.updateMultiselect({ isMultiselect: false }));
    }
  };

  const handleAnnotationClick = (annot) => {
    const wavesurferObj = store.getState().wavesurferObj.wavsurferObj;
    const wavesurferObjSFX = store.getState().wavesurferObjSFX.wavesurferObjSFX;
    const regionaudio = wavesurferObj.regions.list;
    const regionSFX = wavesurferObjSFX.regions.list;
    for (const regionKey in regionaudio) {
      regionaudio[regionKey].remove();
    }
    for (const regionKey in regionSFX) {
      regionSFX[regionKey].remove();
    }
    wordMultiSelect(annot);
    wavesurferObj.addRegion({
      start: parseFloat(annot.start_time), // start time of the region
      end: parseFloat(annot.end_time), // end time of the region
      color: "hsla(265, 100%, 86%, 0.4)", // color of the region
    });

    const annotation = {
      id: `${annot.id}`,
      start: parseFloat(annot.start_time),
      end: parseFloat(annot.end_time),
      data: annot,
      drag: true,
      resize: true,
      // color: annot.color === "#00FFFFFF" ? "blueviolet" : "#00FFFFFF", // Toggle color on click
      color: "bluevoilet",
    };
    handleHorizontalScroll(convertTimeToPixel(annot.start_time));
    //     updatedSelectedWords.push(annot);
    // setClickedIndex(annot.id);
    wordMultiSelect(annotation);
  };
  const handleSelectAll = () => {
    const updatedSelectedWords = [];

    searchResults.forEach((annot) => {
      const annotation = {
        id: `${annot.id}`,
        start: parseFloat(annot.start_time),
        end: parseFloat(annot.end_time),
        data: annot,
        drag: true,
        resize: true,
      };
      updatedSelectedWords.push(annotation);
      wordMultiSelect(annotation); // Call wordMultiSelect for each word
    });

    setSelectedWords(updatedSelectedWords);
    dispatch(
      multiSelectWordActions.updateMultiSelectWordList({
        wordsObject: updatedSelectedWords,
      }),
    );
    multiSelectOperation();
  };

  const handleMouseDown = (word) => {
    setDragStartIndex(word.id);
  };

  const handleMouseUp = () => {
    setDragStartIndex(null);
  };

  const revealZoomControls = () => {
    const zoomControls = document.getElementById("zoom-control");
    const revealZoomControls = document.getElementById("reveal-zoom-controls");
    const zoomWrapper = document.getElementById("zoom-wrapper");
    if (!zoomControls) return;
    if (!revealZoomControls) return;
    if (!zoomWrapper) return;

    revealZoomControls.style.visibility = "hidden";
    revealZoomControls.style.opacity = "0";
    zoomWrapper.style.visibility = "visible";
    zoomWrapper.style.opacity = "1";
    zoomControls.style.visibility = "visible";
    zoomControls.style.opacity = "1";

    showStatText("Tip: Click outside to close the zoom controls!");
  };

  const searchTranscript = (term) => {
    if (!term) {
      setSearchResults([]);
      setSearchPerformed(false);
      return;
    }

    const transcriptWords = [];
    resultStat.resultStat.speakers_segments.forEach((speaker) =>
      transcriptWords.push(...speaker.annotations),
    );

    const filteredResults = transcriptWords
      .filter((word, index) => {
        // Split the content into words
        const words = word.content.split(" ");

        // Check if any word in the content matches the search term as a whole word
        const match = words.some((w) => {
          const termUpperCase = term.toUpperCase();
          const wordUpperCase = w.toUpperCase();
          return wordUpperCase === termUpperCase;
        });

        return match;
      })
      .map((result, index) => {
        return { ...result, index };
      });

    // Filter the results again to ensure only exact matches are included
    const exactMatches = filteredResults.filter((word) =>
      word.content.toUpperCase().includes(term.toUpperCase()),
    );

    setSearchResults(exactMatches);
    setSearchPerformed(true);
    setCurrentMatchIndex(0);
    setFirstResultDisplayed(false);
    if (exactMatches.length > 0) {
      handleHorizontalScroll(convertTimeToPixel(exactMatches[0].start_time));
      const transcriptionSectionAnnot = document.getElementById(
        `${exactMatches[0]?.id}_tspan`,
      );
      const transcriptionModalElem = document.getElementById(
        `fullTranscript-workspace`,
      );
      verticalScrollToView(transcriptionSectionAnnot, transcriptionModalElem);
      const wavesurferObj = store.getState().wavesurferObj.wavsurferObj;
      const wavesurferObjSFX =
        store.getState().wavesurferObjSFX.wavesurferObjSFX;
      const regionaudio = wavesurferObj.regions.list;
      const regionSFX = wavesurferObjSFX.regions.list;
      for (const regionKey in regionaudio) {
        regionaudio[regionKey].remove();
      }
      for (const regionKey in regionSFX) {
        regionSFX[regionKey].remove();
      }
      // wavesurferObj.addRegion({
      //   start: parseFloat(exactMatches[0].start_time), // start time of the region
      //   end: parseFloat(exactMatches[0].end_time), // end time of the region
      //   color: "hsla(265, 100%, 86%, 0.4)", // color of the region
      // });
    }
  };

  const handleNextClick = () => {
    if (searchResults.length === 0) {
      return;
    }
    const nextIndex = (currentMatchIndex + 1) % searchResults.length;
    setCurrentMatchIndex(nextIndex);
    const nextWord = searchResults[nextIndex];

    const transcriptionModeAnnot = document.getElementById(
      `${nextWord.id}_tspan`,
    );
    const transcriptionModeElem = document.getElementById(
      `fullTranscript-workspace`,
    );
    verticalScrollToView(transcriptionModeAnnot, transcriptionModeElem);
    handleHorizontalScroll(convertTimeToPixel(nextWord.start_time));
    highlightWord(nextWord);
  };

  const handlePreviousClick = () => {
    if (searchResults.length === 0) {
      return;
    }
    const prevIndex =
      (currentMatchIndex - 1 + searchResults.length) % searchResults.length;
    setCurrentMatchIndex(prevIndex);
    const prevWord = searchResults[prevIndex];
    const transcriptionModeAnnot = document.getElementById(
      `${prevWord.id}_tspan`,
    );
    const transcriptionModeElem = document.getElementById(
      `fullTranscript-workspace`,
    );
    verticalScrollToView(transcriptionModeAnnot, transcriptionModeElem);
    handleHorizontalScroll(convertTimeToPixel(prevWord.start_time));
    highlightWord(prevWord);
  };

  const highlightWord = (word) => {
    const wavesurferObj = store.getState().wavesurferObj.wavsurferObj;
    const wavesurferObjSFX = store.getState().wavesurferObjSFX.wavesurferObjSFX;
    const regionaudio = wavesurferObj.regions.list;
    const regionSFX = wavesurferObjSFX.regions.list;
    for (const regionKey in regionaudio) {
      regionaudio[regionKey].remove();
    }
    for (const regionKey in regionSFX) {
      regionSFX[regionKey].remove();
    }
    wavesurferObj.addRegion({
      start: parseFloat(word.start_time),
      end: parseFloat(word.end_time),
      color: "hsla(265, 100%, 86%, 0.4)",
    });
  };

  // const handleMouseEnter = (word) => {
  //     if (dragStartIndex !== null) {
  //         const startIndex = Math.min(dragStartIndex, word.id);
  //         const endIndex = Math.max(dragStartIndex, word.id);
  //         const selected = word.slice(startIndex, endIndex + 1);
  //         setSelectedWords(selected);
  //     }
  // };

  const [clickedWordId, setClickedWordId] = useState(null);
  const renderWord = (word) => {
    const isFirstExactMatch =
      searchResults.length > 0 && word.id === searchResults[0]?.id;

    const isSelected = selectedWords.some(
      (selected) => selected.id === word.id,
    );
    const isWordInSearch = word.content
      .toUpperCase()
      .includes(searchTerm.toUpperCase());
    const isWholeWordMatch =
      word.content.toUpperCase() === searchTerm.toUpperCase();
    const isCurrentMatch = searchResults[currentMatchIndex]?.id === word.id;
    const isMultiselectActive =
      store.getState().multiSelectedWords.isMultiselect;
    const isSelectedAndMultiselectActive = isSelected && isMultiselectActive;
    const handleclickword = (clickedId) => {
      setClickedWordId(clickedId);
      handleAnnotationClick(word);
    };

    const handleRightClick = (e, annot) => {
      e.preventDefault();
      store.dispatch(
        sfxModalSliceActions.updateSfxModal({
          showModal: true,
          clickedAnnot: annot.content,
        }),
      );
    };

    return (
      <span
        key={word.id}
        id={`${word.id}_tspan`}
        onClick={() => handleclickword(word.id)}
        onContextMenu={(e) => handleRightClick(e, word)} // Add this line
        className={clickedWordId === word.id ? "clicked-annot" : ""}
        style={{
          cursor: "pointer",
          color: word.is_silenced
            ? "#818181" // Silenced word color
            : isSelected
              ? "blueviolet"
              : isWholeWordMatch
                ? "blueviolet"
                : "white",
          border:
            isSelectedAndMultiselectActive ||
            isCurrentMatch ||
            (isFirstExactMatch && currentMatchIndex === null)
              ? "1px solid blueviolet"
              : "none",
          boxShadow:
            isSelectedAndMultiselectActive ||
            isCurrentMatch ||
            (isFirstExactMatch && currentMatchIndex === null)
              ? "0px 0px 5px violet"
              : "none",
          padding:
            isSelectedAndMultiselectActive ||
            isCurrentMatch ||
            (isFirstExactMatch && currentMatchIndex === null)
              ? "2px"
              : "none",
        }}
      >
        {word.content}
      </span>
    );
  };

  const handleDrag = (e) => {
    if (!store.getState().viewEditMode.mode === "edit") {
      showStatText("operation not permitted in view mode");
    }
  };
  const handleSfxDrop = (e) => {
    e.preventDefault();

    if (store.getState().viewEditMode.mode !== "edit") {
      showStatText("Operation not permitted in view mode");
      return;
    }

    try {
      const wavesurfer = props.wavesurferObjSFX;
      const wrapper = e.target;

      // Get the click position relative to the waveform container
      const rect = wrapper.getBoundingClientRect();
      const clickPosition = e.clientX - rect.left;

      // Get the width of the waveform container
      const waveformWidth = rect.width;

      // Calculate the time based on the click position relative to the total duration
      const duration = wavesurfer.getDuration();
      const startTime = (clickPosition / waveformWidth) * duration;

      // Round to 3 decimal places for consistent precision
      const roundedStartTime = parseFloat(startTime.toFixed(3));

      // Validate the calculated time
      if (roundedStartTime < 0 || roundedStartTime > duration) {
        console.warn(
          "Calculated time is outside valid range:",
          roundedStartTime,
        );
        return;
      }

      // Update the state
      dispatch(setSfxDragAndDropActions.setStartTime(roundedStartTime));
      dispatch(setSfxDragAndDropActions.setDrag(true));
      dispatch(setSfxDragAndDropActions.setDrop(true));
    } catch (error) {
      console.error("Error in handleSfxDrop:", error);
    }
  };
  const handleSfxDragOver = (e) => {
    e.preventDefault();
  };
  // TODO: Need to come up with a better approach
  useEffect(() => {
    setTimeout(() => {
      try {
        const resultStat = store.getState().projectData.resultStat;
        loadSlickSliders(resultStat);
      } catch (error) {
        console.error("Error in loadSlickSliders", error);
      }
    }, 500);
  }, [isChecked]);
  const [showReplaceIcon, setShowReplaceIcon] = useState(false);
  const [showReplaceModal, setshowReplaceModal] = useState(false);

  function closeReplaceModal() {
    setshowReplaceModal(false);
  }
  function handleReplaceTranscript() {
    let inputText = document
      .getElementById("input-search-transcript-workspace")
      .value.trim();
    let replaceText = document
      .getElementById("input-replace-transcript-workspace")
      .value.trim();
    if (replaceText.length == 0) {
      showStatText("Enter replace text...");
      return;
    }
    if (searchResults.length > 0) {
      setshowReplaceModal(true);
    } else {
      showStatText("No matched transcriptions...");
      setShowReplaceIcon(false);
    }
  }
  function handleReplaceModalYes() {
    setSearchResults([]);
    closeReplaceModal();
    let inputText = document
      .getElementById("input-search-transcript-workspace")
      .value.trim();
    let replaceText = document
      .getElementById("input-replace-transcript-workspace")
      .value.trim();
    let resultStat = store.getState().projectData.resultStat;

    let newResultStat = JSON.parse(JSON.stringify(resultStat));
    let newResultStatForUndoRedo = JSON.parse(JSON.stringify(resultStat));
    let speakerSegmentTrackForSelectedWords = [];
    let items = [];
    searchResults.map((word, ind) => {
      let indexID = resultStat.speakers_segments.findIndex((segment) => {
        return segment.annotations.some((annot) => annot.id === word.id);
      });

      speakerSegmentTrackForSelectedWords.push(
        resultStat.speakers_segments[indexID].speaker_label,
      );
      items[ind] = {
        trackId: resultStat.speakers_segments[indexID].id,
        start: parseFloat(searchResults[ind].start_time),
        end: parseFloat(searchResults[ind].end_time),
        wordid: searchResults[ind].id,
        word: searchResults[ind].content,
      };
      //change the content in newResultstat
      newResultStat.speakers_segments[indexID].annotations.forEach(
        (annot, index) => {
          if (annot.id === word.id) {
            let newAnnot = { ...annot, content: replaceText };
            if (document.getElementById(annot.id + "_div")) {
              document.getElementById(annot.id + "_div").textContent =
                replaceText;
            }
            if (document.getElementById(annot.id + "_span")) {
              document.getElementById(annot.id + "_span").textContent =
                replaceText;
            }
            if (document.getElementById(annot.id + "_tspan")) {
              document.getElementById(annot.id + "_tspan").textContent =
                replaceText;
            }
            newResultStat.speakers_segments[indexID].annotations[index] =
              newAnnot;
          }
        },
      );
    });
    const uniqueTracks = [
      ...new Set(speakerSegmentTrackForSelectedWords.map(JSON.stringify)),
    ].map(JSON.parse);
    let operationId = create_UUID();
    let params = {
      jobname: resultStat.jobname,
      refid: operationId,
      parentRefid: resultStat.refid,
      worker: "multiselect-replace",
      inputs: {
        items,
        selectedCustomClasses: [...uniqueTracks],
        customClass: "speakers-0",
        searchWord: document
          .getElementById("input-search-transcript-workspace")
          .value.trim(),
        replaceWord: document
          .getElementById("input-replace-transcript-workspace")
          .value.trim(),
      },
    };
    newResultStatForUndoRedo.params = params;
    saveState(newResultStatForUndoRedo, "multiselect-replace");
    newResultStat.sfx_src = resultStat.sfx_src;
    newResultStat.refid = operationId;
    newResultStat.speakers_src = resultStat.speakers_src;
    newResultStat.fades = resultStat.fades ? resultStat.fades : [];

    // annotUIshiftSub(newResultStat, props);
    setShowReplaceIcon(false);
    restThings(params, newResultStat);
  }
  function handleReplaceIcon() {
    if (store.getState().viewEditMode.mode == "view") {
      showStatText("operation not permitted in view mode");
      return;
    }
    if (
      document.getElementById("input-search-transcript-workspace").value
        .length > 0
    ) {
      setShowReplaceIcon((prev) => !prev);
    } else {
      showStatText("please input text to replace");
      return;
    }
  }

  return (
    <>
      <ToggleButton isChecked={isChecked} setIsChecked={setIsChecked} />
      <TranscriptDrivenTour isChecked={isChecked} />
      <div className="wrapper" id="wrapper" style={{ visibility: "hidden" }}>
        <div>
          <div id="playlistarea">
            <div id="playlist-area" className="playlist-area d-flex">
              <div
                className="add-track-controls col-1-mod"
                style={{ position: "relative" }}
              >
                <div
                  id="add-tracks-slider"
                  style={{
                    position: "absolute",
                    // display: "none",
                    fontWeight: 900,
                    top: 0,
                  }}
                >
                  <div
                    className="add-spk-sfx transcriptSearch-img transcriptDrivenSearchOptionSpacing-alt"
                    id="add-spk-sfx-transcript-slider"
                    style={{
                      zIndex: 0,
                      position: "absolute",
                      left: "45%",
                      top: "-7%",
                      display: "none",
                    }}
                    onClick={resetAddTrackSlider}
                  >
                    <img
                      src="static/img/add_tracks.svg"
                      className="add-spk-sfx-tracks tt"
                      data-bs-placement="top"
                      title="add track"
                      id="add-spk-sfx-tracks-transcript-modal"
                      alt=""
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </div>

                  <div
                    id="add-tracks-slider-speakers"
                    className="row sliders-track text-center add-track"
                    onClick={() => creatCustomClassOperation("speakers")}
                  >
                    SPEAKER
                  </div>
                  <div
                    id="add-tracks-slider-sfx"
                    className="row sliders-track text-center add-track"
                    onClick={() => creatCustomClassOperation("sfx")}
                  >
                    SFX
                  </div>
                  <div
                    id="add-tracks-slider-music"
                    className="row sliders-track text-center add-track"
                    onClick={() => creatCustomClassOperation("music")}
                  >
                    MUSIC
                  </div>
                </div>
              </div>
              <div
                id="track-controls"
                className="spk-track-controls col-1-mod"
                style={{ position: "relative" }}
              >
                <div
                  id="speakers-sliders"
                  className="speakers-sliders track-spk-slider"
                  style={{ position: "relative" }}
                >
                  {/* Content for 'speakers-sliders' goes here */}
                </div>

                <div
                  className="add-spk-sfx"
                  id="add-spk-sfx"
                  style={{ zIndex: 0 }}
                  onClick={resetAddTrackSlider}
                >
                  <img
                    src="/image/add_tracks.svg"
                    className="add-spk-sfx-tracks tt"
                    data-bs-placement="bottom"
                    title="add track"
                    id="add-spk-sfx-tracks"
                    alt=""
                  />
                </div>

                <div id="sfx-slider-cont" className="sfx-slider-cont d-flex">
                  <img
                    className="prev"
                    id="sfx-prev"
                    src="/image/prev_slider.svg"
                    alt=""
                    onClick={() => {
                      if (currentIndex > 0) setCurrentIndex((prev) => prev - 1);
                      scrollToSfx(currentIndex);
                    }}
                    // style={{ visibility: "hidden" }}
                  />

                  <div id="sfx-sliders" className="sfx-sliders ">
                    {/* Content for 'sfx-sliders' goes here */}
                  </div>

                  <img
                    className="next"
                    id="sfx-next"
                    src="/image/next_slider.svg"
                    onClick={() => {
                      if (
                        currentIndex <
                        Array.from(
                          document.querySelectorAll("[id*='-slider-sfx']"),
                        ).length -
                          1
                      )
                        setCurrentIndex((prev) => prev + 1);
                      scrollToSfx(currentIndex);
                    }}
                    alt=""
                    // style={{ visibility: "hidden" }}
                  />
                </div>
              </div>
              {/*style={{ borderLeft: 'double #E2522B', borderRight: 'double #E2522B' }}*/}
              {/*style={{ marginBottom: '7vh',borderLeft: 'double #E2522B', borderRight: 'double #E2522B' , backgroundColor: 'rgba(255,0,0,0)',  zIndex: '15' }}*/}
              {/*style={{  marginTop: '58px',borderLeft: 'double #E2522B', borderRight: 'double #E2522B' }}*/}
              <div className="post col-8-mod">
                <div className="post-content" id="post-content">
                  <div
                    id="playlist"
                    style={{
                      borderLeft: "double #E2522B",
                      borderRight: "double #E2522B",
                    }}
                  >
                    <div
                      className="playlist"
                      style={{ overflow: "hidden", position: "relative" }}
                    >
                      <div
                        className="playlist-wrapper"
                        id="playlist-wrapper"
                        style={{ overflowX: "auto", overflowY: "hidden" }}
                      >
                        <div
                          ref={props.wavesurferRef}
                          onClick={() =>
                            handleWaveSurferClick(props.wavesurferObj, "wav")
                          }
                          id="waveform"
                        >
                          <div id="speakerPulsate"></div>
                          <div id="sfxPulsate"></div>
                        </div>
                        <div
                          id="transcription-wrapper"
                          style={{ overflow: "visible", position: "relative" }}
                        ></div>
                        <div
                          ref={props.wavesurferRefSFX}
                          onClick={() =>
                            handleWaveSurferClick(props.wavesurferObjSFX, "SFX")
                          }
                          id="sfxarea"
                          style={{ marginTop: "5.2vh", marginBottom: "0" }}
                        ></div>
                        <div
                          draggable
                          onDragStart={(e) => {
                            handleDrag(e);
                          }}
                          onDragOver={(e) => {
                            handleSfxDragOver(e);
                          }}
                          onDrop={(e) => {
                            handleSfxDrop(e);
                          }}
                          id="transcription-wrapper-sfx"
                          style={{
                            overflow: "visible",
                            position: "relative",
                            visibility: "visible",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="spk-vol-ctrl"
                className="spk-vol-ctrl col-2-mod"
                style={{ position: "relative" }}
              >
                {/* <div
                  className="vol-ctrl-menu"
                  id="vol-ctrl-menu"
                  style={{
                    visibility: "visible",
                    backdropFilter: "blur(10px)",
                  }}
                >
                  <div
                    id="transciptMuteAndSolo"
                    className="d-none justify-content-center"
                    style={{ position: "absolute", top: "-8%", width: "100%" }}
                  >
                    <div
                      id="spk-mute-transcript"
                      className="transcriptDrivenSearchOptionSpacing-alt speakers-mute muted text-white tt"
                      data-bs-placement="bottom"
                      title="mute"
                      onClick={mute}
                    >
                      M
                    </div>
                    <div
                      id="spk-solo-transcript"
                      className="transcriptDrivenSearchOptionSpacing speakers-solo soloed text-white tt"
                      data-bs-placement="bottom"
                      title="solo"
                      onClick={solo}
                    >
                      S
                    </div>
                  </div>
                  <img
                    src="image/workspace/controls/knob-outline.svg"
                    className="knob-outline"
                    alt=""
                  />
                  <div
                    className="knob-clean"
                    data-fgColor="#3ff"
                    data-bgColor="#111"
                    data-type="pan"
                  ></div>
                  <div className="amplitude-container">
                    <div
                      id="amplitude-way-one"
                      className="amplitude-way-one"
                    ></div>
                    <div
                      id="amplitude-way-two"
                      className="amplitude-way-two"
                    ></div>
                  </div>
                  <div
                    id="vol-ctrl-track-cont"
                    style={{
                      color: "#c4c4c4",
                      position: "absolute",
                      left: "50%",
                      transform: "translateX(-50%)",
                      whiteSpace: "nowrap",
                      bottom: "0%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span id="vol-ctrl-track"></span>
                  </div>
                  <div className="volume-container" id="volume-slider">
                    <input
                      type="range"
                      id="volume"
                      name="volume"
                      min="0"
                      max="100"
                      step="10"
                      orient="vertical"
                      class="volume-range"
                    />
                  </div>
                </div> */}

                {/* For Speaker */}
                <div id="sub-vol-ctrl-spk" className="sub-vol-ctrl">
                  <div className="sub-vol-ctrl-cont">
                    <input
                      type="range"
                      name="volume"
                      min="0.0"
                      max="10.0"
                      step="0.1"
                      value={speakerVolumeLevel}
                      onChange={(e) => setSpeakerVolumeLevel(e.target.value)}
                      className="sub-vol-ctrl-range"
                    />
                    <div className="sub-vol-ctrl-value">
                      {parseFloat(speakerVolumeLevel).toFixed(1)}
                    </div>
                  </div>
                </div>

                {/* For SFX */}
                <div id="sub-vol-ctrl-sfx" className="sub-vol-ctrl">
                  <div className="sub-vol-ctrl-cont">
                    <input
                      type="range"
                      name="volume"
                      min="0.0"
                      max="10.0"
                      step="0.1"
                      value={sfxVolumeLevel}
                      onChange={(e) => setSfxVolumeLevel(e.target.value)}
                      className="sub-vol-ctrl-range"
                    />
                    <div className="sub-vol-ctrl-value">
                      {parseFloat(sfxVolumeLevel).toFixed(1)}
                    </div>
                  </div>
                </div>

                <div style={{ width: "12vw" }}>
                  <div className="speaker-volume ctrl-vol" id="speaker-volume">
                    <div className="d-flex vol-ctrl-elem">
                      <div
                        id="menu-vol-ctrl-spk"
                        className="d-flex"
                        onClick={(event) =>
                          volumeControlMenu(event, "speakers")
                        }
                      >
                        <img
                          src="/image/volume-ctrl.svg"
                          id="spk-volume-alt-icon"
                          className="volume-alt-icon"
                          alt=""
                          style={{
                            width: "1rem",
                          }}
                        />
                      </div>

                      <div
                        id="spk-mute"
                        className="speakers-mute muted text-white tt"
                        data-bs-placement="bottom"
                        title="mute"
                        onClick={() => mute("spk")}
                        style={{ fontSize: "1.25vw" }}
                      >
                        M
                      </div>

                      <div
                        id="spk-solo"
                        className="speakers-solo soloed text-white tt"
                        data-bs-placement="bottom"
                        title="solo"
                        onClick={() => solo("spk")}
                        style={{ fontSize: "1.25vw" }}
                      >
                        S
                      </div>
                    </div>
                  </div>

                  <div id="zoom-container">
                    <div id="reveal-zoom-controls" title="reveal zoom controls">
                      <i
                        class="fa-solid fa-magnifying-glass"
                        onClick={revealZoomControls}
                        style={{ cursor: "pointer" }}
                      ></i>
                    </div>

                    <div id="zoom-wrapper" onClick={hideZoomControls}></div>

                    <div className="playlist-zoom-control" id="zoom-control">
                      {/* <div className="playlist-zoom btn-zoom-in">
                      <span
                        className="zoom-text tt"
                        data-bs-placement="bottom"
                        title="zoom in"
                        onClick={zoomIn}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          id="zoom-in"
                          src="/image/zoomIn.svg"
                          alt=""
                          style={{ width: "0.833vw", height: "1.34vh" }}
                        />
                      </span>
                    </div> */}

                      <div className="playlist-zoom btn-zoom-in disableBtnClass">
                        <span
                          className="zoom-text tt"
                          data-bs-placement="bottom"
                          title="zoom in"
                          onClick={zoomIn}
                          style={{ cursor: "pointer" }}
                        >
                          {/* <img
                          id="zoom-in"
                          src="/image/zoomIn.svg"
                          alt=""
                          style={{ width: "0.833vw", height: "1.34vh" }}
                        /> */}
                          <i
                            class="fa-solid fa-magnifying-glass-plus"
                            id="zoom-in"
                          ></i>
                        </span>
                      </div>

                      {/* <div className="playlist-zoom btn-zoom-reset">
                      <span
                        className="zoom-text tt"
                        data-bs-placement="bottom"
                        title="reset zoom"
                        style={{ cursor: "pointer" }}
                        onClick={resetZoom}
                      >
                        <img
                          id="zoom-reset"
                          src="/image/workspace/controls/reset.svg"
                          alt=""
                          style={{ width: "0.833vw", height: "1.34vh" }}
                        />
                      </span>
                    </div> */}

                      <div className="playlist-zoom btn-zoom-reset disableBtnClass">
                        <span
                          className="zoom-text tt"
                          data-bs-placement="bottom"
                          title="reset zoom"
                          style={{ cursor: "pointer" }}
                          onClick={resetZoom}
                        >
                          {/* <img
                          id="zoom-reset"
                          src="/image/workspace/controls/reset.svg"
                          alt=""
                          style={{ width: "0.833vw", height: "1.34vh" }}
                        /> */}
                          <i
                            class="fa-solid fa-rotate-left"
                            id="zoom-reset"
                          ></i>
                        </span>
                      </div>

                      {/* <div className="playlist-zoom btn-zoom-out">
                      <span
                        className="zoom-text tt"
                        data-bs-placement="bottom"
                        title="zoom out"
                        style={{ cursor: "pointer" }}
                        onClick={zoomOut}
                      >
                        <img
                          id="zoom-out"
                          src="/image/zoomOut.svg"
                          alt=""
                          style={{ width: "0.833vw", height: "1.34vh" }}
                        />
                      </span>
                    </div> */}

                      <div className="playlist-zoom btn-zoom-out disableBtnClass">
                        <span
                          className="zoom-text tt"
                          data-bs-placement="bottom"
                          title="zoom out"
                          style={{ cursor: "pointer" }}
                          onClick={zoomOut}
                        >
                          {/* <img
                          id="zoom-out"
                          src="/image/zoomOut.svg"
                          alt=""
                          style={{ width: "0.833vw", height: "1.34vh" }}
                        /> */}
                          <i
                            class="fa-solid fa-magnifying-glass-minus"
                            id="zoom-out"
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className="sfx-volume sfx-ctrl-vol disableBtnClass"
                    id="sfx-volume"
                  >
                    <div className="d-flex vol-ctrl-elem">
                      <div
                        id="menu-vol-ctrl-sfx"
                        className="d-flex"
                        onClick={(event) => volumeControlMenu(event, "SFX")}
                      >
                        <img
                          src="/image/volume-ctrl.svg"
                          id="sfx-volume-alt-icon"
                          className="volume-alt-icon"
                          alt=""
                          style={{
                            width: "1rem",
                          }}
                        />
                        {/* <div
                          id="sfx-volume-val"
                          className="sfx-vol text-white"
                          style={{ fontSize: "60%" }}
                        >
                          5.0
                        </div> */}
                      </div>

                      <div
                        id="sfx-mute"
                        className="sfx-mute muted text-white tt"
                        data-bs-placement="bottom"
                        title="mute"
                        onClick={() => mute("sfx")}
                        style={{ fontSize: "1.25vw" }}
                      >
                        M
                      </div>

                      <div
                        id="sfx-solo"
                        className="sfx-solo soloed text-white tt"
                        data-bs-placement="bottom"
                        title="solo"
                        onClick={() => solo("sfx")}
                        style={{ fontSize: "1.25vw" }}
                      >
                        S
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isChecked && (
            <div id="transcription-area">
              <div id="add-tracks-slider-transcript">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "6.65vh",
                  }}
                >
                  <div
                    style={{ zIndex: 999 }}
                    onClick={hideAddTrackSliderTranscript}
                  >
                    <img
                      src="/image/add_tracks.svg"
                      className="add-spk-sfx-tracks tt"
                      data-bs-placement="bottom"
                      title="add track"
                      id="add-spk-sfx-tracks-transcript"
                      alt=""
                    />
                  </div>

                  <div
                    id="add-tracks-slider-speakers"
                    className="row sliders-track text-center add-track"
                    onClick={() => creatCustomClassOperation("speakers")}
                  >
                    SPEAKER
                  </div>

                  <div
                    id="add-tracks-slider-sfx"
                    className="row sliders-track text-center add-track"
                    onClick={() => creatCustomClassOperation("sfx")}
                  >
                    SFX
                  </div>

                  <div
                    id="add-tracks-slider-music"
                    className="row sliders-track text-center add-track"
                    onClick={() => creatCustomClassOperation("music")}
                  >
                    MUSIC
                  </div>
                </div>
              </div>
              <div
                id="transcript-header"
                className="row transcript-header-workspace"
                style={{
                  width: "calc(100% - 15rem)",
                  marginLeft: "auto",
                  marginRight: "auto",
                  position: "relative",
                  top: "1.2rem",
                }}
              >
                <div
                  className="col-3 text-secondary trans-search d-flex justify-content-center"
                  id="transcriptSearch-workspace"
                  style={{ position: "relative" }}
                >
                  <input
                    className="transcriptSearchbar"
                    type="text"
                    id="input-search-transcript-workspace"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder=" Search..."
                    size="15"
                  />
                  <div
                    className="searchOptions d-flex align-items-center"
                    id="transcriptIcons-workspace"
                    style={{ position: "relative" }}
                  >
                    <div
                      className="transcriptSearch-img-new transcriptSearchOptionSpacing-alt tt transcriptDButtons"
                      data-bs-placement="bottom"
                      title="replace"
                    >
                      <img
                        className="disable"
                        src="/image/workspace/controls/replace.png"
                        id="btn-transcript-replace-div"
                        alt=""
                        style={{ position: "relative", width: "14.52px" }}
                        onClick={handleReplaceIcon}
                      />
                    </div>
                    <div
                      className="transcriptSearch-img-new transcriptSearchOptionSpacing-alt tt transcriptDButtons"
                      data-bs-placement="bottom"
                      title="previous"
                    >
                      <img
                        className="disable"
                        src="/image/transcript/transcriptUpArrow.png"
                        id="transcript-search-prev"
                        alt=""
                        style={{ position: "relative", width: "14.52px" }}
                        onClick={handlePreviousClick}
                      />
                    </div>
                    <div
                      className="transcriptSearch-img-new transcriptSearchOptionSpacing-alt tt transcriptDButtons"
                      data-bs-placement="bottom"
                      title="next"
                    >
                      <img
                        className="disable"
                        src="/image/transcript/transcriptDownArrow.png"
                        id="transcript-search-next"
                        alt=""
                        style={{ position: "relative", width: "14.52px" }}
                        onClick={handleNextClick}
                      />
                    </div>
                    <div
                      className="transcriptSearch-img-new transcriptSearchOptionSpacing tt transcriptDButtons"
                      data-bs-placement="bottom"
                      title="select all"
                    >
                      <img
                        className="disable"
                        src="/image/workspace/controls/annotations.png"
                        id="multiselect-transcript"
                        alt=""
                        onClick={handleSelectAll}
                        style={{
                          position: "relative",
                          width: "14.52px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>

                  {showReplaceIcon && (
                    <div className="replace-transcript-workspace">
                      <input
                        className="transcriptSearch-workspace"
                        type="text"
                        id="input-replace-transcript-workspace"
                        placeholder="Replace..."
                        size="15"
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          class="disable"
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "-20%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                          src="/image/workspace/controls/rec-check.png"
                          alt=""
                          onClick={handleReplaceTranscript}
                          data-bs-placement="bottom"
                          title="match whole word"
                        />
                      </div>
                    </div>
                  )}
                </div>

                {/*<div className="searchOptions" id="transcriptIcons" style={{position: 'relative'}}>
                     
                     {/* <div title="search previous"
                         class='transcriptSearch-icon transcriptSearch-prev transcriptSearchOptionSpacing'><i
                             onClick={searchTranscript} class="fa fa-long-arrow-up"
                             aria-hidden="true"></i></div>
                     <div title="search next"
                         class='transcriptSearch-icon transcriptSearch-next transcriptSearchOptionSpacing'><i
                             id="chev-down" onClick={searchTranscript} class="fa fa-long-arrow-down"
                             aria-hidden="true"></i></div>
                     */}
                {/* <div className="transcriptSearch-img transcriptSearchOptionSpacing-alt tt transcriptDButtons" data-bs-placement="bottom"
                         title="replace">
                         <img className="disable" 
                             src="./image/workspace/controls/replace.png" id="btn-transcript-replace-div"
                             style={{height: 'auto', width: '12.1479px', cursor:'pointer'}} alt="" />
                     </div>
                     <div className="transcriptSearch-img transcriptSearchOptionSpacing-alt tt transcriptDButtons" data-bs-placement="bottom"
                         title="previous">
                         <img className="disable" 
                             src="./image/transcript/transcriptUpArrow.png" id="transcript-search-prev"
                             style={{height: 'auto', width: '12.1479px'}} alt="" />
                     </div>
                     <div className="transcriptSearch-img transcriptSearchOptionSpacing-alt tt transcriptDButtonsNext" data-bs-placement="bottom"
                         title="next">
                         <img className="disable"
                             src="./image/transcript/transcriptDownArrow.png" id="transcript-search-next"
                             style={{height: 'auto', width: '12.1479px'}} alt="" />
                     </div>
                     <div className="transcriptSearch-img transcriptSearchOptionSpacing tt transcriptDButtonsLast" data-bs-placement="bottom"
                         title="select all" onClick={handleSelectAll} style={{cursor:'pointer'}}>
                         <img className="disable" 
                             src="./image/workspace/controls/annotations.png" id="multiselect-transcript"
                             style={{height: 'auto', width: '12.1479px',cursor:'pointor'}} alt="" />
                     </div>
                 </div>
             </div> */}
                <div
                  id="transcriptDrivenCustomClassesCont"
                  className="col-5 text-secondary trans-search d-flex justify-content-center"
                >
                  <div
                    id="speakers-sliders-transcript"
                    className="speakers-sliders track-spk-slider"
                    style={{ position: "relative" }}
                  >
                    {/* Content for 'speakers-sliders' goes here */}
                  </div>

                  <div
                    className="add-spk-sfx-transcript transcriptSearch-img transcriptDrivenSearchOptionSpacing-alt"
                    id="add-spk-sfx-transcript"
                    style={{ zIndex: 999 }}
                    onClick={revealAddTrackSliderTranscript}
                  >
                    <img
                      src="/image/add_tracks.svg"
                      className="add-spk-sfx-tracks tt"
                      data-bs-placement="bottom"
                      title="add track"
                      id="add-spk-sfx-tracks-transcript"
                      alt=""
                    />
                  </div>

                  <div
                    id="sfx-slider-cont-transcript"
                    className="sfx-slider-cont d-flex"
                    style={{
                      position: "relative",
                      justifyContent: "center",
                      top: "55%",
                    }}
                  >
                    <img
                      className="prev-transcript"
                      id="sfx-prev"
                      src="/image/prev_slider.svg"
                      alt=""
                      onClick={() => {
                        if (currentIndex > 0)
                          setCurrentIndex((prev) => prev - 1);
                        scrollToSfx(currentIndex);
                      }}
                    />

                    <div id="sfx-sliders-transcript" className="sfx-sliders">
                      {/* Content for 'sfx-sliders' goes here */}
                    </div>

                    <img
                      className="next-transcript"
                      id="sfx-next"
                      src="/image/next_slider.svg"
                      onClick={() => {
                        if (
                          currentIndex <
                          Array.from(
                            document.querySelectorAll(
                              "#sfx-sliders-transcript [id*='-slider-sfx']",
                            ),
                          ).length -
                            1
                        )
                          setCurrentIndex((prev) => prev + 1);
                        scrollToSfx(currentIndex);
                      }}
                      alt=""
                    />
                  </div>

                  <div
                    id="transcriptSFXSliderBox"
                    style={{ color: "#8A2BE2 !important" }}
                  ></div>
                </div>
                <div
                  id="transcriptDrivenButtons"
                  className="col-3 text-secondary trans-search d-flex justify-content-end"
                  style={{ position: "relative" }}
                >
                  {/* <div
                    className="vol-ctrl-menu-transcription"
                    id="vol-ctrl-menu-transcription"
                    style={{
                      visibility: "hidden",
                      backdropFilter: "blur(10px)",
                    }}
                  >
                    <div
                      id="transciptMuteAndSolo"
                      className="d-none justify-content-center"
                      style={{
                        position: "absolute",
                        top: "-8%",
                        width: "100%",
                      }}
                    >
                      <div
                        id="spk-mute-transcript"
                        className="transcriptDrivenSearchOptionSpacing-alt speakers-mute muted text-white tt"
                        data-bs-placement="bottom"
                        title="mute"
                        onClick={mute}
                      >
                        M
                      </div>
                      <div
                        id="spk-solo-transcript"
                        className="transcriptDrivenSearchOptionSpacing speakers-solo soloed text-white tt"
                        data-bs-placement="bottom"
                        title="solo"
                        onClick={solo}
                      >
                        S
                      </div>
                    </div>
                    <img
                      src="./image/workspace/controls/knob-outline.svg"
                      className="knob-outline"
                      alt=""
                    />
                    <div
                      className="knob-clean"
                      data-fgColor="#3ff"
                      data-bgColor="#111"
                      data-type="pan"
                    ></div>
                    <div className="amplitude-container">
						<div id='amplitude-way-one' className="amplitude-way-one"></div>
						<div id='amplitude-way-two' className="amplitude-way-two"></div>
					</div>
                    <div
                      id="vol-ctrl-track-cont"
                      style={{
                        color: "#c4c4c4",
                        position: "absolute",
                        left: "50%",
                        transform: "translateX(-50%)",
                        whiteSpace: "nowrap",
                        bottom: "0%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span id="vol-ctrl-track"></span>
                    </div>
                    <div className="volume-container" id="volume-slider">
                      <input
                        type="range"
                        id="volume-transcription"
                        name="volume"
                        min="0"
                        max="100"
                        step="10"
                        orient="vertical"
                        class="volume-range"
                      />
                    </div>
                  </div> */}
                  <div
                    id="menu-vol-ctrl-spk-transcript"
                    className="d-flex align-items-center"
                    onClick={(event) => volumeControlMenu(event, "speakers")}
                  >
                    <img
                      src="/image/volume-ctrl.svg"
                      id="spk-volume-alt-icon"
                      className="volume-alt-icon-transcript transcriptSearchOptionSpacing"
                      alt=""
                      style={{
                        cursor: "pointer",
                        width: "14.2917px",
                        height: "13.5771px",
                        textAlign: "center",
                      }}
                    />
                  </div>

                  <div
                    className="btn-trans-speakers trans-speakers transcriptSearch-img transcriptDrivenSearchOptionSpacing tt"
                    id="transcript-toggle-t-spk"
                    style={{
                      textAlign: "center",
                      width: "10.0042px",
                      marginRight: "7.14583px",
                      marginLeft: "7.14583px",
                    }}
                    data-bs-placement="bottom"
                    title="hide speakers"
                  >
                    <img
                      className="show-spk"
                      id="toggle-t-spk"
                      src="/image/transcript/c_transcript_speaker.png"
                      alt=""
                      onClick={toggleSpeakerImgSrc}
                      style={{
                        cursor: "pointer",
                        width: "0.9765625vw",
                        height: "0.9765625vw",
                        textAlign: "center",
                      }}
                    />
                  </div>
                  <div
                    className="btn-trans-download trans-download transcriptSearch-img transcriptDrivenSearchOptionSpacing tt"
                    onClick={handleExport}
                    style={{
                      textAlign: "center",
                      width: "10.0042px",
                      marginRight: "7.14583px",
                      marginLeft: "7.14583px",
                    }}
                    data-bs-placement="bottom"
                    title="export transcript"
                  >
                    <img
                      className=""
                      src="/image/transcript/export_transcript.png"
                      id="exportTranscriptIcon"
                      alt=""
                      style={{
                        cursor: "pointer",
                        width: "14.2917px",
                        height: "13.5771px",
                        textAlign: "center",
                      }}
                    ></img>
                  </div>
                  <div
                    className="btn-trans-export trans-export transcriptSearch-img transcriptDrivenSearchOptionSpacing tt"
                    onClick={handlePrint}
                    style={{
                      textAlign: "center",
                      width: "10.0042px",
                      marginRight: "7.14583px",
                      marginLeft: "7.14583px",
                    }}
                    data-bs-placement="bottom"
                    title="print transcript"
                  >
                    <img
                      className=""
                      src="/image/transcript/print_transcript.png"
                      id="printTranscriptIcon"
                      alt=""
                      style={{
                        cursor: "pointer",
                        width: "14.2917px",
                        height: "13.5771px",
                        textAlign: "center",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="sub-vol-ctrl-transcript">
                <div className="sub-vol-ctrl-cont-transcript">
                  <input
                    type="range"
                    name="volume"
                    min="0.0"
                    max="10.0"
                    step="0.1"
                    value={speakerVolumeLevel}
                    onChange={(e) => setSpeakerVolumeLevel(e.target.value)}
                    className="sub-vol-ctrl-range-transcript"
                  />
                  <div className="sub-vol-ctrl-value">
                    {parseFloat(speakerVolumeLevel).toFixed(1)}
                  </div>
                </div>
              </div>

              {/* {isChecked && ( */}
              {/* <div className="post">
                <div className="post-content" id="post-content"> */}
              <div
                id="playlist-transcript"
                style={{
                  borderLeft: "double #E2522B",
                  borderRight: "double #E2522B",
                }}
              >
                <div
                  className="transcript-main-workspace m-auto text-white text-justify font-weight-bold"
                  id="fullTranscript-workspace"
                >
                  <div
                    id="transcription-wrapperpara-workspace"
                    style={{
                      overflow: "visible",
                      position: "relative",
                      padding: ".6rem",
                    }}
                  >
                    {resultStat.resultStat.jobname &&
                      resultStat.resultStat.speakers_segments.map(
                        (speaker, speakerIndex) => {
                          const sortedAnnotations = [
                            ...speaker.annotations,
                          ].sort((a, b) => {
                            return a.start_time - b.start_time;
                          });
                          let speakerlabel = speaker.speaker_label.split("-");
                          let speakerHeader = speakerlabel[0];
                          let speakerNumber = JSON.parse(speakerlabel[1]) + 1;
                          let speakerImgSrc =
                            speakerHeader + "-" + speakerNumber;
                          let imgSrc =
                            "https://ui-avatars.com/api/?name=" +
                            speakerImgSrc +
                            "&background=BB417C&rounded=true&size=31&color=fff";
                          let shouldDisplayHeader = true; // Flag to control display of header
                          // we will check whether the previous speaker segment is same as the current segment or not
                          if (speakerIndex === 0) {
                            previousSpeakerImgSrc = speaker.speaker_label;
                          } else {
                            if (speaker.annotations.length > 0) {
                              if (
                                speaker.speaker_label == previousSpeakerImgSrc
                              ) {
                                shouldDisplayHeader = false;
                              } else {
                                previousSpeakerImgSrc = speaker.speaker_label;
                              }
                            }
                          }
                          const speakerImageHTML = `<img id='${speaker.id}-spk' src='${imgSrc}' class='spk-slid'>`;

                          return (
                            <>
                              {shouldDisplayHeader
                                ? sortedAnnotations.length > 0 && (
                                    <div key={speakerIndex}>
                                      {resultStat.resultStat.speakers_segments
                                        .length > 1 && (
                                        <>
                                          <br />
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: speakerImageHTML,
                                            }}
                                          />
                                          <br />
                                        </>
                                      )}

                                      {sortedAnnotations.map((annot, index) => (
                                        <p
                                          key={annot.id}
                                          style={{
                                            margin: "0.4rem",
                                            fontFamily: "avenir",
                                            fontWeight: "700",
                                            padding: "0.2rem",
                                            fontSize: "13px",
                                            cursor: "pointer",
                                            display: "inline-block",
                                          }}
                                        >
                                          {renderWord(annot, index)}
                                        </p>
                                      ))}
                                    </div>
                                  )
                                : sortedAnnotations.map((annot, index) => (
                                    <p
                                      key={annot.id}
                                      style={{
                                        margin: "0.4rem",
                                        fontFamily: "avenir",
                                        fontWeight: "700",
                                        padding: "0.2rem",
                                        fontSize: "13px",
                                        cursor: "pointer",
                                        display: "inline-block",
                                      }}
                                    >
                                      {renderWord(annot, index)}
                                    </p>
                                  ))}
                            </>
                          );
                        },
                      )}
                  </div>
                </div>
              </div>
            </div>
            //   </div>

            // </div>
          )}
        </div>

        <article className="post-global">
          <div
            id="global-view"
            className="global-view d-flex"
            style={{
              background: "rgba(0, 0, 0, 0.2)",
              opacity: loaderValue ? 0 : 1,
              transition: "opacity 0.3s",
            }}
          >
            <div
              id="global-playlist-time"
              className="col-2-mod global-playlist-time"
            >
              <span className="vertical-center-left">00:00:00</span>
            </div>
            <div
              ref={props.wavesurferRefGlobal}
              onClick={() =>
                handleWaveSurferClick(props.wavesurferObjGlobal, "global")
              }
              className="col-8-mod"
              id="globalview"
              // style={{ marginTop: "2vh" }}
            >
              <div id="globalViewPulsate"></div>
            </div>
            <div
              id="global-playlist-duration"
              className="col-2-mod global-playlist-duration"
            >
              <span className="vertical-center-right ml-5">15:00:00</span>
            </div>
          </div>
        </article>

        {showModalFlag && (
          <SfxModal closeModal={closeModal} clickedAnnot={clickedAnnot} />
        )}
        {showReplaceModal && (
          <MultiselectReplaceModal
            replaceWord={document
              .getElementById("input-replace-transcript-workspace")
              .value.trim()}
            searchWord={document
              .getElementById("input-search-transcript-workspace")
              .value.trim()}
            onClose={closeReplaceModal}
            onReplaceYes={handleReplaceModalYes}
          ></MultiselectReplaceModal>
        )}

        {/* {showModal && <SfxModal closeModal={closeModal} />} */}
        {showModalsFlag && <ExportTranscript closeModal={closeModalExport} />}
      </div>
    </>
  );
};

export default PlaylistArea;
