import React, { useEffect } from "react";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css"; // Keep this if you want to preserve Tippy's base styles

const useBootstrapTooltips = (elementId = null) => {
  useEffect(() => {
    const initializeTooltips = (elements) => {
      elements.forEach((tooltipTriggerEl) => {
        const originalTitle = tooltipTriggerEl.getAttribute("title");
        if (originalTitle) {
          tooltipTriggerEl.setAttribute("data-original-title", originalTitle); // Save original title
          tooltipTriggerEl.removeAttribute("title"); // Remove the title to disable the browser tooltip
        }

        if (tooltipTriggerEl._tippy) {
          tooltipTriggerEl._tippy.destroy();
        }

        tippy(tooltipTriggerEl, {
          content: originalTitle,
          trigger: "mouseenter focus",
          hideOnClick: true,
          interactive: false,
          delay: [500, 100],
          animation: "fade",
          onShow(instance) {
            // Inline styles for the tooltip's appearance
            const tooltipBox = instance.popper.firstElementChild;
            tooltipBox.style.backgroundColor = "black"; // Set background color
            tooltipBox.style.color = "white"; // Set text color
            tooltipBox.style.borderRadius = "4px"; // Optional: Add rounded corners
            // tooltipBox.style.padding = "8px"; // Optional: Adjust padding
            tooltipBox.style.fontSize = "0.875rem"; // Optional: Adjust font size
            tooltipBox.style.boxShadow = "0px 2px 8px rgba(0, 0, 0, 0.3)"; // Optional: Add shadow
          },
        });
      });
    };

    const cleanupTooltips = (elements) => {
      elements.forEach((tooltipTriggerEl) => {
        if (tooltipTriggerEl._tippy) {
          tooltipTriggerEl._tippy.destroy();
        }

        const originalTitle = tooltipTriggerEl.getAttribute(
          "data-original-title",
        );
        if (originalTitle) {
          tooltipTriggerEl.setAttribute("title", originalTitle);
          tooltipTriggerEl.removeAttribute("data-original-title");
        }
      });
    };

    const initializeAllTooltips = () => {
      try {
        const tooltipElements = elementId
          ? Array.from(document.querySelectorAll(`#${elementId} [title]`)) // Ensure the ID selector starts with #
          : Array.from(document.querySelectorAll("[title]"));

        initializeTooltips(tooltipElements);
      } catch (error) {
        console.error(
          "Invalid selector in useBootstrapTooltips:",
          error.message,
        );
      }
    };

    initializeAllTooltips();

    return () => {
      try {
        const tooltipElements = elementId
          ? Array.from(document.querySelectorAll(`#${elementId} [title]`))
          : Array.from(document.querySelectorAll("[title]"));

        cleanupTooltips(tooltipElements);
      } catch (error) {
        console.error(
          "Invalid selector in useBootstrapTooltips cleanup:",
          error.message,
        );
      }
    };
  }, [elementId]);
};

export default useBootstrapTooltips;
